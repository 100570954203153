import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterContextType {
    propertyType: any;
    setPropertyType: any;
    bedrooms: string | '';
    setBedrooms: Dispatch<SetStateAction<string | ''>>;
    bathrooms: string | '';
    setBathrooms: Dispatch<SetStateAction<string | ''>>;
    parking: number | null;
    setParking: Dispatch<SetStateAction<number | null>>;
    price: number[];
    setPrice: Dispatch<SetStateAction<number[]>>;
    status: string;
    setStatus: Dispatch<SetStateAction<string>>;
    minPrice: number[];
    setMinPrice: Dispatch<SetStateAction<number[]>>;
    maxPrice: number[];
    setMaxPrice: Dispatch<SetStateAction<number[]>>;
    mlsNumber: string;
    setMlsNumber: Dispatch<SetStateAction<string>>;
    purchaseType: string;
    setPurchaseType: any;
    location: any;
    setLocation: any;
    currentView: string;
    setCurrentView: Dispatch<SetStateAction<string>>;
    sortType: string;
    setSortType: Dispatch<SetStateAction<string>>;
    collections: any,
    setCollections : any,
    resetFilters: any
}

const defaultFilterValues: FilterContextType = {
    propertyType: '',
    setPropertyType: () => {},
    bedrooms: '',
    setBedrooms: () => {},
    bathrooms: '',
    setBathrooms: () => {},
    parking: null,
    setParking: () => {},
    price: [0, 25000000],
    setPrice: () => {},
    status: 'A',
    setStatus: () => {},
    minPrice: [],
    setMinPrice: () => {},
    maxPrice: [],
    setMaxPrice: () => {},
    mlsNumber: '',
    setMlsNumber: () => {},
    purchaseType: 'Buy',
    setPurchaseType: () => {},
    location: '',
    setLocation: () => {},
    currentView: 'list',
    setCurrentView: () => {},
    sortType: '',
    setSortType: () => {},
    collections: '',
    setCollections: () => {},
    resetFilters: () => {},
};

export const FilterContext = createContext<FilterContextType>(defaultFilterValues);

interface FilterProviderProps {
    children: ReactNode;
}

export const FilterProvider = ({ children }: FilterProviderProps) => {

    const {t} = useTranslation();
    const [propertyType, setPropertyType] = useState({ id: 1, name: '', key: 'all' });
    const [bedrooms, setBedrooms] = useState<string | ''>('');
    const [bathrooms, setBathrooms] = useState<string | ''>('');
    const [parking, setParking] = useState<number | null>(null);
    const [price, setPrice] = useState([0, 25000000]);
    const [status, setStatus] = useState('A');
    const [maintenanceFee, setMaintenanceFee] = useState([0, 1000]);
    const [minPrice, setMinPrice] = useState<any>([]);
    const [maxPrice, setMaxPrice] = useState<any>([]);
    const [mlsNumber, setMlsNumber] = useState('');
    const [purchaseType, setPurchaseType] = useState<string>('Buy');
    const [location, setLocation] = useState('Toronto');
    const [sortType, setSortType] = useState('');
    const [collections, setCollections] = useState('');
    const [currentView, setCurrentView] = useState('list')
        const resetFilters = () => {
            setPropertyType({ id: 1, name: '' , key: 'all' });
            setBedrooms('');
            setBathrooms('');
            setParking(null);
            setPrice([0, 25000000]);
            setStatus('A');
            setMinPrice([]);
            setMaxPrice([]);
            setMlsNumber('');
            setPurchaseType('Buy');
            setLocation('Toronto');
            setSortType('');
            setCollections('');
        };
    return (
        <FilterContext.Provider value={{
            propertyType,
            setPropertyType,
            bedrooms,
            setBedrooms,
            bathrooms,
            setBathrooms,
            parking,
            setParking,
            price,
            setPrice,
            status,
            setStatus,
            minPrice,
            setMinPrice,
            maxPrice,
            setMaxPrice,
            mlsNumber,
            setMlsNumber,
            purchaseType,
            setPurchaseType,
            location,
            setLocation,
            currentView,
            setCurrentView,
            sortType,
            setSortType,
            collections,
            setCollections,
            resetFilters,
        }}>
            {children}
        </FilterContext.Provider>
    );
};
