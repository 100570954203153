import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Header from './header';

const defaultIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = defaultIcon;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const NewMap: React.FC<any> = () => {
    const location = useLocation(); 
    const searchParams = new URLSearchParams(location.search); 
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');

    useEffect(() => {
        console.log('coordinates from query params', { lat, lng });
        if (!lat || !lng) {
            console.error('Invalid coordinates in query string');
        }
    }, [lat, lng]);

    if (!lat || !lng || isNaN(Number(lat)) || isNaN(Number(lng))) {
        return <div>Invalid coordinates</div>;
    }

    console.log('Rendering map with coordinates:', { lat, lng });

    return (
        <>
        <Header></Header>
        
        <div style={{ height: '500px', width: '100%' }}>
            <MapContainer center={[Number(lat), Number(lng)]} zoom={12} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[Number(lat), Number(lng)]}>
                    <Popup>Location</Popup>
                </Marker>
            </MapContainer>
        </div>
        </>
    );
};


export default NewMap;
