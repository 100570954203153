import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { sendEnquiry } from "../services/mailService";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { use } from "i18next";



interface Broker {
    NOM: string;
    COURRIEL: string;
    PHOTO_URL: string;
    PRENOM: string;
}

interface FormData {
    name: string;
    email: string;
    phone: string;
    message: string;
    selectedBrokers: { NOM: string; COURRIEL: string }[];
}

interface BrokerFormProps {
    propertyDetails: Broker[];
    property: any;
}

const BrokerForm: React.FC<BrokerFormProps> = ({ propertyDetails, property }) => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false);
    const [language, setLanguage] = useState('')

    const {t} = useTranslation();

    function sendSentance() {
        return t('testSentence');
    }

    
    const { i18n } = useTranslation();



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const [selectedBrokers, setSelectedBrokers] = useState<Broker[]>([]);
    const [customMessage, setCustomMessage] = useState(`${t('testSentence')} ${property}`);
    const [brokerError, setBrokerError] = useState("");

    useEffect(() => {
        setCustomMessage(`${t('testSentence')} ${property}`);
    }, [i18n.language]);



   

    const onSubmit = async (data: FormData) => {
        if (selectedBrokers.length === 0) {
            setBrokerError(t("enquiry.16"));
            return;
    }
        setBrokerError("");

        const formDataWithBrokers = {
            ...data,
            message: customMessage.trim(), // Ensure no leading/trailing spaces
            selectedBrokers: selectedBrokers.map(({ PRENOM, NOM, COURRIEL }) => ({ PRENOM, NOM, COURRIEL })),
            property,  // Include property details
            url: window.location.href, // Capture current page URL
        };


        try {
            setFormSubmitted(true);
            await sendEnquiry(formDataWithBrokers);
            setFormSubmitted(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        } catch (error) {
            console.error("Error sending enquiry", error);
            setFormSubmitted(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }

        reset();
        setSelectedBrokers([]);
        setCustomMessage(""); 
    };


    const handleBrokerSelection = (broker: Broker) => {
        setSelectedBrokers((prev) => {
            const exists = prev.find((b) => b.NOM === broker.NOM);
            return exists ? prev.filter((b) => b.NOM !== broker.NOM) : [...prev, broker];
        });
    };

    return (<>
        {success && (
            <div className='bg-white p-3 m-2 rounded fixed shadow-lg z-[500] w-fit top-2 right-2 ' >
                {/* <IoClose size={24} className="absolute right-0 top-0 m-3 cursor-pointer z-40 text-black" onClick={onClose} /> */}
                <div className="flex items-center gap-2 justify-center pb-2 ">
                    <FaCheckCircle size={25} color='green' className='' />
                    <h1 className="text-sm text-center font-semibold text-[green]  ">{t('enquiry.07')}</h1>
                </div>
                <div className='bar  rounded absolute left-0 bottom-0' >
                </div>
            </div>

        )}
          {error && (
            <div className='bg-white p-3 m-2 rounded fixed shadow-lg z-[500] w-fit top-2 right-2 ' >
                {/* <IoClose size={24} className="absolute right-0 top-0 m-3 cursor-pointer z-40 text-black" onClick={onClose} /> */}
                <div className="flex items-center gap-2 justify-center pb-2 ">
                    <MdError size={25} color='red' className='' />
                    <h1 className="text-sm text-center font-semibold text-[#800000]  ">{t('enquiry.08')}</h1>
                </div>
                <div className='bar bg-Red-600  rounded absolute left-0 bottom-0' >
                </div>
            </div>

        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
            <input
                {...register("name", { required: t('enquiry.09') })}
                type="text"
                className="border-2 border-gray-300 px-3 py-2 w-full rounded"
                placeholder={t('enquiry.02')}
            />
            {errors.name && <p className="text-Red-600 text-start">{errors.name.message}</p>}

            <input
                {...register("email", {
                    required: t('enquiry.10'),
                    pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: t('enquiry.17'),
                    },
                })}
                type="email"
                className="border-2 border-gray-300 px-3 py-2 w-full rounded"
                placeholder={t('enquiry.03')}
            />
            {errors.email && <p className="text-Red-600 text-start">{errors.email.message}</p>}

            <input
                {...register("phone", {
                    required: t('enquiry.11'),
                    pattern: {
                        value: /^[0-9]{10}$/,
                        message: t('enquiry.12'),
                    },
                })}
                type="tel"
                className="border-2 border-gray-300 px-3 py-2 w-full rounded"
                placeholder={t('enquiry.04')}
            />
            {errors.phone && <p className="text-Red-600 text-start">{errors.phone.message}</p>}

            <div className="py-3">
                {propertyDetails.map((broker, index) => (
                    <div key={index} className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id={`broker-${index}`}
                            checked={selectedBrokers.some((b) => b.NOM === broker.NOM)}
                            onChange={() => handleBrokerSelection(broker)}
                            className="w-4 h-4 scale-100"
                        />
                        <img
                            src={broker.PHOTO_URL}
                            alt={broker.NOM}
                            className="w-12 h-12 rounded-full object-cover"
                        />
                        <label htmlFor={`broker-${index}`}>{broker.PRENOM} {broker.NOM}</label>
                    </div>
                ))}
            </div>
            {brokerError && <p className="text-Red-600 text-start">{brokerError}</p>}

            <textarea
                {...register("message", { required: t('enquiry.13') })}
                className="border-2 border-gray-300 px-3 py-2 w-full h-32 rounded"
                placeholder={t('enquiry.05')}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
            />

            {errors.message && <p className="text-Red-600 text-start">{errors.message.message}</p>}
            {
                formSubmitted ? <div
                    className="bg-pantone288-600 text-white p-3 w-full rounded-lg hover:bg-yellow-500 transition flex justify-center items-center gap-3"
                >
                    <div className="spinner"></div>
                    {t('enquiry.14')}
                </div> :
                    <button
                        type="submit"
                        className="bg-pantone288-600 text-white p-3 w-full rounded-lg hover:bg-yellow-500 transition"
                    >
                        {t('enquiry.15')}
                    </button>
            }
        </form>
    </>

    );
};

export default BrokerForm;
