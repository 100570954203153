import React, { useEffect, useState } from 'react';
import BrokerCard from './brockerCard2';
import { Broker } from './broker';
import Header from '../components/header';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import img1 from "../assets/images/bgInRentals.webp";
import { brokerEngData } from './brokerEngData'; 
import { brokersFrData } from './brokerFrData';
import axios from 'axios';
// import { getAllBrokers } from '../services/brokrService';

const BrokersList: React.FC = () => {
  const [brokerlist, setBrokerList] = useState<any[]>([]);
  const [shuffledBrokerList, setShuffledBrokerList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { t, i18n } = useTranslation();
  const itemsPerPage = 8;

  // Shuffle brokers list
  const shuffleBrokers = (data: any[]) => {
    return data.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    setLoading(true);
    
    // Select broker data based on the language and shuffle it once
    const selectedBrokers = i18n.language === 'fr' ? brokersFrData : brokerEngData;
    const shuffledBrokers = shuffleBrokers(selectedBrokers);
    
    setBrokerList(selectedBrokers); // Store the unshuffled list for reference
    setShuffledBrokerList(shuffledBrokers); // Store the shuffled list
    
    setLoading(false);
  }, [i18n.language]);

  useEffect(() => {
    setTotalPages(Math.ceil(shuffledBrokerList.length / itemsPerPage));
  }, [shuffledBrokerList]);


  //show the title as brokers
  useEffect(() => {
    document.title = t('title.brokers');
  }, [t]);


  // const getBrokersData = async ()=>{
  //   const res = await axios.get("http://mt.vto.homes/annonces/api/brokers?limit=27");
  //   const result = await res.data;
  //   console.log(result);
    
  // }
  // getBrokersData();

  // Calculate current items based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = brokerlist.slice(indexOfFirstItem, indexOfLastItem).sort(() => Math.random() - 0.5)

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`w-12 rounded-full text-sm ${i === currentPage ? 'bg-pantone288-700 hover:bg-yellow-500 hover:text-white text-white' : 'bg-gray-200'}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <Header />
      <div className='md:pt-24 pt-20' >
      <div className="relative text-white lg:py-10 md:h-60 py-8 lg:px-24 px-5 " style={{ backgroundImage: `url(${img1})`, backgroundSize: "cover", backgroundPositionY: "center" }}>
        <div className="absolute inset-0 bg-black bg-opacity-70 z-10"></div>
        <div className="container relative mx-auto px-4 z-10 opacity-100">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-start">
              <h3 className="text-2xl">{t("brockers.15")}</h3>
              <p className="mt-4 text-gray-300">{t("brockers.16")}</p>
            </div>
            <div className="text-start">
              <h3 className="text-2xl">{t("brockers.17")}</h3>
              <p className="mt-4 text-gray-300">{t("brockers.18")}</p>
            </div>
            <div className="text-start">
              <h3 className="text-2xl ">{t("brockers.19")}</h3>
              <p className="mt-4 text-gray-300">{t("brockers.20")}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="text-start px-8 lg:px-24 py-5">
          <h1 className="text-yellow-500 text-2xl">{t("brockers.21")}</h1>
          {/* <p className="text-4xl ml-1 font-light">{t("brockers.22")}</p> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 p-3 px-14 lg:px-24">
            {currentItems.map(broker => (
            <BrokerCard key={broker.CODE} broker={broker} />
            ))}
        </div>
      </div>
      <div className="flex gap-5 justify-center mt-5 p-5">
        <button
          className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white p-4 rounded-full disabled:opacity-50"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <FaArrowLeft color='#ffffff' />
        </button>
        {renderPageNumbers()}
        <button
          className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white p-4 rounded-full disabled:opacity-50"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <FaArrowRight color='#ffffff' />
        </button>
      </div>
      </div>
    </>
  );
};

export default BrokersList;
