import axios from 'axios'



const api = 'https://mt-cms-214691512350.us-east4.run.app/api'
export const getBlog= async()=>{
   try{
    const response = await axios.get(`${api}/blog-sections?populate[Desc][populate]=img1`)
    return response.data.data;

   }
   catch(error){
      console.error('Failed to fetch blog data:', error);
      throw error;
   }
}


export const getBlogById= async(id:string)=>{
    try{
    const response = await axios.get(`${api}/blog-sections?filters[id][$eq]=${id}&populate[Desc][populate]=img1`);
     return response.data.data;
    }
    catch(error){
       console.error('Failed to fetch blog data:', error);
       throw error;
    }
}