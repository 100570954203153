import React, { useEffect, useRef } from 'react';
import estateVideo from '../assets/vedios/Video without logo cpmpressed.mp4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const HeroSection: React.FC = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { i18n } = useTranslation();

  const language = i18n.language;


  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch(() => {
          console.log("Autoplay blocked on Mac Safari. Trying manual play...");
          video.muted = true; // Ensure muted
          video.play();
        });
      }
    }
  }, []);

  return (
    <section className="relative h-screen w-full">
      {/* Background Video */}
      <video
        ref={videoRef}
        className="absolute inset-0 object-cover w-full h-full"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={estateVideo} type="video/mp4" />
      </video>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 text-center text-white flex flex-col justify-center items-center h-full lg:px-0 px-2">
        <h1 className="text-3xl lg:text-6xl mb-6">{t("hero_section.heading")}</h1>
        <div className="flex space-x-4">
          <button
            className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white text-xs lg:text-base bg-opacity-70 hover:bg-opacity-90 text-yellow-500 py-2 px-4 rounded"
            onClick={() => navigation(`/${language}/collections`)}
          >
            {t("hero_section.button1")}
          </button>
          <button
            className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white text-xs lg:text-base bg-opacity-70 hover:bg-opacity-90 text-yellow-500 py-2 px-4 rounded"
            onClick={() => navigation(`/${language}/listings`)}
          >
            {t("hero_section.button2")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
