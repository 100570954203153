import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import fb from '../assets/images/IMT_logo_reseaux_FB.webp'
import yt from '../assets/images/IMT_logo_reseaux_YT.webp'
import ln from '../assets/images/IMT_logo_reseaux_LK.webp'
import Ig from '../assets/images/IMT_logo_reseaux_IG.webp'


const SocialMediaBar: React.FC = () => {
  return (
    <div className="flex flex-col absolute top-0 z-30 bg-transparent justify-end items-center  py-10 h-full w-24 left-0 space-y-4 text-white">
      <div ><a href="https://www.facebook.com/TremblantRealEstate/" className="block cursor-pointer" target='blank'><img src={fb} alt="facebook" width={50} /></a></div>
      {/* <div className='p-2 rounded-lg bg-yellow-500'><a href="#" className="block"><FaTwitter size={24} /></a></div> */}
      <div className=''><a href="https://www.instagram.com/lesimmeublesmonttremblant" className="block cursor-pointer" target='blank'><img src={Ig} alt="instagram" width={50}  /></a></div>
      <div className=''><a href="https://www.linkedin.com/in/les-immeubles-mont-tremblant-real-estate-72858778/" target='blank' className="block cursor-pointer"><img src={ln} alt="linkedin" width={50} /></a></div>
    </div>
  );
};

export default SocialMediaBar;
