import React, { useEffect } from "react";
import Header from "../../components/header";
import img1 from "../../assets/images/05-LES-LAURENTIDES.webp";
import img2 from "../../assets/images/laurentians-2.webp";
import img3 from "../../assets/images/laurentians-3.avif";
import img4 from "../../assets/images/laurentians-5.webp";
import { useTranslation } from "react-i18next";

const Laurenties = () => {
    const { t } = useTranslation();
    useEffect(()=>{
        document.title = t('title.laurentian')
    },[t])
    return (
        <>
            <Header />
            <div className="px-5 md:px-20 md:pt-36 pt-24">
                {/* Heading */}
                <div className="font-light text-start">
                    <h1 className="text-xl md:text-2xl text-yellow-500 mt-3">{t("communities.laurentian.explore")}</h1>
                    <h1 className="text-4xl md:text-6xl">{t("communities.laurentian.header")}</h1>
                    <p className="text-gray-600 mt-2">{t("communities.laurentian.explore1.00")}</p>
                </div>

                {/* Content-1 */}
                <div className="flex flex-col md:flex-row gap-2 mt-8">
                    <div className="w-full md:w-1/2 lg:p-5 h-auto lg:h-[400px]">
                        <img src={img1} alt="mont-blac" className="rounded-lg h-full w-full object-cover" />
                    </div>
                    <div className="w-full md:w-1/2 text-start lg:p-5  flex flex-col gap-3">
                        <p className="text-gray-500 mt-2">{t("communities.laurentian.explore1.01")}</p>
                        <p className="text-gray-500">{t("communities.laurentian.explore1.02")}</p>
                    </div>
                </div>

                {/* Content-2 */}
                <div className="flex flex-col md:flex-row gap-2 lg:mt-8 items-stretch">
                    <div className="w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4">
                        <p className="text-gray-500 mt-2">{t("communities.laurentian.explore1.03")}</p>
                        <p className="text-gray-500 mt-2">{t("communities.laurentian.explore1.04")}</p>
                    </div>
                    <div className="w-full md:w-1/2  md:h-[400px]">
                        <img src={img2} alt="mont-blac" className="  rounded-lg h-90   object-cover" />
                    </div>
                </div>

                {/* Content-3 */}
                <div className="flex py-2 flex-col-reverse md:flex-row gap-2 lg:mt-8 items-stretch">
                    <div className="w-full md:w-1/2 lg:p-5 h-auto md:h-[450px]">
                        <img src={img3} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
                    </div>
                    <div className="w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4">
                        <h1 className="text-yellow-500 text-xl md:text-2xl font-light mt-2">{t("communities.laurentian.attractions.00")}</h1>
                        <p className="text-gray-500">{t("communities.laurentian.attractions.01")}</p>
                    </div>
                </div>

                {/* Content-4 */}
                <div className="flex flex-col md:flex-row gap-2 lg:mt-8 items-stretch">
                    <div className="w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4">
                        <p className="text-gray-500">{t("communities.laurentian.attractions.04")}</p>
                    </div>
                    <div className="w-full md:w-1/2 lg:p-5 h-auto lg:h-[400px]">
                        <img src={img4} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Laurenties;
