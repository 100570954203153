import lacImg from '../../assets/images/lac-superiur.webp';
import Img2 from '../../assets/images/01Lac-Superieur-1536x1023.webp';
import img3 from '../../assets/images/lac-5.avif';
import Header from '../../components/header';
import { useTranslation } from 'react-i18next';
import { PiCurrencyCircleDollarThin } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { PiPersonSimpleWalkLight } from "react-icons/pi";
import { useEffect } from 'react';

const LacSuperior = () => {
  const { t } = useTranslation();
  useEffect(()=>{
    document.title = t('title.lac')
},[t])

 const { i18n } = useTranslation();


    const language = i18n.language


const replaceString = (str: string) => {
  if (language === 'fr') {
    return str.replace(/41\.2/g, "47")
              .replace(/\$90567/g, "72 600 $")
              .replace(/7,019/g, "1,800")
              .replace(/41,2/g, "47")
              .replace(/90 567 \$/g, "72 600 $")
              .replace(/7 019/g, "1 800")
              .replace(/High/g, "Low")
              .replace(/\$72,600/g, "72 600 $")
              .replace(/Haut/g, "Bas")
              .replace(/Aspen/g, "Lac-Supérieur");
  } else {
    return str.replace(/41\.2/g, "47")
              .replace(/\$90567/g, "$72,600")
              .replace(/7,019/g, "1,800")
              .replace(/41,2/g, "47")
              .replace(/90 567 \$/g, "$72,600")
              .replace(/7 019/g, "1,800")
              .replace(/High/g, "Low")
              .replace(/\$90,567/g, "$72,600")
              .replace(/Haut/g, "Bas")
              .replace(/Aspen/g, "Lac-Supérieur");
  }

}

  return (
    <>
      <Header />
      <div className="md:px-20 px-4 md:pt-36 pt-24">

        {/* Heading */}
        <div className="font-light text-start">
          <h1 className="text-2xl text-yellow-500 mt-3">{t("communities.lac.explore")}</h1>
          <h1 className="text-4xl md:text-6xl">{t("communities.lac.header")}</h1>
          <p className="text-gray-600 mt-2">{t("communities.lac.explore1")}</p>
        </div>

        {/* content-1 */}
        <div className="lg:p-2 flex gap-2 mt-8 items-stretch p-2 flex-col  lg:flex-row">
          <div className="lg:w-1/2 w-full lg:h-[550px] h-full">
            <img src={lacImg} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
          </div>
          <div className="lg:w-1/2 text-start lg:p-5 flex flex-col justify-between">
            <h1 className="text-yellow-500 text-2xl font-light">{t("communities.lac.geography.00")}</h1>
            <p className="text-gray-500 mt-2"><span className="font-semibold">Lac-Supérieur</span> {t("communities.lac.geography.01")}</p>
            <p className="text-gray-500 mt-2">{t("communities.lac.geography.02")}</p>
            <p className="text-gray-500 mt-2">{t("communities.lac.geography.03")}</p>

            <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.lac.outdoor.00")}​</h1>
            <p className="text-gray-500 mt-2"><span className="font-semibold"> {t("communities.labelle.outdoor.04")}</span> {t("communities.lac.outdoor.01")} <span className="font-semibold">{t("communities.lac.outdoor.03")}</span> {t("communities.lac.outdoor.04")}</p>
            <p className="text-gray-500 mt-2"><span className="font-semibold"> {t("communities.labelle.outdoor.02")}</span>{t("communities.lac.outdoor.02")} <span className="font-semibold"> {t("communities.lac.outdoor.05")}</span>{t("communities.lac.outdoor.06")}</p>
          </div>
        </div>

        {/* content-2 */}
        <div className="lg:p-2 flex gap-2  p-2 lg:mt-8 lg:flex-row flex-col items-stretch">
          <div className="lg:w-1/2 text-start lg:p-5 flex flex-col justify-between">
            <h1 className="text-yellow-500 text-2xl font-light">{t("communities.lac.real-estate.00")}</h1>
            <p className="text-gray-500 mt-2">{t("communities.lac.real-estate.01")}</p>
            <p className="text-gray-500 mt-2">{t("communities.lac.real-estate.02")}</p>
            <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.lac.community.00")}​</h1>
            <p className="text-gray-500 mt-2">{t("communities.lac.community.01")}</p>
            <p className="text-gray-500 mt-2">{t("communities.lac.community.02")}</p>
          </div>
          <div className="lg:w-1/2 lg:h-[500px]">
            <img src={Img2} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
          </div>
        </div>

        {/* content-3 */}
        <div className="p-2 lg:p-2 flex  lg:flex-row flex-col-reverse gap-2 lg:mt-2 items-stretch">
  {/* Image Section */}
  <div className="w-full lg:w-1/2 lg:p-5 lg:h-[600px] pb-2">
    <img src={img3} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
  </div>

  {/* Content Section */}
  <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-between">
    {/* Population Text */}
    <p className="text-yellow-500 text-2xl font-light">{t("communities.lac.population.00")}</p>
    <p className="text-gray-500 mt-2">{t("communities.lac.population.01")}</p>
    <h1 className="text-2xl py-2 text-yellow-500 font-light">{t("communities.lac.population.03")}</h1>
    <p className="text-gray-500">{replaceString(t("communities.tremblant.population.03"))}</p>

    {/* Icon Section */}
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pt-7 pb-5 w-full">
      {/* First Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-3xl bg-gray-400 flex items-center justify-center">
          <CiLocationOn color="white" size={24} />
        </div>
        <div>
          <p className="text-sm text-gray-700">1,800</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.04")}</p>
        </div>
      </div>

      {/* Second Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <IoPersonOutline size={24} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.05"))}</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.06")}</p>
        </div>
      </div>

      {/* Third Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <PiPersonSimpleWalkLight size={24} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.07"))}</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.08")}</p>
        </div>
      </div>

      {/* Fourth Icon Card */}
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
          <PiCurrencyCircleDollarThin size={30} color="white" />
        </div>
        <div>
          <p className="text-sm text-gray-700">{replaceString("$72,600")}</p>
          <p className="text-sm text-gray-400">{t("communities.tremblant.population.09")}</p>
        </div>
      </div>
    </div>

    {/* Additional Text */}
    <p className="text-gray-500 mt-2">{t("communities.lac.population.02")}</p>
  </div>
</div>


<div className="p-2 flex flex-col lg:flex-row gap-2 items-stretch">
  {/* First Column: Lac Schools */}
  <div className="w-full lg:w-1/2 text-start lg:ps-5 lg:py-5 flex flex-col">
    <p className="text-xl font-semibold mt-2 pb-2 text-gray-800">{t("communities.lac.school")}</p>
    <p className="text-gray-500 ms-2">-Pavillon La Ribambelle</p>
    <p className="text-gray-500 ms-2">-Pavillon Fleur-Soleil</p>
    <p className="text-gray-500 ms-2">-Pavillon Trois-Saisons</p>
    <p className="text-gray-500 ms-2">-Pavillon Tournesol</p>
    <p className="text-gray-500 ms-2">-École l’Odyssée</p>
  </div>

  {/* Second Column: Sainte-Agathe Schools */}
  <div className="w-full lg:w-1/2 lg:ps-5 lg:py-5 text-start">
    <p className="text-xl font-semibold pb-2 mt-2 text-gray-800">{t("communities.sainte-agathe.school-sec")}</p>
    <p className="text-gray-500 ms-2">-Polyvalente Curé-Mercure</p>
    <p className="text-gray-500 pb-10 ms-2">-CEGEP</p>
  </div>
</div>

{/* English Schools Section */}
<div className="w-full text-start lg:ps-7 pb-7 ps-2 flex flex-col">
  <h1 className="text-lg font-semibold text-gray-800">{t("communities.English-Schools")}</h1>
  <p className="text-gray-500 mt-2">{t("communities.lac.school-eng.00")}</p>
  <p className="text-gray-500 mt-2">{t("communities.lac.school-eng.01")}</p>
  <p className="text-gray-500 mt-2">
    {t("communities.lac.school-eng.02")} <a href="tel:18194259325" className="text-blue-600 underline">1 819 425 9325</a>
  </p>
</div>


      </div>
    </>
  );
}

export default LacSuperior;
