import React, { useEffect } from "react";
import HeroSection from "../components/heroSection";
import LanguageSelector from "../components/languageSelector";
import SocialMediaBar from "../components/socialmediaBar";
import Header from "../components/header";
import StatsSection from "../components/ourStory";
import ExclusiveListingsSection from "../components/exclusiveListings";
import FeatureSection from "../components/featureSecton";
import LocationGrid from "../components/LocationGridComponent";
import Testimonials from "../components/testimonals";
import { useTranslation } from 'react-i18next';


const HomeComponent: React.FC = () => {
  const { t } = useTranslation();
  useEffect(()=>{
    document.title = t('title.home')
  },[t])

    const testimonials = [
        {
            text: t("testimonials.04.text"),
            author:  t("testimonials.04.author"),
        },
        {
            text: t("testimonials.01.text"),
            author: t("testimonials.01.author"),
        },
        {
            text: t("testimonials.02.text"),
            author:  t("testimonials.02.author"),
        },
        {
            text: t("testimonials.03.text"),
            author:  t("testimonials.03.author"),
        },
        
    ];
    return (
        <div className="relative">
            {/* Social Media Sidebar */}

            {/* Main Content */}
            <div className="flex">
                <div className="flex-shrink-0 hidden sm:block relative"><SocialMediaBar  /></div> {/* Prevents it from resizing */}
                <div className="flex-grow">
                    <Header />
                    <HeroSection />
                </div>
            </div>
            <StatsSection />
            <ExclusiveListingsSection />
            <FeatureSection />
            <LocationGrid />
            <Testimonials testimonials={testimonials} />
        </div>
    )
}

export default HomeComponent