import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import { BiArea } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import LoaderCard from './loaderCard';
import { getAllListings } from "../services/listingService";
import { FilterContext } from "../context/filterContext";
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { t,i18n } from 'i18next';





const ListView: React.FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const isInitialLoad = useRef(true);
    const isUserTriggered = useRef(false);
    const [listData, setListData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(false);
    const itemsPerPage = 12;

    const {
        propertyType,
        setPropertyType,
        location,
        setLocation,
        price,
        setPrice,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        mlsNumber,
        setMlsNumber,
        purchaseType,
        setPurchaseType,
        status,
        setStatus,
        currentView,
        setCurrentView,
        sortType, setSortType,
        collections, setCollections, resetFilters
    } = useContext(FilterContext);


    const updateUrlWithFilters = () => {
        const params = new URLSearchParams();
        if (collections !== 'all' && collections !== '') {
            params.append('collections', `${collections}`);
        }

        navigate(`?${params.toString()}`, { replace: true });

    };






    useEffect(() => {
        return () => {
            isUserTriggered.current = true;
            resetFilters();
        };
    }, []);

    useEffect(() => {
        const parsed = queryString.parse(search);

        if (parsed.propertyType) {
            const [key, name] = (parsed.propertyType as string).split('-');
            setPropertyType({
                key,
                name: name || key,  // Use key as name if no name is found
            });
        }
        if (parsed.location && !location) setLocation(parsed.location as string);
        if (parsed.price && JSON.stringify(price) === JSON.stringify([0, 3200000])) {
            setPrice((parsed.price as string).split(",").map(Number));
        }

        if (parsed.minPrice && !minPrice.length) setMinPrice([Number(parsed.minPrice)]);
        if (parsed.maxPrice && !maxPrice.length) setMaxPrice([Number(parsed.maxPrice)]);
        if (parsed.bedrooms && !bedrooms) setBedrooms(parsed.bedrooms as string);
        if (parsed.bathrooms && !bathrooms) setBathrooms(parsed.bathrooms as string);
        if (parsed.mlsNumber && !mlsNumber) setMlsNumber(parsed.mlsNumber as string);
        if (parsed.purchaseType && !purchaseType) setPurchaseType(parsed.purchaseType as string);
        if (parsed.status && !status) setStatus(parsed.status as string);
        if (parsed.sortType && !sortType) setSortType(parsed.sortType as string);
        if (parsed.collections) setCollections(parsed.collections as string);


        isUserTriggered.current = true;
        setHasLoaded(true);
        isInitialLoad.current = false;


    }, [search]);


    const { i18n } = useTranslation();

    const language = i18n.language;

    





    useEffect(() => {
        isUserTriggered.current = true;
    }, [
        currentPage,
        purchaseType,
        propertyType,
        bedrooms,
        bathrooms,
        minPrice,
        maxPrice,
        collections,
    ]);

    useEffect(() => {
        if (hasLoaded && isUserTriggered.current && currentPage !== 1) {

            setCurrentPage(1);
            isUserTriggered.current = false;
        }

    }, [collections, propertyType])

    useEffect(() => {

        if (hasLoaded && isUserTriggered.current) {
            getListings();
            isUserTriggered.current = false;
        }
    }, [currentPage, purchaseType, propertyType, bedrooms, bathrooms, minPrice, maxPrice, collections, hasLoaded,])





    const getListings = async () => {
        try {
            setLoading(true)
            console.log("bath", bathrooms);
            console.log("bed", bedrooms);
            let query = `&status=${collections === 'locations-saisonnieres' ? 'Lease' : purchaseType}&collection=${collections}&propertyType=${propertyType.key}&bed=${bedrooms === "Tout" ? 'all' : bedrooms}&bath=${bathrooms === 'Tout' ? 'all' : bathrooms}&minPrice=${minPrice}&maxPrice=${maxPrice}`
            const listingResponse: any = await getAllListings(itemsPerPage, currentPage, query);
            setListData(listingResponse.data)
            setTotalPages(listingResponse.totalPages)
            setCurrentPage(listingResponse.currentPage)

        }
        catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    };





    useEffect(() => {
        updateUrlWithFilters();
    }, [
        propertyType,
        location,
        price,
        minPrice,
        maxPrice,
        bedrooms,
        bathrooms,
        mlsNumber,
        purchaseType,
        status,
        sortType,
        collections,
    ]);





    const formatPrice = (price: any) => {
        const currency = language === 'fr' ? 'CAD' : 'USD';
        return new Intl.NumberFormat(language === 'fr' ? 'fr-CA' : 'en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
        }).format(price);
    };



    const formatOpenHouseDate = (openHouse: any) => {
        const date = new Date(openHouse.date);
        const day = String(date.getDate()).padStart(2, '0');  // Get day with leading zero
        const month = date.toLocaleString('default', { month: 'short' });  // Get abbreviated month

        // Format start and end times, converting them to lowercase
        const startTime = openHouse.startTime.toLowerCase();
        const endTime = openHouse.endTime.toLowerCase();


        return `${day} ${month} ${startTime} - ${endTime}`;
    }



    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = listData.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };



    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };




    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`lg:w-12 w-8 rounded-full text-sm ${i === currentPage ? 'bg-pantone288-700 hover:bg-yellow-500 hover:text-white text-white' : 'bg-gray-200'}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return pageNumbers;
    };


    return (
        <>
            <div className="grid gap-y-16 grid-cols-4 gap-7 p-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl:grid-cols-3 xl:pt-16 xl2:grid-cols-4 xl2:pt-6 max-lg:grid-cols-2 2xl:grid-cols-4 max-xl:grid-cols-3 place-items-center">
                {loading ? (
                    Array.from({ length: itemsPerPage }).map((_, index) => <LoaderCard key={index} />)
                ) : (
                    (listData && listData.length > 0) && (
                        listData.map((item: any, index: number) => (
                            <div
                                key={item.id || index}
                                className="w-[370px] max-2xl:w-[330px] max-sm:w-full rounded-xl shadow-lg"
                            >
                                <div className="h-[500px] max-2xl:h-[400px] relative bg-cover bg-center rounded-2xl overflow-hidden">
                                    <img
                                        src={item.images?.[0] || "/default-image.jpg"}
                                        alt="home"
                                        className="rounded-2xl w-full h-full cursor-pointer object-cover transform hover:scale-105"
                                        style={{
                                            transition: "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                                        }}
                                        onClick={() => window.open(`/${language}/listings.php?mls=${item.NO_INSCRIPTION}`, "_blank")}

                                    />
                                    <div className="p-4 rounded-b-xl absolute top-[64%] w-full">
                                        <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm h-28 bg-opacity-80">
                                            <div className="flex justify-between items-center">
                                                <h2
                                                    className="text-lg text-yellow-500 font-bold whitespace-nowrap"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content={item.listPrice}
                                                >
                                                    {formatPrice(purchaseType === "Lease" ? item.PRIX_LOCATION_DEMANDE : item.PRIX_DEMANDE)}
                                                </h2>
                                                <p className="text-xs text-gray-600 font-semibold">{item.class}</p>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <GrLocation size={22} className="shrink-0" />
                                                <h2
                                                    className="text-sm overflow-hidden text-ellipsis text-start font-normal whitespace-nowrap"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content={`
        ${[item.NO_CIVIQUE_DEBUT, item.NOM_RUE_COMPLET, item.municipalities, item.CODE_POSTAL]
                                                            .filter(Boolean)
                                                            .map((val) => val.replace(/È/g, "é")
                                                                .replace(/‡/g, "à")
                                                                .replace(/Ë/g, 'ë')
                                                                .replace(/Î/g, "î")
                                                                .replace(/Í/g, "ê")
                                                                .replace(/\*\*/g, '')
                                                                .replace(/è/g, 'é')
                                                                .replace(/Ó/g, "ô")
                                                                .replace(/Œ/g, "œ")
                                                                .replace(/‰/g, "é")
                                                                .replace(/\.\./g, '')
                                                                .replace(/∞/g, "")
                                                                .replace(/\u2026/g, 'É')
                                                                .replace(/\I\D\:/g, "")
                                                                .replace(/Ù/g, "ô")
                                                                .replace(/ë/g, 'è')
                                                                .replace(/˚/g, "û")
                                                                .replace(/˘/g, "ù")



                                                            )  //Replace È with é
                                                            .join(", ")}
    `}
                                                >
                                                    {[
                                                        item.NO_CIVIQUE_DEBUT,
                                                        item.NOM_RUE_COMPLET,
                                                        item.municipalities,
                                                        item.CODE_POSTAL,
                                                    ]
                                                        .filter(Boolean)
                                                        .map((val) => val.replace(/È/g, "é")
                                                            .replace(/Ë/g, 'ë')
                                                            .replace(/Î/g, "î")
                                                            .replace(/Í/g, "ê")
                                                            .replace(/\*\*/g, '')
                                                            .replace(/è/g, 'é')
                                                            .replace(/Ó/g, "ô")
                                                            .replace(/Œ/g, "œ")
                                                            .replace(/‰/g, "é")
                                                            .replace(/\.\./g, '')
                                                            .replace(/∞/g, "")
                                                            .replace(/\u2026/g, 'É')
                                                            .replace(/\I\D\:/g, "")
                                                            .replace(/Ù/g, "ô")
                                                            .replace(/ë/g, 'è')
                                                            .replace(/˚/g, "û")
                                                            .replace(/˘/g, "ù")


                                                        ) // Apply the replacement
                                                        .join(", ")}
                                                </h2>

                                            </div>

                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-1 ">
                                                    {[
                                                        item.NB_CHAMBRES && (
                                                            <div key="bedrooms" className="flex gap-1 items-center">
                                                                <IoBedOutline size={25} />
                                                                <h4>{item.NB_CHAMBRES}</h4>
                                                            </div>
                                                        ),
                                                        item.NB_SALLES_BAINS && (
                                                            <div key="bathrooms" className="flex gap-1 items-center">
                                                                <LiaBathSolid size={25} />
                                                                <h4>{item.NB_SALLES_BAINS}</h4>
                                                            </div>
                                                        ),
                                                        item.FACADE_TERRAIN && item.PROFONDEUR_TERRAIN && (
                                                            <div key="land" className="flex gap-1 items-center">
                                                                <BiArea size={25} />
                                                                <h4 className="text-sm truncate w-32">
                                                                    {`${item.FACADE_TERRAIN}m x ${item.PROFONDEUR_TERRAIN}m | ${item.SUPERFICIE_TERRAIN} m²`}
                                                                </h4>
                                                            </div>
                                                        ),
                                                    ]
                                                        .filter(Boolean)
                                                        .map((element, index, array) => (
                                                            <React.Fragment key={index}>
                                                                {element}
                                                                {index < array.length - 1 && <span className="text-gray-300">|</span>}
                                                            </React.Fragment>
                                                        ))}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                )}

            </div>
            {listData?.length === 0 && !loading && (
                <div className="flex justify-center items-center w-full p-5">
                    <p className="text-2xl font-semibold">{t('noData')}</p>
                </div>
            )}

            {(listData?.length > 0 && totalPages > 1 && !loading) && (
                <div className="flex lg:gap-5 justify-center mt-5 lg:p-5 p-2 gap-2">
                    <button
                        className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white p-2 lg:p-4 rounded-full disabled:opacity-50"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        <FaArrowLeft color='#ffffff' />
                    </button>
                    {renderPageNumbers()}
                    <button
                        className="bg-pantone288-700 hover:bg-yellow-500 hover:text-white lg:p-4 p-2 rounded-full disabled:opacity-50"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        <FaArrowRight color='#ffffff' />
                    </button>
                </div>
            )}

            <Tooltip id="my-tooltip" />
        </>
    );
}

export default ListView;