import React, { useState, useEffect, useContext } from "react";
import ListView from "../components/listView";
import Header from "../components/header";
import FilterComponent from "./filter";
import { FilterContext } from "../context/filterContext";
import TabComponent from "../components/tabContent";
import MapView from "./mapView";
import { useTranslation } from "react-i18next";



const Listings: React.FC = () => {
    
    const { currentView, setPurchaseType,  setMaxPrice, setMinPrice } = useContext(FilterContext);
    useEffect(() => {
        setPurchaseType('Buy')
        setMaxPrice([]);
        setMinPrice([0]);
    }, [])

    const {t} = useTranslation();

    useEffect(()=>{
        document.title = t('title.properties')
      },[t])

    return (
        <>
            <Header />
            <div className="md:pt-36 pt-24">

            <FilterComponent />
            </div>
            <TabComponent />
            {currentView === 'list' && (
                <ListView />)}
            {currentView === 'map' && (
                <MapView />
            )}
        </>
    )
}

export default Listings