import api from './api';

export const getAllListings = async (limit:number, currentPage:number, query:any) => {
  try {
    const response = await api.get(`/inscriptions?limit=${limit}&page=${currentPage}${query}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch dashboard data:', error);
    throw error;
  }
};
export const getAllLocations = async () => {
  try {
    const response = await api.get('/locations');
    return response;
  } catch (error) {
    console.error('Failed to get Locations');
    throw error;
  }
}

export const getSingleProperty = async (id:any) => {
  try {
    const response = await api.get(`/inscriptions/${id}`);
    return response;
  } catch (error) {
    console.error('failed to load api');
    throw error;
  }
}

export const getPropertyBybrokerId = async (id:any,limit:number, currentPage:number, filter:string) => {
  try {
    const response = await api.get(`/inscriptions/broker/${id}?limit=${limit}&page=${currentPage}&filter=${filter}`);
    return response;
  } catch(error) {
    console.error('failed to Load Api');
    throw error;
  }
}

export const getAllListingsMap = async (query:any) => {
  try {
    const response = await api.get(`/map?${query}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch dashboard data:', error);
    throw error;
  }
};