import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

// Fix for default marker icon issue
const defaultIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = defaultIcon;

const SinglePropertiesMap: React.FC<{ coordinates: { lat: number; lng: number } }> = ({ coordinates }) => {
    const markerRef = useRef<L.Marker<any>>(null); // Reference for the Marker
    const { t } = useTranslation();

    useEffect(() => {
        if (!coordinates || !coordinates.lat || !coordinates.lng) {
            console.error('Invalid coordinates provided');
            return;
        }

        // Ensure the popup opens after the map and marker are rendered
        const timeout = setTimeout(() => {
            if (markerRef.current) {
                markerRef.current.openPopup();
            }
        }, 500); // Add a delay to ensure rendering is complete

        return () => {
            clearTimeout(timeout); // Cleanup timeout on unmount
        };
    }, [coordinates]);

    if (!coordinates || !coordinates.lat || !coordinates.lng) {
        return <div>Invalid coordinates</div>;
    }

    const handleMarkerClick = () => {
        const googleMapsUrl = `https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`;
        window.open(googleMapsUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={14}
            style={{ height: '100%', zIndex: "50", width: '100%', borderRadius: '12px' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
                position={[coordinates.lat, coordinates.lng]}
                eventHandlers={{ click: handleMarkerClick }}
                ref={markerRef} // Attach the marker reference
            >
                <Popup closeButton={false}>
                    {t('map')}
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default SinglePropertiesMap;
