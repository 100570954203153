import React from 'react';

const LoaderCard:React.FC = () => {
    return (
        <div className='w-[370px] max-2xl:w-[330px]  max-sm:w-full rounded-xl shadow-lg animate-pulse'>
            <div className='h-[500px] bg-gray-300 rounded-2xl'></div>
            <div className='p-4 bg-white rounded-b-xl'>
                <div className='w-full bg-gray-200 h-6 mb-2 rounded'></div>
                <div className='w-3/4 bg-gray-200 h-6 mb-2 rounded'></div>
                <div className='flex justify-between'>
                    <div className='w-1/4 bg-gray-200 h-6 rounded'></div>
                    <div className='w-1/4 bg-gray-200 h-6 rounded'></div>
                    <div className='w-1/4 bg-gray-200 h-6 rounded'></div>
                </div>
            </div>
        </div>
    );
};

export default LoaderCard;