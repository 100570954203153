import React, { useEffect, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from "react-icons/fa";
import { sendTestmonial } from '../services/mailService';

interface TestimonialPageProps {
  onClose: () => void;
}

const TestmonialPage: React.FC<TestimonialPageProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // State to hold form input values and errors
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formErrors, setFormErrors] = useState({ name: '', email: '', message: '' });
  const [successMessage, setSuccessMessage] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' }); // Clear error when user starts typing
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: { name: string; email: string; message: string } = { name: '', email: '', message: '' };

    // Validation for name
    if (!formData.name.trim()) {
      errors.name = t('testmonial.06') || 'Please enter your name.';
    }

    // Validation for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex to validate email
    if (!formData.email.trim()) {
      errors.email = t('testmonial.07') || 'Please enter your email.';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t('testmonial.11') || 'Please enter a valid email.';
    }

    // Validation for message
    if (!formData.message.trim()) {
      errors.message = t('testmonial.10') || 'Please enter your testimonial.';
    }

    setFormErrors(errors);

    // If there are no errors, reset the form and proceed
    if (!errors.name && !errors.email && !errors.message) {

      setIsSubmitted(true)
      const res = await sendTestmonial(formData)
      setIsSubmitted(false)

      setFormData({ name: '', email: '', message: '' });
      setSuccessMessage(false);

      setTimeout(() => {
        onClose();
      }, 3000);
    }
  };

  useEffect(() => {
    if (successMessage) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding equal to scrollbar width
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = ''; // Reset padding
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = ''; // Cleanup
    };
  }, [successMessage]);

  return (

    <>
      <section className={`fixed px-2 lg:px-0 inset-0 z-[500]  flex ${successMessage ? 'items-center justify-center bg-gray-900 bg-opacity-50' : 'items-start justify-end'}`}>

        {
          successMessage ?
            <div className="p-5 flex items-center justify-center">
              <div className="p-5 flex flex-col shadow-xl bg-white rounded-md md:w-[450px] w-[300px]">
                <div className="relative flex justify-start lg:justify-center ">
                  <h1 className="lg:text-2xl text-lg text-center font-semibold mb-3">{t('testmonial.01')}</h1>
                  <IoClose size={24} className="absolute right-1 cursor-pointer z-40 text-black" onClick={onClose} />
                </div>

                <div>
                  <form className="flex flex-col items-center gap-4" onSubmit={handleSubmit}>
                    <div className="w-full flex flex-col items-start gap-2">
                      <label htmlFor="name" className="text-start">{t('testmonial.02')}<span className='text-Red-600' >*</span></label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`border-2 px-2 py-1 w-full rounded-md focus:outline-none focus:ring-2 bg-blue-50/50 ${formErrors.name ? 'border-Red-600' : ''
                          }`}
                      />
                      {formErrors.name && <p className="text-Red-600 text-sm">{formErrors.name}</p>}
                    </div>
                    <div className="w-full flex flex-col items-start gap-2">
                      <label htmlFor="email" className="text-start">{t('testmonial.03')}<span className='text-Red-600' >*</span></label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`border-2 px-2 py-1 w-full rounded-md focus:outline-none focus:ring-2 bg-blue-50/50 ${formErrors.email ? 'border-Red-600' : ''
                          }`}
                      />
                      {formErrors.email && <p className="text-Red-600 text-sm">{formErrors.email}</p>}
                    </div>
                    <div className="w-full flex flex-col items-start gap-2">
                      <label htmlFor="message" className="text-start">{t('testmonial.04')}<span className='text-Red-600' >*</span></label>
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`w-full h-52 px-2 py-1 border-2 rounded-md focus:outline-none focus:ring-2 bg-blue-50/50 ${formErrors.message ? 'border-Red-600' : ''
                          } `}
                      />
                      {formErrors.message && <p className="text-Red-600 text-sm">{formErrors.message}</p>}
                    </div>
                    {isSubmitted ?
                      <div className="flex justify-center items-center bg-pantone288-600 px-4 py-1 text-white border-yellow-500 rounded border-2">
                        <div className="spinner"></div>
                        <span className="text-md ml-2">{t("send")}</span>
                      </div>
                      :
                      <button
                        type="submit"
                        className="bg-pantone288-600 px-4 py-1 text-white border-yellow-500 rounded border-2 hover:text-yellow-500 hover:bg-white"
                      >
                        {t('testmonial.05')}
                      </button>
                    }

                  </form>
                </div>
              </div>
            </div> :

            <div className='bg-white p-3 m-2 rounded relative shadow-lg z-[500]' >
              {/* <IoClose size={24} className="absolute right-0 top-0 m-3 cursor-pointer z-40 text-black" onClick={onClose} /> */}
              <div className="flex items-center gap-2 justify-center pb-2 ">
                <FaCheckCircle size={25} color='green' className='' />
                <h1 className="text-sm text-center font-semibold text-[green]  ">{t('testmonial.08')}</h1>
              </div>

              {/* <div className="flex justify-center items-center gap-4"> */}
              {/* <button
              className="bg-pantone288-600 rounded-xl px-4 py-1 text-white border-yellow-500  border-2 hover:text-yellow-500 hover:bg-white"
              onClick={onClose}
            >
              {t('testmonial.09')}
            </button> */}
              {/* </div> */}

              <div className='bar  rounded absolute left-0 bottom-0' >
              </div>
            </div>

        }
      </section>
    </>
  );
};

export default TestmonialPage;
