import React from 'react';
import axios from 'axios';


  const API_KEY = process.env.GOOGLE_TRANSLATE_API_KEY ;
  const API_URL = process.env.GOOGLE_TRANSLATE_API_URL;
  export const TranslateText = async (text:any, targetLanguage:any) => {
    const response = await axios.post(
      `${API_URL}?key=${API_KEY}`,
      {
        q: text,
        target: targetLanguage,
      }
    );
    return response.data.data.translations[0].translatedText;
  };
