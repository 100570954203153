import React, { useEffect, useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import Cookies from 'js-cookie';
import { FacebookAuthProvider } from "firebase/auth";
import { useTranslation } from 'react-i18next';

type LoginDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

const LoginDialog: React.FC<LoginDialogProps> = ({ isOpen, onClose }) => {
    const [isSignUp, setIsSignUp] = useState(false);
    const {t} = useTranslation();


    const handleLogin = async (providerName:string) => {
        if(providerName === 'google'){
            const provider = new GoogleAuthProvider();
            try {
                const result: any = await signInWithPopup(auth, provider);
                Cookies.set('accessToken', result.user.accessToken, { expires: 1 });
                Cookies.set('refreshToken', result.user.stsTokenManager.refreshToken, { expires: 1 });
                Cookies.set('userDetails', JSON.stringify(result.user.reloadUserInfo), { expires: 1 });
                onClose();
            } catch (error) {
                console.error(error);
            }

        }

      

        if(providerName === 'facebook'){
            try {
                const provider = new FacebookAuthProvider();
                const result: any = await signInWithPopup(auth, provider);
                Cookies.set('accessToken', result.user.accessToken, { expires: 1 });
                Cookies.set('refreshToken', result.user.stsTokenManager.refreshToken, { expires: 1 });
                Cookies.set('userDetails', JSON.stringify(result.user.reloadUserInfo), { expires: 1 });
                onClose();
            }
            catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding equal to scrollbar width
        } else {
            document.body.style.overflow = '';
            document.body.style.paddingRight = ''; // Reset padding
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = ''; // Cleanup
        };
    }, [isOpen]);

    const handleLoginFailure = (error: any) => {
        console.error('Login failed:', error);
    };

    const toggleForm = () => setIsSignUp(!isSignUp);

    if (!isOpen) return null;

    return (
        <div className="fixed px-2 lg:px-0 inset-0 flex items-center justify-center  bg-gray-900 bg-opacity-50 z-30">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full transition-all duration-300 relative ">
                <IoClose size={24} className="absolute top-4 right-4 cursor-pointer z-40 text-black" onClick={onClose} />
                {/* Title */}
                <h2 className="text-3xl text-black font-semibold text-center mb-6">
                    {isSignUp ? t('signup.00') : t('login.00')}
                </h2>

                {/* Google Login Button */}
                <div className="mb-1 flex justify-center">
                    <button onClick={()=>handleLogin('google')}
                        className="flex items-center gap-1 justify-center w-72 rounded-2xl py-3 px-3 bg-white text-gray-800 border border-gray-400 hover:bg-blue-300 hover:text-black">
                        <FcGoogle size={22} /> {isSignUp ? t('signup.01') : t('login.01')}
                    </button>
                </div>
                <p className='text-center text-gray-400 mb-1'>{t('signup.02')}</p>
                {/* Facebook Login Button */}
                <div className="mb-6 flex justify-center">
                    <button onClick={()=>handleLogin('facebook')}
                        className="flex items-center gap-1 justify-center w-72 rounded-2xl py-3 px-3 bg-pantone288-500 text-white hover:bg-blue-300 hover:text-black">
                        <FaFacebookSquare size={22} /> {isSignUp ? t('signup.03') : t('login.03')}
                    </button>
                </div>

                {/* Toggle between Login/Sign Up */}
                <div className="text-center">
                    <p className="text-gray-600">
                        {isSignUp ? t('signup.04') : t('login.04')}
                        <button
                            onClick={() => setIsSignUp(!isSignUp)}
                            className="text-blue-500 font-semibold hover:underline"
                        >
                            {isSignUp ? t('signup.05') : t('login.05')}
                        </button>
                    </p>
                </div>


            </div>
        </div>
    );
};

export default LoginDialog;
