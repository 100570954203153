// src/components/ReusableMapComponent.tsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { IoBedOutline } from 'react-icons/io5';
import { LiaBathSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import "../../node_modules/react-leaflet-markercluster/dist/styles.min.css";

// Define a default icon to fix marker icon issues
import DefaultIcon from 'leaflet/dist/images/marker-icon.png';
import { useTranslation } from 'react-i18next';
L.Marker.prototype.options.icon = L.icon({
  iconUrl: DefaultIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Define types for the props
interface PositionData {
  LATITUDE: number;
  LONGITUDE: number;
  title: string;
  PRIX_DEMANDE: any;
  PRIX_LOCATION_DEMANDE: any;
  images: string[];
  photos:string[];
  NOM_RUE_COMPLET: string;
  NB_CHAMBRES: number;
  NB_SALLES_BAINS: number;
  property_english: string;
  NO_INSCRIPTION: any;
  englishDescription: any;
  muncipalities: any;
}

interface ReusableMapComponentProps {
  center: [number, number];
  zoom: number;
  data: PositionData[];
}

const MapComponent: React.FC<ReusableMapComponentProps> = ({ center, zoom, data }) => {
  const navigate = useNavigate();
  const {i18n} = useTranslation()

  const language = i18n.language;

  const createCustomMarkerIcon = (label: string, price: number) => {
    return L.divIcon({
      html: `
        <div class="relative flex items-center bg-pantone288-600 text-yellow-500 rounded-lg px-2 py-1 shadow-md w-20">
          
          <div class="font-bold text-white">$ ${price}</div>
          <div class="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-gray-700"></div>
        </div>`,
      iconSize: [0, 0],
      iconAnchor: [25, 50],
    });
  };

  const createClusterCustomIcon = (cluster: any) => {
    const count = cluster.getChildCount();
    return L.divIcon({
      html: `<div class="flex items-center justify-center bg-pantone288-600 text-yellow-500 rounded-full w-12 h-12">${count}</div>`,
      className: 'custom-cluster-icon',
      iconSize: L.point(45, 45, true),
    });
  };

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '100vh', width: '100%',zIndex:'50' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
        {data.map((position, index) => (
          <Marker
            key={index}
            position={[position.LATITUDE, position.LONGITUDE]}
            icon={createCustomMarkerIcon('CA', position.PRIX_DEMANDE ? position.PRIX_DEMANDE : position.PRIX_LOCATION_DEMANDE)}
          >
            <Popup className="leafletPopup">
              <div
                className="flex flex-row w-96 gap-2 border border-gray-300 rounded-xl p-2 font-open-sans cursor-pointer"
                onClick={() => window.open(`/${language}/listings.php?mls=${position.NO_INSCRIPTION}`, '_blank')}
              >
                <img src={position.images ? position.images[0] : position.photos[0]} alt="home" className="w-28 h-28 object-cover rounded-lg" />
                <div className="flex flex-col gap-2">
                  <h3 className="text-lg font-bold">$ {position.PRIX_DEMANDE ? position.PRIX_DEMANDE : position.PRIX_LOCATION_DEMANDE}</h3>
                  <p className="text-md mr-0">{position.NOM_RUE_COMPLET}</p>
                  <div className="flex items-center divide-x-2 gap-3">
                    <div className="flex items-center gap-1">
                      <IoBedOutline />
                      <span>{position.NB_CHAMBRES}</span>
                    </div>
                    <div className="flex items-center gap-1 pl-2">
                      <LiaBathSolid />
                      <span>{position.NB_SALLES_BAINS}</span>
                    </div>
                  </div>
                  <p>{position.muncipalities}</p>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default MapComponent;
