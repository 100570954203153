import React, { useEffect, useState } from 'react';
import Header from './header';

// Extend the Window interface to include Reciprocity
declare global {
  interface Window {
    Reciprocity?: {
      init: (config: { membersite: string; parentLocation: Location; onComplete?: () => void }) => void;
    };
  }
}

export default function GlobalLuxurySearch() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const scriptUrl = 'https://tremblantrealestate.luxuryrealestate.com/reciprocity.js';

    // Cleanup function to remove the script
    const cleanup = () => {
      const script = document.querySelector(`script[src="${scriptUrl}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };

    // Dynamically load the Reciprocity script
    const loadReciprocityScript = () => {
      const existingScript = document.querySelector(`script[src="${scriptUrl}"]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.type = 'text/javascript';

        script.onload = () => {
          initializeReciprocity(() => {
            setLoading(false); // Set loading to false after full initialization
          });
        };

        script.onerror = () => {
          console.error('Failed to load Reciprocity script');
          setLoading(false); // Ensure loading stops
        };

        document.body.appendChild(script);
      } else {
        initializeReciprocity(() => {
          setLoading(false); // Set loading to false after full initialization
        });
      }
    };

    // Start script loading
    loadReciprocityScript();

    return cleanup; // Cleanup script on unmount
  }, []);

  // Initialize Reciprocity
  const initializeReciprocity = (onComplete: () => void) => {
    try {
      const reciprocityContainer = document.getElementById('reciprocity');
      if (!reciprocityContainer) {
        console.error('Reciprocity container is missing.');
        onComplete(); // End loading even if the container is missing
        return;
      }

      // Clear old content if any
      reciprocityContainer.innerHTML = '';

      if (window.Reciprocity) {
        window.Reciprocity.init({
          membersite: 'tremblantrealestate',
          parentLocation: window.location,
          onComplete: () => {
            console.log('Reciprocity initialization complete.');
            onComplete(); // Notify that initialization is complete
          },
        });
      } else {
        console.error('Reciprocity is not defined');
        onComplete(); // Fallback in case of an error
      }
    } catch (error) {
      console.error('Error initializing Reciprocity:', error);
      onComplete(); // Ensure loading stops even on error
    }
  };

  return (
    <div>
      <Header />
      <div className="md:pt-32 pt-20 px-4 md:px-20">
        {/* Always render the container */}
        <div className="" id="reciprocity"></div>
        {loading && <div>Loading...</div>}
      </div>
    </div>
  );
}
