import React, { createContext, useState, ReactNode, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../i18n/can-en.json';
import fr from '../i18n/fr.json';


const pathSegments = window.location.pathname.split("/"); 
const detectedLanguage = pathSegments[1] === "en" ? "en" : "fr"; 


i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr }
  },
  lng: detectedLanguage,
  fallbackLng: detectedLanguage,
  interpolation: { escapeValue: false }
});

type LanguageContextType = {
  language: string;
  setLanguage: (lang: string) => void;
};

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState(detectedLanguage);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    // updateUrl(lang);
  };

  // const updateUrl = (lang: string) => {
  //   const newUrl = `/${lang}${window.location.pathname.replace(/^\/(fr|en)/, '')}`;
  //   window.history.pushState({}, '', newUrl);
  // };

  // // Update the URL on initial load based on the current language
  useEffect(() => {
    // updateUrl(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage: changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};