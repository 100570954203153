import React, { useState, useEffect, useContext } from 'react';
import { getAllListingsMap } from '../services/listingService';
import MapComponent from '../components/map';
import { FilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import Loading from '../components/loading';


// Define type for map data
interface PositionData {
  LATITUDE: number;
  LONGITUDE: number;
  title: string;
  PRIX_DEMANDE: any;
  PRIX_LOCATION_DEMANDE: any;
  images: string[];
  NOM_RUE_COMPLET: string;
  NB_CHAMBRES: number;
  NB_SALLES_BAINS: number;
  property_english: string;
  NO_INSCRIPTION: any;
  englishDescription: any;
  muncipalities: any;
  photos:string[];
}

interface MapViewProps {
  data?: PositionData[]; // Optional data prop
}

const MapView: React.FC<MapViewProps> = ({ data: externalData }) => {
  const [data, setData] = useState<PositionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { currentView, collections, propertyType, minPrice, maxPrice, bathrooms, bedrooms, purchaseType  } = useContext(FilterContext);
  const {t} = useTranslation();

  useEffect(()=>{
    document.title = t('title.map')
},[t])

const handleExternalData = (newData: PositionData[]) => {
  setData(newData);
};






useEffect(() => {
  if (externalData) {
    handleExternalData(externalData);
     return;// Early return if externalData is present
  }

  const fetchData = async () => {
    setLoading(true);

    // Default query if externalData exists
    let query =  `status=${collections === 'locations-saisonnieres' ? 'Lease' : purchaseType}&collection=${collections}&propertyType=${propertyType.key}&bed=${bedrooms}&bath=${bathrooms}&minPrice=${minPrice}&maxPrice=${maxPrice}`;

    console.log("query ",query); // This will now be logged even if externalData is present
    
    try {
      const dataList = await getAllListingsMap(query);
      setData(dataList.data);
      setError(null);
    } catch (err) {
      setError('Error fetching data');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [externalData, currentView, collections, propertyType, minPrice, maxPrice, bathrooms, bedrooms, purchaseType]);


    
    


  if (loading) {
    return <div className='flex justify-center items-center h-[100px]'  >
      <Loading></Loading>
    </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Default center and zoom for the map
  const defaultCenter: [number, number] = [46.14101243, -74.58735217]; // Set a default center
  const defaultZoom: number = 10;

  return (
    <div>
      <MapComponent
        center={defaultCenter} // Default map center
        zoom={defaultZoom}     // Default zoom level
        data={data}            // Pass the fetched data to the map
      />
    </div>
  );
};

export default MapView;
