import React, { useEffect, useTransition } from 'react';
import CollectionCard from '../components/collectionCards';
import Header from '../components/header';
import { useTranslation } from 'react-i18next';
import exclusivePropertyimg from '../assets/images/exclusivepropertyImg.jpg';
import signaturePropertyimg from '../assets/images/signaturePropertyimg.jpg';
import countryPropertyimg from '../assets/images/country-homes-img.jpg';
import waterFrontimg from '../assets/images/waterfront-img.png';
import contryRegionalImg from '../assets/images/condosRegionalimg.jpg';
import mountainpropertyimg from '../assets/images/mountain_properties.jpg';
import pedestrianVillageimg from '../assets/images/pedestrianVillageimg.jpg';
import skiInSkiOutimg from '../assets/images/skyIn-skyOutimg.jpg';
import projectTerrainsImg from '../assets/images/projectsTerrainsimg.jpg';
import commercialPropimg from '../assets/images/commercialpropImg.jpg';
import seasonalRentalimg from '../assets/images/seasonalRentalsimg.png';
import internationalPropimg from '../assets/images/internationalPropimg.jpg'





const Collections:React.FC = () => {
const {t} = useTranslation();
const { i18n } = useTranslation();

  const language = i18n.language

useEffect(()=>{
  document.title = t('title.collections')
},[t])

const properties = [
  { name: t('properties.exclusive.01'),key : 'exclusives', description: t('properties.exclusive.02'), imageUrl: exclusivePropertyimg },
  { name: t('properties.signature.01'),key: 'maisons-signature', description: t('properties.signature.02'), imageUrl: signaturePropertyimg },
  { name: t('properties.country.01'),key: 'maisons-de-campagne', description: t('properties.country.02'), imageUrl: countryPropertyimg },
  { name: t('properties.waterfront.01'),key: 'proprietes-riveraines', description: t('properties.waterfront.02') ,imageUrl: waterFrontimg},
  { name: t('properties.regional.01'),key: 'condos-regionaux', description: t('properties.regional.02'), imageUrl: contryRegionalImg},
  { name: t('properties.mountain.01'), key: 'pres-golf', description: t('properties.mountain.02'), imageUrl: mountainpropertyimg},
  { name: t('properties.pedestrian.01'),key: 'centre-de-villegiature', description: t('properties.pedestrian.02'), imageUrl: pedestrianVillageimg},
  { name: t('properties.mountainD.01'),key: 'ski-in-ski-out', description:t('properties.mountainD.02'), imageUrl: skiInSkiOutimg},
  { name: t('properties.projects.01'),key: 'terrains-et-projets', description: t('properties.projects.02'), imageUrl: projectTerrainsImg},
  { name: t('properties.commercial.01'),key: 'commercial', description:t('properties.commercial.02'), imageUrl: commercialPropimg},
  { name: t('properties.seasonal.01'),key: 'locations-saisonnieres', description: t('properties.seasonal.02'), imageUrl: seasonalRentalimg},
  { name: t('properties.international.01'),key: 'international-properties', description: t('properties.international.02'), imageUrl: internationalPropimg}

];

  return (
    <>
    <Header />
    <div className="bg-pantone288-900 min-h-screen p-8 md:pt-36 pt-24">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 Collections">
      {properties.map((property, index) => (
        <CollectionCard
          key={index}
          title={property.name}
          description={property.description}
          imageUrl={property.imageUrl}
          keyVal={property.key}
        />
      ))}
    </div>
  </div>
  </>
  );
}

export default Collections;
