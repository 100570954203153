


import { useEffect, useState } from "react";
import Header from "../components/header";
import { useTranslation } from "react-i18next";

const LeagalNotice = () => {
  const { t } = useTranslation();
  const [activeSection, setactiveSection] = useState("personal_cookie");
  useEffect(()=>{
    document.title = t('title.legal')
},[t])
  return (
    <>
      <Header />
      <div className="flex gap-5 items-center justify-center p-7 md:pt-36 pt-24">
        <h1
          onClick={() => setactiveSection("personal_cookie")}
          className={`text-xl hover:underline hover:text-yellow-500 cursor-pointer
            ${
              activeSection === "personal_cookie"
                ? "underline text-yellow-500"
                : ""
            }`}
        >
          {t("legal.01")}
        </h1>
        <h1
          onClick={() => setactiveSection("cookie")}
          className={`text-xl hover:underline hover:text-yellow-500 cursor-pointer
                ${
                  activeSection === "cookie" ? "underline text-yellow-500" : ""
                }`}
        >
          {t("legal.02")}
        </h1>
      </div>
      <div>
        {activeSection === "cookie" && <CookiePolicy />}
        {activeSection === "personal_cookie" && <PersonalPolicy />}
      </div>
    </>
  );
};

export default LeagalNotice;

const CookiePolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="text-start text-l mt-5 lg:px-24 px-5">
      <h1 className="text-2xl mb-5">{t("legal.001")}</h1>
      <p>{t("legal.002")}</p>
      <h2 className="text-xl mb-5 mt-4">{t("legal.003")}</h2>
      <p>{t("legal.004")}</p>
      <p>{t("legal.005")}</p>
      <p>{t("legal.006")}</p>
      <p>{t("legal.007")}</p>
      <h2 className="text-xl mb-5 mt-4">{t("legal.008")}</h2>
      <p>{t("legal.009")}</p>
      <p>{t("legal.010")}</p>
      <p>{t("legal.011")}</p>
      <p>{t("legal.012")}</p>
      <h3 className="text-xl mb-5 mt-4">{t("legal.013")}</h3>
      <p>{t("legal.014")}</p>
      <h3 className="text-xl mb-5 mt-4">{t("legal.015")}</h3>
      <p>{t("legal.016")}</p>
      <h3 className="text-xl mb-5 mt-4">{t("legal.017")}</h3>
      <p>{t("legal.018")}</p>
      <h3 className="text-xl mb-5 mt-4">{t("legal.019")}</h3>
      <p>{t("legal.020")}</p>
      <p>
        <a href="javascript:Didomi.preferences.show()">{t("legal.021")}</a>
      </p>
      <h2 className="text-xl mb-5 mt-4">{t("legal.022")}</h2>
      <p>{t("legal.023")}</p>
      <p>{t("legal.024")}</p>
      <p>{t("legal.025")}</p>
      <p>{t("legal.026")}</p>
      <h2 className="text-xl mb-5 mt-4">{t("legal.027")}</h2>
      <p>{t("legal.028")}</p>
      <h2 className="text-xl mb-5 mt-4">{t("legal.029")}</h2>
      <p>
        {t("legal.030")}{" "}
        <a href="mailto:consent.mtre@royallepage.ca">
          consent.mtre@royallepage.ca
        </a>
      </p>
      <p>{t("legal.031")}</p>
    </div>
  );
};

const PersonalPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="text-start text-l mt-5 lg:px-24 px-5">
      <h1 className="mt-3 text-2xl mb-7 font-serif">
        LES IMMEUBLES MONT-TREMBLANT REAL ESTATE {t("legal.03")}
      </h1>
      <p className="mb-7">
        At LES IMMEUBLES MONT-TREMBLANT REAL ESTATE, {t("legal.04")}.
      </p>
      <p className="mb-7">{t("legal.05")}</p>
      <p className="mb-7">{t("legal.06")}</p>
      <p className="mb-7">{t("legal.07")}</p>
      <p className="mb-7">{t("legal.08")}</p>
      <h2 className="text-xl mb-7">1. {t("legal.09")}</h2>
      <p className="mb-7">{t("legal.10")}</p>
      <h2 className="text-xl mb-7">2. {t("legal.11")}</h2>
      <p className="mb-7">
        {t("legal.12")} <em>{t("legal.13")}</em>,{t("legal.14")}
      </p>
      <p className="mb-7">{t("legal.15")}</p>
      <h2 className="text-xl mb-3">3. {t("legal.16")}</h2>
      <p className="mb-7">{t("legal.17")}</p>
      <ul className="list-disc mx-4">
        <li>{t("legal.18")}</li>
        <li>{t("legal.19")}</li>
        <li>{t("legal.20")}</li>
        <li>{t("legal.21")}</li>
        <li>{t("legal.22")}</li>
        <li>{t("legal.23")}</li>
        <li>{t("legal.24")}</li>
        <li>{t("legal.25")}</li>
      </ul>
      <p className="mb-7">{t("legal.26")}</p>
      <h2 className="text-xl mb-8">4. {t("legal.27")}</h2>
      <p className="mb-7">{t("legal.28")}</p>
      <p className="mb-7">{t("legal.29")}.</p>
      <p className="mb-7">{t("legal.30")}</p>
      <p className="mb-7">{t("legal.31")}</p>
      <p className="mb-7">{t("legal.32")}</p>
      <p className="mb-7">{t("legal.33")}</p>
      <h2 className="text-xl mb-8">5.{t("legal.34")}</h2>
      <p>{t("legal.35")}</p>
      <p className="mb-7">{t("legal.36")}</p>
      <ul className="mb-7 list-disc mx-4">
        <li>{t("legal.37")}</li>
        <li>
          {t("legal.38")}
          <ul className="mb-1 list-disc">
            <li>{t("legal.39")}</li>
            <li>{t("legal.40")}</li>
            <li>{t("legal.41")}</li>
          </ul>
        </li>
        <li>
          {t("legal.42")}
          <ul>
            <li>{t("legal.43")}</li>
            <li>{t("legal.44")}</li>
          </ul>
        </li>
        <li>{t("legal.45")}</li>
        <li>{t("legal.46")}</li>
        <li>{t("legal.47")}</li>
        <li>{t("legal.48")}</li>
        <li>{t("legal.49")}</li>
        <li>{t("legal.50")}</li>
      </ul>
      <h2 className="text-xl mb-7">{t("legal.51")}</h2>
      <p className="mb-7">{t("legal.52")}</p>
      <p className="mb-7">{t("legal.53")}</p>
      <p>{t("legal.54")}</p>
      <h2 className="text-xl mb-8">{t("legal.55")}</h2>
      <p className="mb-7">{t("legal.56")}</p>
      <h2 className="text-xl mb-8">{t("legal.57")}</h2>
      <p className="mb-7">{t("legal.58")}</p>
      <h2 className="text-xl mb-8">{t("legal.59")}</h2>
      <p className="mb-7">{t("legal.60")}</p>
      <p className="mb-7">{t("legal.61")}</p>
      <p className="mb-7">{t("legal.62")}</p>
      <h2 className="text-xl mb-8">{t("legal.63")}</h2>
      <p className="mb-7">{t("legal.64")}</p>
      <ul className="mb-7">
        <li>
          <em>{t("legal.65")}</em>
          {t("legal.66")}
        </li>
        <li>
          <em>{t("legal.67")}</em>
          {t("legal.68")}
        </li>
        <li>
          <em>{t("legal.69")}</em>
          {t("legal.70")}
        </li>
        <li>
          <em>{t("legal.71")}</em>
          {t("legal.72")}
        </li>
      </ul>
      <p className="mb-7">{t("legal.73")}</p>
      <h2 className="text-xl mb-8">{t("legal.74")}</h2>
      <p className="mb-7">{t("legal.75")}</p>
      <p className="mb-7">{t("legal.76")}</p>
      <p className="mb-7">{t("legal.77")}</p>
      <ul>
        <li>{t("legal.78")}</li>
        <li>{t("legal.79")}</li>
        <li>{t("legal.80")}</li>
        <li>{t("legal.81")}</li>
      </ul>
      <p className="mb-7">{t("legal.82")}</p>
      <p className="mb-7">{t("legal.83")}</p>
      <h2 className="text-xl mb-8">{t("legal.84")}</h2>
      <p className="mb-7">
        {t("legal.85")}
        <a href="mailto:consent.mtre@royallepage.ca">
          consent.mtre@royallepage.ca
        </a>
        {t("legal.86")}
      </p>
      <h2 className="text-xl mb-8"> {t("legal.87")}</h2>
      <p className="mb-7">{t("legal.88")}</p>
      <p className="mb-7">{t("legal.89")}</p>
    </div>
  );
};