import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';



export default function CookiesPopUp() {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const language = i18n.language
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setShowBanner(false);
    };

    const handleReject = () => {
        localStorage.setItem("cookieConsent", "rejected");
        setShowBanner(false);
    };

    if (!showBanner) return null;


    return (
        <div className='p-5 md:w-[400px] rounded-lg sm:w-full bg-white shadow-sm shadow-gray-400 text-start'>
            <div className='mb-4'>
            <p className='mb-4 text-xl font-bold text-gray-800'>{t('cookiespop.00')}</p>
            <p className='text-sm text-gray-600'>
            {t('cookiespop.01')} <span className='font-semibold'>{t('cookiespop.02')}, </span>{t('cookiespop.03')}{' '}
                <a href={`/${language}/cookies`} className='text-blue-500 underline hover:text-blue-700'>
                {t('cookiespop.04')}
                </a>.
            </p>
            </div>
            <div className='flex justify-between mt-4 gap-2'>
            <button className='px-4 py-2 text-sm bg-pantone288-500 text-white rounded-lg border border-pantone288-500 hover:bg-white hover:text-pantone288-500 hover:border-pantone288-500 transition-all' onClick={handleAccept}>
            {t('cookiespop.05')}
            </button>
            <button className='px-4 py-2 text-sm bg-yellow-500 text-white rounded-lg border border-yellow-500 hover:bg-white hover:text-yellow-500 hover:border-yellow-500 transition-all' onClick={handleReject}>
            {t('cookiespop.06')}
            </button>
            </div>
        </div>
    )
}
