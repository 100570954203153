import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL || ''; 

export const sendMail = async (data: any) => {
  try {
    const response = await axios.post(`${API_URL}/email`, data);
    return response.data;
  } catch (error: any) {
    console.error("Error sending email:", error.response?.data || error.message);
    throw error;
  }
};

export const sendTestmonial = async (data: any) =>{
  try{
    const response = await axios.post(`${API_URL}/testimonial`, data);
    return response.data;
  }
  catch(error){
    console.error('Failed to send testimonial:', error);
    throw error;
  }
}

export const sendEnquiry = async (data: any) =>{
  try{
    const response = await axios.post(`${API_URL}/enquirymail`, data);
    return response.data;
  }
  catch(error){
    console.error('Failed to send inquiry:', error);
    throw error;
  }
}