import React, { useEffect } from "react";
import Header from "../../components/header";
import img1 from "../../assets/images/06Labelle-surroundings-scaled.webp"
import img2 from "../../assets/images/LaBelle-2.webp";
import img3 from "../../assets/images/labelle-3.webp";
import img4 from "../../assets/images/SKI-DO.webp"
import img6 from "../../assets/images/Riviere rouge.webp"
import img7 from '../../assets/images/mont-blac-4.webp'
import img5 from '../../assets/images/labelle-attraction.webp'
import { useTranslation } from "react-i18next";

const LabelleSurroundings = () => {
  const { t } = useTranslation();

  useEffect(()=>{
      document.title = t('title.labelle')
  },[t])

  return (
    <>
      <Header />
      <div className="px-4 md:px-20 md:pt-36 pt-24">
        {/* Heading */}
        <div className="font-light text-start">
          <h1 className="text-2xl text-yellow-500 mt-3">{t("communities.labelle.explore")}</h1>
          <h1 className="text-4xl md:text-6xl">{t("communities.labelle.header")}</h1>
          <p className="text-gray-600 mt-2">
            <span className="font-semibold">{t("communities.labelle.explore1")}(La Minerve, Rivière Rouge, Nominingue) </span>
            {t("communities.labelle.explore2")}
          </p>
        </div>

        {/* content-1 */}
        <div className="p-2 flex gap-2 lg:mt-5 flex-col md:flex-row">
          <div className='w-full md:w-1/2 lg:p-5 h-auto md:h-[400px]'>
            <img src={img1} alt='mont-blac' className='rounded-lg h-full w-full object-cover' />
          </div>
          <div className='w-full md:w-1/2 text-start mt-2 lg:mt-0 lg:p-5 flex flex-col gap-3'>
            <h1 className="text-yellow-500 text-2xl font-light">{t("communities.labelle.geography.00")}</h1>
            <p className="text-gray-500 mt-2">
              <span className="font-semibold">{t("communities.labelle.geography.01")}</span> {t("communities.labelle.geography.02")}
            </p>
            <p className='text-gray-500'>
              {t("communities.labelle.geography.03")}
              <span className="font-semibold"> Rivière Rouge </span>
              {t("communities.labelle.geography.04")}
            </p>
          </div>
        </div>

        {/* content-2 */}
        <div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col md:flex-row">
          <div className='w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
            <h1 className="text-yellow-500 text-2xl font-light">{t("communities.labelle.outdoor.00")}</h1>
            <p className='text-gray-500 mt-2'>{t("communities.labelle.outdoor.01")}</p>
            <p className='text-gray-500 mt-3'>
              <span className="font-semibold">{t("communities.labelle.outdoor.02")}</span>{t("communities.labelle.outdoor.03")}
            </p>
            <p className='text-gray-500 mt-3'>
              <span className="font-semibold">{t("communities.labelle.outdoor.04")}</span>{t("communities.labelle.outdoor.05")}
              {t("communities.labelle.geography.03")} <span className="font-semibold">Rivière Rouge</span>
              {t("communities.labelle.outdoor.06")} {t("communities.labelle.outdoor.08")} <span className="font-semibold">Parc Linéaire Le P’tit Train du Nord</span>
              {t("communities.labelle.outdoor.07")}
            </p>
          </div>
          <div className='w-full md:w-1/2 text-start h-auto md:h-[400px]'>
            <img src={img2} alt='mont-blac' className='rounded-lg h-full w-full object-cover' />
          </div>
        </div>

        {/* content-3 */}
        <div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col-reverse md:flex-row">
          <div className='w-full md:w-1/2 lg:p-5 h-auto md:h-[400px]'>
            <img src={img3} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
          </div>
          <div className='w-full md:w-1/2 text-start mt-2 lg:mt-0 lg:p-5 flex flex-col gap-4'>
            <h1 className='text-yellow-500 text-2xl font-light'>{t("communities.labelle.real-estate.00")}</h1>
            <p className='text-gray-500'>
              <span className="font-semibold">{t("communities.labelle.real-estate.01")}</span> {t("communities.labelle.real-estate.02")}
            </p>
            <p className='text-gray-500'>{t("communities.labelle.real-estate.03")}</p>
          </div>
        </div>

        {/* content-4 */}
        <div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col md:flex-row">
          <div className='w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
            <h1 className="text-yellow-500 text-2xl font-light">{t("communities.labelle.community.00")}</h1>
            <p className='text-gray-500'>{t("communities.labelle.community.01")}</p>
            <p className='text-gray-500'>{t("communities.labelle.community.02")}</p>
          </div>
          <div className='w-full md:w-1/2 lg:p-5 h-[300px] md:h-[400px] text-start'>
            <img src={img4} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
          </div>
        </div>

        {/* content-5 */}
        <div className="p-2 flex gap-2 lg:selection lg:mt-5 items-stretch flex-col-reverse md:flex-row">
          <div className='w-full md:w-1/2 lg:p-5 h-[300px] md:h-[400px]'>
            <img src={img5} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
          </div>
          <div className='w-full md:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
            <h1 className='text-yellow-500 text-2xl font-light'>{t("communities.labelle.attractions.00")}</h1>
            <p className='text-gray-500'>
            {t("communities.labelle.outdoor.08")} <span className="font-semibold">{t("communities.labelle.attractions.01")}</span> {t("communities.labelle.attractions.02")}
            </p>
            <p className='text-gray-500'>
              {t("communities.labelle.attractions.03")} <span className="font-semibold">{t("communities.labelle.attractions.04")}</span> {t("communities.labelle.attractions.05")}
            </p>
            <p className='text-gray-500'>{t("communities.labelle.attractions.06")}</p>
          </div>
        </div>

        {/* content-6 */}
        <div className="p-2 flex gap-2 lg:mt-5 items-stretch flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-start lg:p-5 flex flex-col justify-between">
            <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.labelle.population.00")}</h1>
            <p className="text-gray-500 mt-2">{t("communities.labelle.population.01")}</p>
            <p className="text-gray-500 mt-2">{t("communities.labelle.population.02")}</p>
            <p className="text-gray-500 mt-2">{t("communities.labelle.population.03")}</p>
            <p className="text-gray-500 mt-2">{t("communities.labelle.population.04")}</p>
            <p className="text-gray-500 mt-2">{t("communities.labelle.population.06")}</p>

          </div>
          <div className="w-full md:w-1/2 lg:p-5 h-[300px] md:h-[400px]">
            <img src={img6} alt="saint-Agathe" className="rounded-lg h-full w-full object-cover" />
          </div>
        </div>

        <div className="mt-3 lg:ps-7 pb-7 ps-2 text-start">
          <p className="text-gray-500 mt-2">{t("communities.labelle.population.05")}</p>

          <p className="font-semibold text-lg mt-2">{t("communities.labelle.school")}</p>

          <p className="text-gray-500 ms-2">-Polyvalente Curé-Mercure</p>
          <p className="text-gray-500 ms-2 ">-CEGEP</p>

          <h1 className='font-semibold text-lg mt-5'>{t("communities.English-Schools")}</h1>
          <p className='text-gray-500 mt-2'>{t("communities.labelle.school-eng.00")}</p>
        </div>
      </div>
    </>
  );
};

export default LabelleSurroundings;
