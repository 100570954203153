import { t } from "i18next";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IndividualCollectionsProps {
    price: number | string; // Use specific types instead of `any`
}

// The component should accept props of type IndividualCollectionsProps
const IndividualCollections: React.FC<IndividualCollectionsProps> = ({ price }) => {

    useEffect(()=>{
        document.title = t('title.collections')
      },[t])
    const { title } = useParams<{ title: string }>();
    return (
        <div>
            <h1>{title}</h1>
            <p>Price: {price}</p>
        </div>
    );
};

export default IndividualCollections;
