import React from 'react';
import { useNavigate } from 'react-router-dom';
import superior from '../assets/images/01Lac-Superieur-1536x1023.webp';
import montBloc from '../assets/images/03-Mont-Blanc-scaled.webp';
import tremblant from '../assets/images/02Mont-Tremblant.webp';
import agathe from '../assets/images/04Ste-Agathe.webp';
import lesAurentdeis from '../assets/images/05-LES-LAURENTIDES.webp';
import labelle from '../assets/images/06Labelle-surroundings-scaled.webp';
import { useTranslation } from 'react-i18next';

interface Location {
  image: string;
  title: string;
  subtitle: string;
}

interface LocationCardProps {
  location: Location;
  onClick: () => void;
}

const LocationCard: React.FC<LocationCardProps> = ({ location, onClick }) => (
  <div
    className="relative h-80 overflow-hidden group cursor-pointer"
    onClick={onClick}
  >
    <div
      className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
      style={{ backgroundImage: `url(${location.image})` }}
    ></div>
    <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-500 group-hover:bg-opacity-30"></div>
    <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
      <h3 className="text-xl">{location.title}</h3>
      <p className="text-base">{location.subtitle}</p>
    </div>
  </div>
);




const LocationGrid: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const language = i18n.language;

  const locations = [
    {
      image: superior,
      title: t('location_grid.1.title'),
      subtitle: t('location_grid.1.sub_title'),
      key: `/${language}/communities/lac-superieur`,

    },
    {
      image: tremblant,
      title: t('location_grid.2.title'),
      subtitle: t('location_grid.2.sub_title'),
      key: `/${language}/communities/mont-tremblant`,

    },
    {
      image: montBloc,
      title: t('location_grid.3.title'),
      subtitle: t('location_grid.3.sub_title'),
      key: `/${language}/communities/mont-blac`,
    },
    {
      image: agathe,
      title: t('location_grid.4.title'),
      subtitle: t('location_grid.4.sub_title'),
      key: `/${language}/communities/sainte-agathe-des-monts`,
    },
    {
      image: lesAurentdeis,
      title: t('location_grid.5.title'),
      subtitle: t('location_grid.5.sub_title'),
      key: `/${language}/communities/laurentians`,
    },
    {
      image: labelle,
      title: t('location_grid.6.title'),
      subtitle: t('location_grid.6.sub_title'),
      key: `/${language}/communities/labelle-surroundings`,
    },
  ];

  return (
    <div className="bg-pantone288-50 py-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {locations.map((location, index) => (
          <LocationCard
            key={index}
            location={location}
            onClick={() => navigate(location.key)}
          />
        ))}
      </div>
    </div>
  );
};

export default LocationGrid;