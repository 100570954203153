import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

interface ImageSlider {
    images: any;
    initialIndex: number;
}

const ImageSlider: React.FC<ImageSlider> = ({ images, initialIndex }) => {
    const slider: any = React.useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Slider settings with responsive behavior
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        arrows: false,
        initialSlide: initialIndex || 0,
        afterChange: (index: number) => setCurrentIndex(index),
        responsive: [
            {
                breakpoint: 640, // Small devices (phones)
                settings: {
                    slidesToShow: 1, // Show 1 slide per view on mobile
                },
            },
            {
                breakpoint: 768, // Medium devices (tablets)
                settings: {
                    slidesToShow: 1, // Show 1 slide per view on tablets
                },
            },
            {
                breakpoint: 1024, // Larger devices (desktops)
                settings: {
                    slidesToShow: 1, // Still show 1 slide per view on larger screens, you can adjust this if needed
                },
            },
        ],
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
            <div className="relative w-full max-w-4xl mx-auto">
                <Slider {...settings} ref={slider}>
                    {images.map((image: any, index: number) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                className="w-full h-auto" // Ensure images adjust based on screen size
                            />
                        </div>
                    ))}
                </Slider>

                {/* Right Arrow */}
                <div
                    className={`p-4 rounded-lg bg-Indigo-800/70 absolute top-[50%] left-[90%] cursor-pointer hover:opacity-70 transition-all duration-300 ${currentIndex < images.length - 1 ? '' : 'opacity-40'}`}
                    onClick={() => currentIndex < images.length - 1 && slider.current.slickNext()}
                >
                    <FaArrowRight color="#ffffff" />
                </div>

                {/* Left Arrow */}
                <div
                    className={`p-4 rounded-lg bg-Indigo-800/70 absolute top-[50%] right-[90%] cursor-pointer transition-all duration-300 ${currentIndex > 0 ? '' : 'opacity-40'}`}
                    onClick={() => currentIndex > 0 && slider.current.slickPrev()}
                >
                    <FaArrowLeft color="#ffffff" />
                </div>
            </div>
        </div>
    );
}

export default ImageSlider;
