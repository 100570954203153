import React from 'react';
import { useTranslation } from 'react-i18next';


const NoDataFound = () => {
    const { t } = useTranslation();
  
  return (
    <div className="flex flex-col items-center justify-center min-h-[300px] text-center px-4 py-8 bg-gray-50 rounded-2xl shadow-md">
      <div className="bg-yellow-100 p-4 rounded-full mb-4">
        <svg
          className="w-10 h-10 text-yellow-600"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v2m0 4h.01M4.93 19h14.14a1 1 0 00.87-1.5l-7.07-12.25a1 1 0 00-1.74 0L4.06 17.5a1 1 0 00.87 1.5z"
          />
        </svg>
      </div>
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        {t('individualListing.Oops, no data found')}
      </h2>
      <p className="text-gray-500">
        {t('error2')}
      </p>
    </div>
  );
};

export default NoDataFound;
