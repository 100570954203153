import React from 'react';
import { useTranslation } from 'react-i18next';


const ExclusiveListingsSection: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const language = i18n.language

  return (
    <div className="flex flex-col items-center justify-center py-5 lg:py-10 gap-5 bg-white">
      {/* Subtitle */}
      <h3 className="text-base font-medium text-yellow-500 tracking-wider mb-2">
        {t("exclusive_listings.luxury")}
      </h3>

      {/* Main Title */}
      <h2 className="text-2xl lg:text-6xl text-gray-900 " >
        {t("exclusive_listings.exclusive")} 
      </h2>

      <h2 className="text-2xl lg:text-6xl text-gray-900 mb-2 " style={{marginTop:"-15px"}}>
      {t("exclusive_listings.listings")}
      </h2>


      {/* Description */}
      <p className="text-center text-gray-500 max-w-2xl mb-2">
        {t("exclusive_listings.content")}
      </p>

      {/* Button */}
      <button className="px-6 py-3 bg-pantone288-700 hover:bg-yellow-500 hover:text-white text-yellow-500 font-semibold  transition duration-300"
      onClick={(e) => {
          e.preventDefault();
          const url = new URL(window.location.origin + `/${language}/listings`);
          url.searchParams.append('collections', 'exclusives');
          window.location.href = url.toString();
      } }>
      {t("exclusive_listings.button")}
      </button>
    </div>
  );
};

export default ExclusiveListingsSection;
