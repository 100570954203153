import React from 'react';
import { IoCallOutline } from "react-icons/io5";
import { CiSearch, CiMail } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const BrokerDetails: React.FC<{ brokers: any[] }> = ({ brokers }) => {

    const { i18n } = useTranslation();
   
    
    const { t } = useTranslation();
    const navigate = useNavigate()

    let language = i18n.language;
    return (
        <div className='flex flex-col gap-3'>
            {brokers && brokers.length > 0 ? (
                brokers.map((broker, index) => (
                    broker && (
                        <div key={index} className="broker-card text-start p-4 border rounded-lg bg-white cursor-pointer"
                        onClick={() => navigate(`/${language}/agent/${broker.PRENOM.toLowerCase()}-${broker.NOM.toLowerCase()}`)}>
                            <img
                                src={broker.PHOTO_URL}
                                alt={`${broker.PRENOM} ${broker.NOM}`}
                                className="h-48 object-contain w-full rounded-t-lg"
                            />
                            <div className="mt-4 text-center">
                                <h2 className="text-2xl font-semibold text-gray-800">{broker.PRENOM} {broker.NOM}</h2>
                                <p className="text-lg font-semibold text-gray-600">{broker.NOM_SOCIETE}</p>
                                <p className="text-md text-gray-500 mb-2">{broker.TITRE_PROFESSIONNEL || `${t('individualListing.Real Estate Broker')}`}</p>
                                <a href={`mailto:${broker.COURRIEL}`} className="flex items-center justify-center gap-2 text-blue-600 hover:text-blue-800 mt-1">
                                    <CiMail className="text-blue-600" />
                                    <span>{broker.COURRIEL}</span>
                                </a>
                                <p className="flex items-center gap-2 text-gray-700 justify-center">
                                    <IoCallOutline className="text-gray-600" />
                                    <span>{broker.TELEPHONE_1}</span>
                                </p>
                                
                            </div>
                        </div>
                    )
                ))
            ) : (
                <p>No brokers available</p>
            )}
        </div>
    );
};

export default BrokerDetails;
