import React, { useEffect } from "react";
import Header from "../../components/header";
import img1 from "../../assets/images/03-Mont-Blanc-scaled.webp"
import img3 from "../../assets/images/The-Walkway-Mont-Blanc-Quebec-Canada.webp"
import img4 from "../../assets/images/mont-blac.webp";
import img5 from "../../assets/images/mont-blac-2.webp"
import img6 from "../../assets/images/mont-blac-3.webp"
import img7 from '../../assets/images/MONT-BLANC.webp'
import { PiCurrencyCircleDollarThin } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { PiPersonSimpleWalkLight } from "react-icons/pi";
import { useTranslation } from "react-i18next";

const MontBlac = () => {

    const {t} = useTranslation();
    useEffect(()=>{
        document.title = t('title.blac')
    },[t])

    const { i18n } = useTranslation();
    const language = i18n.language


    const replaceString = (str: string) => {
        if (language === "fr") {
            return str.replace(/41\.2/g, "42")
                  .replace(/\$90567/g, "74 600 $")
                  .replace(/7,019/g, "10 992")
                  .replace(/41,2/g, "42")
                  .replace(/90 567 \$/g, "74 600 $")
                  .replace(/7 019/g, "10 992")
                  .replace(/High/g, "Low")
                  .replace(/\$71,600/g, "72 600 $")
                  .replace(/Haut/g, "Bas")
                  .replace(/Aspen/g, "Mont-Blanc");
        } else {
            return str.replace(/41\.2/g, "42")
                  .replace(/\$90567/g, "$74,600")
                  .replace(/7,019/g, "10,992")
                  .replace(/41,2/g, "42")
                  .replace(/90 567 \$/g, "$74,600")
                  .replace(/7 019/g, "10,992")
                  .replace(/High/g, "Low")
                  .replace(/\$90,567/g, "$72,600")
                  .replace(/Haut/g, "Bas")
                  .replace(/Aspen/g, "Mont-Blanc");
        }
      }


    return (
        <><Header />
        <div className="px-5 md:px-20 md:pt-36 pt-24">
            {/* Heading */}
            <div className="font-light text-start">
                <h1 className="text-2xl text-yellow-500 mt-3">{t("communities.mont-blanc.explore")}</h1>
                <h1 className=" text-4xl md:text-6xl ">{t("communities.mont-blanc.header")}</h1>
                <p className="text-gray-600 mt-2">{t("communities.mont-blanc.explore1")}</p>
            </div>

            {/* content-1 */}
            <div className="p-2 flex flex-col lg:flex-row gap-2 mt-5">
    <div className='w-full lg:w-1/2 lg:p-5 lg:h-[400px]'>
        <img src={img1} alt='mont-blac' className='rounded-lg h-full w-full object-cover'></img>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.mont-blanc.geography.00")}</h1>
        <p className='text-gray-500'>{t("communities.mont-blanc.geography.01")} <span className="font-semibold" > {t("communities.mont-blanc.geography.011")}</span>{t("communities.mont-blanc.geography.012")}</p>
        <p className='text-gray-500'>{t("communities.mont-blanc.geography.02")} <span className="font-semibold">{t("communities.mont-blanc.geography.021")}</span>{t("communities.mont-blanc.geography.022")} </p>
    </div>
</div>

{/* content-2 */}
<div className="p-2 flex flex-col lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.mont-blanc.outdoor.00")}</h1>
        <p className='text-gray-500 mt-2'>
            {t("communities.mont-blanc.outdoor.01")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.labelle.outdoor.02")}</span>{t("communities.labelle.geography.03")} <span className="font-semibold"> {t("communities.mont-blanc.outdoor.021")}</span>{t("communities.mont-blanc.outdoor.02")} <span className="font-semibold">{t("communities.mont-blanc.outdoor.020")}</span> {t("communities.mont-blanc.outdoor.022")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.labelle.outdoor.04")}</span>{t("communities.mont-blanc.outdoor.03")}
        </p>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:h-[400px]'>
        <img src={img3} alt='mont-blac' className='rounded-lg h-full w-full object-cover'></img>
    </div>
</div>

{/* content-3 */}
<div className="p-2 flex flex-col-reverse lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className='w-full lg:w-1/2 lg:p-5 lg:h-[400px]'>
        <img src={img4} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover'></img>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.mont-blanc.real-estate.00")}</h1>
        <p className='text-gray-500'>{t("communities.mont-blanc.real-estate.01")}</p>
        <p className='text-gray-500'><span className="font-semibold">{t("communities.mont-blanc.real-estate.03")}</span>{t("communities.mont-blanc.real-estate.02")}</p>
    </div>
</div>

{/* content-4 */}
<div className="p-2 flex flex-col lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2'>{t("communities.mont-blanc.community.00")}</h1>
        <p className='text-gray-500 mt-2'>
            {t("communities.mont-blanc.community.01")}
        </p>
        <p className='text-gray-500 mt-2'>
            {t("communities.labelle.geography.03")} <span className="font-semibold"> {t("communities.mont-blanc.community.03")}</span>{t("communities.mont-blanc.community.02")}
        </p>
    </div>
    <div className='w-full lg:w-1/2  lg:p-5 lg:h-[400px] text-start'>
        <img src={img5} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover'></img>
    </div>
</div>

{/* content-5 */}
<div className="p-2 flex flex-col-reverse lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className="w-full lg:w-1/2 lg:p-5 lg:h-[400px]">
        <img src={img6} alt="mont-tremblant-natinal park" className="rounded-lg h-full w-full object-cover" />
    </div>
    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4">
        <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.mont-blanc.attractions.00")}</h1>
        <p className="text-gray-500 mt-2">
            <span className="font-semibold">{t("communities.mont-blanc.attractions.001")}</span>{t("communities.mont-blanc.attractions.01")}
        </p>
        <p className="text-gray-500 mt-2">{t("communities.mont-blanc.attractions.02")}</p>
    </div>
</div>


            {/* contenet - 6  */}
            <div className="p-2 flex flex-col lg:flex-row gap-2 lg:mt-5 items-stretch">

    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col gap-4">
        <h1 className="text-yellow-500 text-2xl font-light mt-2">{t("communities.mont-blanc.population.00")}</h1>
        <p className="text-gray-500 mt-2">{t("communities.mont-blanc.population.01")}</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pt-7 pb-5 w-full">
            {/* First Icon Card */}
            <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-3xl bg-gray-400 flex items-center justify-center">
                    <CiLocationOn color="white" size={24} />
                </div>
                <div>
                    <p className="text-sm text-gray-700">3,499</p>
                    <p className="text-sm text-gray-400">{t("communities.tremblant.population.04")}</p>
                </div>
            </div>

            {/* Second Icon Card */}
            <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                    <IoPersonOutline size={24} color="white" />
                </div>
                <div>
                    <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.05"))}</p>
                    <p className="text-sm text-gray-400">{t("communities.tremblant.population.06")}</p>
                </div>
            </div>

            {/* Third Icon Card */}
            <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                    <PiPersonSimpleWalkLight size={24} color="white" />
                </div>
                <div>
                    <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.07"))}</p>
                    <p className="text-sm text-gray-400">{t("communities.tremblant.population.08")}</p>
                </div>
            </div>

            {/* Fourth Icon Card */}
            <div className="flex items-center gap-2">
                <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                    <PiCurrencyCircleDollarThin size={30} color="white" />
                </div>
                <div>
                    <p className="text-sm text-gray-700">{replaceString("$71,600")}</p>
                    <p className="text-sm text-gray-400">{t("communities.tremblant.population.09")}</p>
                </div>
            </div>
        </div>

        <p className="text-gray-500 mt-2">
            {t("communities.mont-blanc.population.02")}
        </p>

    </div>

    <div className="w-full lg:w-1/2 lg:p-5 lg:h-[450px]">
        <img src={img7} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
    </div>

</div>

<div className="p-2 flex flex-col lg:flex-row gap-2 items-stretch">

<div className='w-full lg:w-1/2 text-start lg:ps-5 lg:py-5 flex flex-col'>
    <p className='text-xl font-semibold mt-2 pb-2 text-gray-800'>{t("communities.lac.school")}</p>
    <p className="text-gray-500 ms-2">-Pavillon La Ribambelle </p>
    <p className="text-gray-500 ms-2">-Pavillon Fleur-Soleil</p>
    <p className="text-gray-500 ms-2">-Pavillon Trois-Saisons</p>
    <p className="text-gray-500 ms-2">-Pavillon Tournesol</p>
    <p className="text-gray-500 ms-2">-École l’Odyssée</p>
</div>

<div className='w-full lg:w-1/2 lg:ps-5 lg:py-5 text-start'>
    <p className='text-xl font-semibold pb-2 mt-2 text-gray-800'>{t("communities.sainte-agathe.school-sec")}</p>
    <p className="text-gray-500 ms-2">-Polyvalente Curé-Mercure</p>
    <p className="text-gray-500 pb-10 ms-2">-CEGEP</p>
</div>

</div>

            <div className='lg:ps-7 ps-2 lg:mt-2 pb-7 text-start text-gray-400'>
                <h1 className='text-lg font-semibold text-gray-800'>{t("communities.English-Schools")}</h1>
                <p className='text-gray-500 mt-2'>{t("communities.mont-blanc.school-eng.00")}</p>

                <p className='text-gray-500 mt-2'>{t("communities.mont-blanc.school-eng.01")}</p>
                <p className='text-gray-500 mt-2'>{t("communities.mont-blanc.school-eng.02")}</p>
            </div>

        </div>
        </>
    )
}

export default MontBlac