import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useInView } from 'framer-motion';
import { useRef  } from 'react';


interface Feature {
  number: string;
  title: string;
  description: string;
}

interface FeatureCardProps {
  feature: Feature;
  delay: number;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ feature, delay }) => {
  const ref = useRef(null);

  const  inView:any = useInView(ref,{ once: true, });

  return (
    <motion.div
      ref={ref}
      className="flex flex-col items-start text-start gap-3"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, delay }}
    >
      <div className="flex items-center gap-5">
        <div className="text-4xl font-bold text-gray-100 mb-2">{feature.number}</div>
        <div className="h-0.5 w-10 bg-gray-700 mb-2"></div>
      </div>
      <h3 className="text-lg mb-1 text-[#C4AB6E]">{feature.title}</h3>
      <p className="text-gray-100">{feature.description}</p>
    </motion.div>
  );
};

const FeatureSection: React.FC = () => {
  const { t } = useTranslation();

  const features: Feature[] = [
    {
      number: t('feature_Section.1.number'),
      title: t('feature_Section.1.title'),
      description: t('feature_Section.1.description'),
    },
    {
      number: t('feature_Section.2.number'),
      title: t('feature_Section.2.title'),
      description: t('feature_Section.2.description'),
    },
    {
      number: t('feature_Section.3.number'),
      title: t('feature_Section.3.title'),
      description: t('feature_Section.3.description'),
    },
    {
      number: t('feature_Section.4.number'),
      title: t('feature_Section.4.title'),
      description: t('feature_Section.4.description'),
    },
  ];

  return (
    <div className="bg-pantone288-500 text-gray-300 py-7 px-4">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {features.map((feature, index) => (
          <FeatureCard key={index} feature={feature} delay={index * 0.2} />
        ))}
      </div>
    </div>
  );
};

export default FeatureSection;
