import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';

import Slider from "react-slick"
import TestmonialPage from './testmonialPage';

interface Testimonial {
  text: string;
  author: string;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
}

const TestimonialCard: React.FC<Testimonial> = ({ text, author }) =>

  
  (
  <div className="bg-pantone288-500 p-6 rounded-lg shadow-lg text-gray-300 w-full   h-full">
    <p className="text-yellow-500 font-semibold text-start">{author}</p>
    <p className="text-sm lg:text-lg leading-relaxed mb-4 text-white text-start mt-3">{text}</p>
  </div>
);

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {

  const [showFeed, setShowFeed] = useState(false)
  const { t } = useTranslation();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows : false,
};

const navigate = useNavigate();

  return (
    <section className="bg-pantone288-700 py-16 px-4 relative overflow-x-hidden">

{showFeed && (
        <div className="absolute z-[500]  h-full  flex items-center justify-center">
          <TestmonialPage onClose={() => setShowFeed(false)} />
        </div>
      )}
      
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-6 text-left md:text-left">
          <h2 className="text-yellow-500 uppercase text-lg tracking-wide">{t('testimonials.testimonals')}</h2>
          <h3 className="text-white text-5xl font-light">{t('testimonials.clients')}</h3>
          <div className="h-0.5 w-60 bg-gray-700 mb-2"></div>
          <button className="bg-pantone288-500 mt-5 text-yellow-500 hover:ring-yellow-500 py-2 px-8 rounded font-semibold hover:bg-pantone288-700 transition" onClick={()=>{setShowFeed(true)}}>
            {t('testimonials.button')}
          </button>
        </div>

        {/* Right Column: Testimonial Cards */}
        <div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} text={testimonial.text} author={testimonial.author} />
          ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
