import React from 'react'
import Header from '../../components/header'
import { useTranslation } from 'react-i18next'
import img1 from '../../assets/images/blog/Village Pietonnier 1.webp'
import img2 from '../../assets/images/blog/Village pietonnier 2.webp'
import img3 from '../../assets/images/blog/village pietonnier 3.webp'



export default function VillagePietonnier() {
    const { t } = useTranslation()

  return (
    <div>
        <Header></Header>
        <section className='lg:px-[300px] px-5 py-10 md:pt-36 pt-24'>
            <div className='flex flex-col justify-center items-center' >
                <div className='text-pantone288-600 bg-yellow-500 rounded-lg px-4 py-1 font-semibold' >{t('popular')}</div>
                <h1 className='md:text-5xl text-3xl font-[400] mt-5  mb-5 text-yellow-500' >{t("blog.blog1.00")}</h1>
                <p className='text-start mt-5' >{t("blog.blog1.001")}</p>
                <img className='w-full lg:h-[500px] my-5 rounded-lg' src={img1} alt="" />
                <p className='text-start' >{t("blog.blog1.01")}</p>
                <img className='w-full lg:h-[500px] my-5 rounded-lg' src={img2} alt="" />
                <p className='text-start' >{t("blog.blog1.02")}</p>
                {/* <img className='w-full lg:h-[500px] my-10 rounded-lg' src={img3} alt="" /> */}
            </div>


            

        </section>
    </div>
  )
}
