// components/BrokerCard.tsx
import React from 'react';
import { Broker } from './broker';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { CiSearch,CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';



const BrokerCard: React.FC<any> = ({ broker }) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  
  const language = i18n.language
  // console.log(broker);
  const nom = broker.NOM.toLowerCase();
  const prenom = broker.PRENOM.toLowerCase();
  

  const handleViewDetails = () => {
     // Navigate to the broker details page
  };

  return (
    <motion.div
    className="w-64 bg-white shadow-lg rounded-lg overflow-hidden"
    whileHover={{ scale: 1.05 }}
  >
    {/* Image Section */}
    <div className="w-full h-60 flex justify-center items-center bg-white">
      <img
        src={broker.PHOTO_URL}
        alt={broker.NOM_SOCIETE}
        className="object-contain w-full h-full"
      />
    </div>

    {/* Broker Info */}
    <div className="p-4 text-center">
      <h3 className="text-xl font-semibold text-gray-900 ">
        {`${broker.PRENOM} ${broker.NOM}`}
      </h3>
      <p className="text-sm text-gray-500">
        {broker.TITRE_PROFESSIONNEL ? broker.TITRE_PROFESSIONNEL : 'Real Estate Agent'}
      </p>
    </div>
    

    {/* Profile Link */}
    <div className="text-center pb-4">
      <button
        onClick={() => navigate(`/${language}/agent/${prenom}-${nom}`,{state:
          {broker:broker}
        })}
        className="bg-pantone288-500 text-yellow-500 px-4 py-2 font-semibold hover:bg-yellow-500 hover:text-white  rounded text-xs"

      >
       {t('broker.view')}
      </button>
    </div>
  </motion.div>
  );
};

export default BrokerCard;
