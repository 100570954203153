import React from 'react'
import Header from '../../components/header'
import { useTranslation } from 'react-i18next'
import img1 from '../../assets/images/blog/Domaine de la Foret 1.webp'
import img2 from '../../assets/images/blog/Domaine de la Montagne 2.webp'
import img3 from '../../assets/images/blog/Domaine de la Montagne 3.webp'
export default function Domaine() {
        const { t } = useTranslation()
    
  return (
    <div>
        <Header></Header>
        <section className='lg:px-[300px] px-5 py-10 md:pt-36 pt-24'>
        <div className='flex flex-col justify-center items-center' >
                <div className='text-pantone288-600 bg-yellow-500 rounded-lg px-4 py-1 font-semibold' >{t('popular')}</div>
                <h1 className='md:text-5xl text-3xl font-[400] my-5 mb-5 text-yellow-500' >{t("blog.blog2.00")}</h1>
                <p className='text-start' >{t("blog.blog2.01")}</p>
                <img className='w-full lg:h-[500px] mt-5 mb-5 rounded-lg' src={img1} alt="" />
                <p className='text-start' >{t("blog.blog2.02")}</p>
                <img className='w-full lg:h-[500px] my-5 rounded-lg' src={img2} alt="" />
                <p className='text-start' >{t("blog.blog2.03")}</p>
                <p className='text-start' >{t("blog.blog2.04")}</p>
                {/* <img className='w-full lg:h-[500px] my-10 rounded-lg' src={img3} alt="" /> */}
            </div>


            
        
        
        </section>



    </div>
  )
}
