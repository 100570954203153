import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { getSingleProperty } from "../services/listingService";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { BiArea } from "react-icons/bi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { IoHammerOutline } from "react-icons/io5";
import PhotoModal from "../dialog-components/photosDialog";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import BrokerDetails from "./brokerCard";
import Header from "../components/header";
import { GoDeviceCameraVideo } from "react-icons/go";
import SinglePropertiesMap from "../components/singlePropertiesMap";
import { brokerEngData } from './brokerEngData';
import { brokersFrData } from './brokerFrData';
import { TranslateText } from "../services/googleTranslator";
import { Helmet } from "react-helmet-async";
import BrokerForm from "../components/brokerContactForm";
import NoDataFound from "../components/error";



const IndividualProperty: React.FC = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("mls");

    const [propertyDetails, setPropertyDetails] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [indexValue, setIndexValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const currentLanguage = i18n.language;
    const [isExpanded, setIsExpanded] = useState(false);
    const [coordinates, setCoordinates] = useState({
        lat: "",
        lng: ""
    });
    const [errors, setErrors] = useState(false)
    const { t } = useTranslation();
    const frenchCorrections: { [key: string]: string } = {
        "interieur": "intérieur",
        "design": "design",
        "qualite": "qualité",
        "materiaux": "matériaux",
        "ceramique": "céramique",
        "foyer": "foyer",
        "propriete": "propriété",
        "piece": "pièce",
        "lumiere": "lumière",
        "stationnement": "stationnement",
        "ascenseurs": "ascenseurs",
        "frais de condos est": "les frais de condos sont",
        // Fix double spaces
        "\\s{2,}": " ",
        "(\\.|\\?|\\!)(\\w)": "$1 $2",
        "(\\d)(°)": "$1°", // Ensure space before "°"
    };

    useEffect(() => {
        getProperty();
    }, [id]);

    useEffect(() => {
        document.title = t('title.properties')
    }, [t])

    const getProperty = async () => {
        try {
            setLoading(true);
            const response = await getSingleProperty(id);
            setPropertyDetails(response.data);
            console.log(JSON.stringify(response.data, null, 2));



            const coordinates = {
                lat: response.data.LATITUDE,
                lng: response.data.LONGITUDE,
            };
            setCoordinates(coordinates);
        } catch (err) {
            console.log(err);
            setErrors(true)
            
        } finally {
            setLoading(false);
        }
    };
    console.log(errors);

    const formatPrice = (price: any) => {
        const currency = currentLanguage === 'fr' ? 'CAD' : 'USD';
        return new Intl.NumberFormat(currentLanguage === 'fr' ? 'fr-CA' : 'en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
        }).format(price);
    };
    const handleShowAllPhotos = (index: any) => {
        setIndexValue(index);
        setIsModalOpen(true);

    };
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const formatDescription = (exclusive: string, inclusive: string) => {

        const value = exclusive + ' ' + inclusive;
        const val = value
            .replace(/<br\s*\/?>/g, '')
            .replace(/‡/g, "à")
            // .replace(/\*\*/g, '')
            .replace(/È/g, 'é')
            .replace(/Ë/g, 'ë')
            .replace(/¿/g, 'À')
            // .replace(/!/g, "")
            .replace(/Î/g, "î")
            .replace(/Í/g, "ê")
            .replace(/Ó/g, "ô")         //
            .replace(/Œ/g, "œ")         // Replace 'Œ' with 'œ'
            .replace(/‰/g, "é")         // Replace '‰' with 'é'
            .replace(/‚/g, "à")         // Replace '‚' with 'à'
            .replace(/\\s{2,}/g, ' ')   // Replace multiple spaces with a single space
            .replace(/(\.|,)(\S)/g, "$1 $2")
            .replace(/;/g, "")
            .replace(/\.\./g, '')
            .replace(/\u2026/g, 'É')
            .replace(/∞/g, "")
            .replace(/Ù/g, "ô")
            .replace(/è/g, 'é')
            .replace(/ë/g, 'è')
            .replace(/˚/g, "û")
            .replace(/˘/g, "ù")



            // Replace 'Œ' with 'œ' 



            .trim();                    // Trim any leading or trailing whitespace

        return correctFrenchText(val);
    }
    const formatDescription2 = (exclusive: string) => {

        const value = exclusive;
        const val = value
            .replace(/<br\s*\/?>/g, '')
            .replace(/‡/g, "à")
            // .replace(/\*\*/g, '')
            .replace(/È/g, 'é')
            .replace(/Ë/g, 'ë')
            .replace(/¿/g, 'À')
            // .replace(/!/g, "")
            .replace(/Î/g, "î")
            .replace(/Í/g, "ê")
            .replace(/Ó/g, "ô")         //
            .replace(/Œ/g, "œ")         // Replace 'Œ' with 'œ'
            .replace(/‰/g, "é")         // Replace '‰' with 'é'
            .replace(/‚/g, "à")         // Replace '‚' with 'à'
            .replace(/\\s{2,}/g, ' ')   // Replace multiple spaces with a single space
            .replace(/(\.|,)(\S)/g, "$1 $2")
            .replace(/;/g, "")
            .replace(/\.\./g, '')
            .replace(/\u2026/g, 'É')
            .replace(/∞/g, "")
            .replace(/Ù/g, "ô")
            .replace(/è/g, 'é')
            .replace(/ë/g, 'è')
            .replace(/˚/g, "û")
            .replace(/˘/g, "ù")




            // Replace 'Œ' with 'œ' 



            .trim();                    // Trim any leading or trailing whitespace

        return correctFrenchText(val);
    }

    const formatString = (inputString: string) => {
        // Remove unwanted characters and extra spaces
        let formattedString = inputString
            .replace(/‡/g, 'à')  // Remove '‡' characters
            .replace(/"/g, '')  // Remove double quotes
            .replace(/(\d{8})/g, 'ID: $1')
            .replace(/`|\$|`/g, '')  // Remove backticks and dollar signs
            // Remove any 8-digit number (or numbers with a specific pattern like '9844128')
            .replace(/\d{7,}/g, '')  // Remove any 7 or more digits
            // Remove any 8-digit number (or numbers with a specific pattern like '9844128')
            .replace(/\d{7,}/g, '')  // Remove any 7 or more digits
            .replace(/[`$]/g, '')  // Remove backticks and dollar signs
            .replace(/\s+/g, ' ')
            .replace(/[`$]+/g, '')
            .replace(/È/g, 'é')
            .replace(/è/g, 'é')
            .replace(/Ë/g, 'ë')
            .replace(/¿/g, 'À')
            // .replace(/!/g, "")
            .replace(/Î/g, "î")
            .replace(/Í/g, "ê")
            .replace(/\*\*/g, '')
            .replace(/Ó/g, "ô")         //
            .replace(/Œ/g, "œ")         // Replace 'Œ' with 'œ' ∞
            .replace(/‰/g, "é")
            .replace(/\.\./g, '')
            .replace(/∞/g, "")         // Replace 'Œ' with 'œ' 
            .replace(/\u2026/g, 'É')
            .replace(/\I\D\:/g, "")
            .replace(/Ù/g, "ô")
            // Replace 'Œ' with 'œ' ù Ù
            .replace(/ë/g, 'è')
            .replace(/˚/g, "û")
            .replace(/˘/g, "ù")


            .trim();



        return formattedString;
    };

    console.log("Brokers: ", propertyDetails.brokers);



    const correctFrenchText = (text: string): string => {
        let correctedText = text;

        for (const [incorrect, correct] of Object.entries(frenchCorrections)) {
            const regex = new RegExp(incorrect, "gi");
            correctedText = correctedText.replace(regex, correct);
        }

        return correctedText.trim();
    };





    const fianceDataCheckA = ((propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 ||
        propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0) &&
        propertyDetails.taxDetails.some((tax: any) => tax.MONTANT_DEPENSE > 0 && ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE)))

    const fianceDataCheckB = (propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 ||
        propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0) &&
        (propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FCOP" && expense.MONTANT_DEPENSE > 0) ||
            propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FRAISCOMM" && expense.MONTANT_DEPENSE > 0) ||
            propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "ENER" && expense.MONTANT_DEPENSE > 0))


    const fianceDataCheckC = (propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FCOP" && expense.MONTANT_DEPENSE > 0) ||
        propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FRAISCOMM" && expense.MONTANT_DEPENSE > 0) ||
        propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "ENER" && expense.MONTANT_DEPENSE > 0)) && propertyDetails.taxDetails.some(
            (tax: any) => tax.MONTANT_DEPENSE > 0 && ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE)
        )

    const financeResD = (fianceDataCheckA && fianceDataCheckB) || fianceDataCheckC;
    const financeResE = fianceDataCheckA || (fianceDataCheckB && fianceDataCheckC);

    const responsiveF = financeResD || financeResE;



    const getBrokers = (code: any[]) => {
        const data = code.filter((id: any) => {
            return id.CODE
        })

        // console.log(data);


    }
    const availableDataBoxes = [
        (propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 || propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0),
        propertyDetails.taxDetails?.some(
            (tax: any) => tax.MONTANT_DEPENSE > 0 && ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE)
        ),
        propertyDetails.taxDetails?.some(
            (expense: any) =>
                ['FCOP', 'FRAISCOMM', 'ENER', 'GAZO', 'TAXEAU'].includes(expense.TDEP_CODE) && expense.MONTANT_DEPENSE > 0
        ),
    ].filter(Boolean).length;


    //translation using google api service
    const [translatedDescription, setTranslatedDescription] = useState('');
    const [translationCache, setTranslationCache] = useState(new Map());

    useEffect(() => {
        const translateDescription = async () => {
            if (currentLanguage === 'fr' && propertyDetails.ADDENDA_COMPLET_F) {
                const originalText = formatDescription(propertyDetails.ADDENDA_COMPLET_F, propertyDetails.INCLUS_FRANCAIS);

                // Check if translation exists in cache
                if (translationCache.has(originalText)) {
                    setTranslatedDescription(translationCache.get(originalText));
                } else {
                    try {
                        const translation = await TranslateText(originalText, 'fr-CA');
                        setTranslatedDescription(translation);

                        // Cache the translation
                        setTranslationCache((prevCache) => new Map(prevCache).set(originalText, translation));
                    } catch (error) {
                        console.error('Error translating description:', error);
                    }
                }
            }
        };

        translateDescription();
    }, [currentLanguage, propertyDetails.ADDENDA_COMPLET_F, translationCache]);








    const filterBrokerDetails = (brokerDetails: any[]) => {
        const translationData = currentLanguage === 'fr' ? brokersFrData : brokerEngData;
        // console.log(brokerDetails);

        return translationData.filter((broker) => {
            if (broker) {
                // console.log(broker.CODE);
                return brokerDetails.find((element) => element ? element.CODE === broker.CODE : false);
            }
        });
    };

    const updateHeading = (text: string) => {
        const format = text.replace(/(?<!^)(È)/g, 'é')
            .replace(/‡/g, "à")

            .replace(/È/g, 'é')
            .replace(/è/g, 'é')
            .replace(/Ë/g, 'ë')
            .replace(/¿/g, 'À')
            // .replace(/!/g, "")
            .replace(/Î/g, "î")
            .replace(/Í/g, "ê")
            .replace(/\*\*/g, '')
            .replace(/Ó/g, "ô")         //
            .replace(/Œ/g, "œ")         // Replace 'Œ' with 'œ' ∞
            .replace(/‰/g, "é")
            .replace(/\.\./g, '')
            .replace(/∞/g, "")
            .replace(/\u2026/g, 'É')
            .replace(/\I\D\:/g, "")
            .replace(/Ù/g, "ô")
            // Replace 'Œ' with 'œ' ù Ù
            .replace(/ë/g, 'è')
            .replace(/˚/g, "û")
            .replace(/˘/g, "ù")
        return format;
    }

    const propertyName = `${propertyDetails.APPARTEMENT ? `${propertyDetails.APPARTEMENT} - ` : ''}${propertyDetails.NOM_RUE_COMPLET
            ? `${propertyDetails.NO_CIVIQUE_DEBUT} ${updateHeading(propertyDetails.NOM_RUE_COMPLET)}, ${propertyDetails.municipality ? propertyDetails.municipality : ''
            }, ${propertyDetails.CODE_POSTAL}, ${propertyDetails.NO_INSCRIPTION}`
            : ''
        }`;





    return (
        <>
            <Header />
            <div className="md:pt-28 pt-24">
                    
                {loading ? (
                    Array(5)
                        .fill(null)
                        .map((_, index) => (
                            <div
                                key={index}
                                className={`${index === 0 ? 'col-span-2 row-span-2' : 'col-span-1'} 
                                bg-gray-300 animate-pulse rounded-lg h-48 w-full`}
                            ></div>
                        ))
                ) : (
                    propertyDetails && !errors ? (
                        <>
                            <Helmet>
                                <title>Mont-Tremblant Real Estate</title>
                                <meta property="og:title" content="Mont-Tremblant" />
                                <meta property="og:description" content="description" />
                                {propertyDetails?.photos?.[0] && (
                                    <>
                                        <meta property="og:image" content={propertyDetails.photos[0]} />
                                        <meta name="twitter:image" content={propertyDetails.photos[0]} />
                                    </>
                                )}
                                <meta property="og:url" content={`http://mt.vto.homes/property/${id}`} />
                                <meta property="og:type" content="website" />
                                <meta name="twitter:card" content="summary_large_image" />
                                <meta name="twitter:title" content="Mont-Tremblant" />
                                {propertyDetails?.description?.[0]?.TEXTE && (
                                    <meta name="twitter:description" content={propertyDetails.description[0].TEXTE} />
                                )}
                            </Helmet>

                            <div className="px-3 lg:px-14">
                                <div className="flex gap-3 justify-end p-5">

                                    <div>
                                        {(propertyDetails.URL_VISITE_VIRTUELLE_FRANCAIS || propertyDetails.URL_VISITE_VIRTUELLE_ANGLAIS) && (
                                            <div >
                                                <a href={propertyDetails.URL_VISITE_VIRTUELLE_FRANCAIS || propertyDetails.URL_VISITE_VIRTUELLE_ANGLAIS}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="flex items-center gap-3">
                                                    <div className="p-2 border rounded-full border-gray-300">
                                                        <GoDeviceCameraVideo />
                                                    </div>
                                                    <span>{t('individualListing.Virtual Tour')}</span>
                                                </a>
                                            </div>
                                        )}
                                    </div>


                                </div>
                                <div className="grid lg:grid-cols-4 gap-4">
                                    {propertyDetails.photos && propertyDetails.photos.slice(0, 5).map((image: any, index: number) => (
                                        <div
                                            key={index}
                                            className={`${index === 0 ? 'col-span-2 row-span-2 h-[18rem] lg:h-[25rem]' : 'col-span-1 h-32 lg:h-48'} 
                                     relative overflow-hidden rounded-lg`}
                                            onClick={() => handleShowAllPhotos(index)}
                                        >
                                            <img
                                                src={image}
                                                alt={`image-${index}`}
                                                className="object-cover w-full h-full hover:cursor-pointer "
                                            />
                                            {index === 4 && (
                                                <div className="absolute inset-0 flex items-end justify-end p-5">
                                                    <button
                                                        onClick={() => handleShowAllPhotos(0)}
                                                        className="bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg px-4 py-2"
                                                    >
                                                        {t('individualListing.Show All Photos')}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <PhotoModal isOpen={isModalOpen} onClose={closeModal} value={propertyDetails.photos} index={indexValue} />

                                <div className="flex flex-col lg:flex-row mt-10  font-roboto gap-10">
                                    <div className="w-full lg:w-[70%]">
                                        <div className="flex flex-col w-full">
                                            <div className="flex flex-col lg:flex-row lg:justify-between">
                                                <h1 className="text-start text-2xl flex font-semibold order-1 lg:order-2 items-center gap-2">
                                                    {propertyDetails.CODE_STATUT === 'VE' ? (
                                                        <span className=" text-yellow-500" > {t('footerData.sold')}</span>
                                                    ) : (
                                                        <>
                                                            {propertyDetails.PRIX_DEMANDE
                                                                ? formatPrice(propertyDetails.PRIX_DEMANDE)
                                                                : formatPrice(propertyDetails.PRIX_LOCATION_DEMANDE)}
                                                            {propertyDetails.PRIX_DEMANDE_TAXE_INCL && (
                                                                <p className="text-sm text-gray-700"> + Taxes </p>
                                                            )}
                                                        </>
                                                    )}
                                                </h1>

                                                <div className="flex mt-2 lg:mt-0 gap-1 lg:gap-3 items-start text-2xl order-2 lg:order-1">
                                                    <span><HiOutlineLocationMarker /></span>
                                                    <h1 className="font-bold text-start text-xl lg:text-2xl">
                                                        {propertyDetails.APPARTEMENT ? `${propertyDetails.APPARTEMENT} - ` : ''}
                                                        {propertyDetails.NOM_RUE_COMPLET
                                                            ? `${propertyDetails.NO_CIVIQUE_DEBUT} ${updateHeading(propertyDetails.NOM_RUE_COMPLET)}, ${propertyDetails.municipality ? propertyDetails.municipality : ''}, ${propertyDetails.CODE_POSTAL}`
                                                            : ''}
                                                    </h1>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="w-full grid-cols-3 grid gap-3 mt-8">
                                            {(propertyDetails.property_french || propertyDetails.property_english) &&
                                                <div className="flex lg:flex-row flex-col gap-1 p-1 lg:gap-3 lg:p-3 bg-gray-100 rounded-lg items-center">
                                                    <span><IoHomeOutline size={22} /> </span>
                                                    <p>{currentLanguage === 'fr' ? propertyDetails.property_french : propertyDetails.property_english}</p>
                                                </div>
                                            }


                                            {propertyDetails.ANNEE_CONTRUCTION &&
                                                <div className="flex gap-1 p-1 lg:flex-row flex-col lg:gap-3 lg:p-3 bg-gray-100 rounded-lg items-center">
                                                    <span><IoHammerOutline size={22} /></span>
                                                    <p>{t('individualListing.Build Year')} {propertyDetails.ANNEE_CONTRUCTION ? propertyDetails.ANNEE_CONTRUCTION : '-'}</p>
                                                </div>
                                            }

                                            {propertyDetails.NB_CHAMBRES &&
                                                <div className="flex gap-1 p-1 lg:flex-row flex-col lg:gap-3 lg:p-3 bg-gray-100 rounded-lg items-center">
                                                    <span><IoBedOutline size={22} /></span>
                                                    <p>
                                                        {propertyDetails.NB_CHAMBRES ? propertyDetails.NB_CHAMBRES : '0 -'} {propertyDetails.NB_CHAMBRES > 1 ? t('individualListing.Bedrooms') : t('individualListing.Bedroom')}
                                                    </p>

                                                </div>
                                            }
                                            {propertyDetails.NB_SALLES_BAINS &&
                                                <div className="flex gap-1 p-1 lg:flex-row flex-col lg:gap-3 lg:p-3 bg-gray-100 rounded-lg items-center">
                                                    <span><LiaBathSolid size={22} /></span>
                                                    <p>{propertyDetails.NB_SALLES_BAINS ? propertyDetails.NB_SALLES_BAINS : '0 -'} {propertyDetails.NB_SALLES_BAINS > 1 ? t('individualListing.Bathrooms') : t('individualListing.Bathroom')}</p>
                                                </div>
                                            }
                                            {(propertyDetails.SUPERFICIE_HABITABLE || propertyDetails.FACADE_TERRAIN || propertyDetails.PROFONDEUR_TERRAIN || propertyDetails.SUPERFICIE_TERRAIN) ?
                                                <div className="flex gap-1 p-1 lg:flex-row flex-col lg:gap-3 lg:p-3 bg-gray-100 rounded-lg items-center">
                                                    <span><BiArea size={22} /></span>
                                                    {propertyDetails.property_english === 'Apartment' || propertyDetails.SUPERFICIE_HABITABLE ? (
                                                        propertyDetails.SUPERFICIE_HABITABLE ? (
                                                            <p>{`${propertyDetails.SUPERFICIE_HABITABLE} ${t('individualListing.Sqft')}`}</p>
                                                        ) : null
                                                    ) : (
                                                        propertyDetails.FACADE_TERRAIN || propertyDetails.PROFONDEUR_TERRAIN || propertyDetails.SUPERFICIE_TERRAIN ? (
                                                            <p>
                                                                {`${propertyDetails.FACADE_TERRAIN ? `${propertyDetails.FACADE_TERRAIN}m x ` : ''}${propertyDetails.PROFONDEUR_TERRAIN ? `${propertyDetails.PROFONDEUR_TERRAIN}m | ` : ''}${propertyDetails.SUPERFICIE_TERRAIN} m²`}
                                                            </p>
                                                        ) : null
                                                    )}
                                                </div>
                                                : null}


                                        </div>


                                        {(propertyDetails.description && propertyDetails.description.length > 0) &&
                                            <div className="mt-10 text-start">
                                                <h1 className="text-xl font-semibold">Description</h1>
                                                <div className="text-gray-500 mt-6 ">
                                                    {currentLanguage === 'fr' ?
                                                        propertyDetails.description
                                                            .filter((item: any) => item.CODE_LANGUE === 'F')
                                                            .map((item: any, id: any) => (
                                                                <span key={id} className="text-gray-500 text-base ">
                                                                    {formatDescription2(item.TEXTE)} {/* Call formatString directly */}
                                                                </span>
                                                            )) :
                                                        propertyDetails.description
                                                            .filter((item: any) => item.CODE_LANGUE === 'A')
                                                            .map((item: any, id: any) => (
                                                                <span key={id} className="text-gray-500 text-base leading-8">
                                                                    {formatDescription2(item.TEXTE)} {/* Call formatString directly */}
                                                                </span>
                                                            ))
                                                    }

                                                </div>


                                            </div>
                                        }
                                        {
                                            (propertyDetails.INCLUS_FRANCAIS && propertyDetails.INCLUS_ANGLAIS) &&
                                            <div className="mt-5 text-start">
                                                <h1 className="text-xl font-semibold">Inclusions</h1>

                                                <span className="text-gray-500 text-base">
                                                    {currentLanguage === 'fr' ? formatDescription2(propertyDetails.INCLUS_FRANCAIS) : formatDescription2(propertyDetails.INCLUS_ANGLAIS)}
                                                </span>


                                            </div>

                                        }
                                        {
                                            (propertyDetails.EXCLUS_FRANCAIS && propertyDetails.EXCLUS_ANGLAIS) &&
                                            <div className="mt-5 text-start">
                                                <h1 className="text-xl font-semibold">Exclusions</h1>

                                                <span className="text-gray-500 text-base leading-8">
                                                    {currentLanguage === 'fr' ? formatDescription2(propertyDetails.EXCLUS_FRANCAIS) : formatDescription2(propertyDetails.EXCLUS_ANGLAIS)}
                                                </span>


                                            </div>

                                        }






                                        {
                                            (propertyDetails.addenda && propertyDetails.addenda.length > 0) &&

                                            <div className="my-5 text-start">
                                                <h1 className="text-xl font-semibold">{t('individualListing.Addenda')}</h1>
                                                <div className="text-gray-500 mt-6 ">
                                                    {currentLanguage === 'fr' ?
                                                        propertyDetails.addenda
                                                            .filter((item: any) => item.CODE_LANGUE === 'F')
                                                            .map((item: any, id: any) => (
                                                                <span key={id} className="text-gray-500 text-base ">
                                                                    {formatString(item.TEXTE)} {/* Call formatString directly */}
                                                                </span>
                                                            )) :
                                                        propertyDetails.addenda
                                                            .filter((item: any) => item.CODE_LANGUE === 'A')
                                                            .map((item: any, id: any) => (
                                                                <span key={id} className="text-gray-500 text-base leading-8">
                                                                    {formatString(item.TEXTE)} {/* Call formatString directly */}
                                                                </span>
                                                            ))
                                                    }

                                                </div>
                                            </div>
                                        }



                                        <div className="mt-10 text-start">
                                            <h1 className="text-xl font-semibold">{t('individualListing.Listing Details')}</h1>
                                            <div className="w-full grid-cols-1 lg:grid-cols-2 grid mt-6 lg:gap-3 lg:gap-x-12 gap-1 gap-x-1">
                                                {/* Property Style */}
                                                {(propertyDetails.property_french || propertyDetails.property_english) &&
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Property-Style')}</p>
                                                        <p className="text-gray-500">
                                                            {currentLanguage === 'fr' ? propertyDetails.property_french : propertyDetails.property_english}
                                                        </p>
                                                    </div>
                                                }
                                                {/* Request Price */}
                                                {propertyDetails.PRIX_DEMANDE &&
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Request-Price')}</p>
                                                        <p className="text-gray-500">
                                                            {propertyDetails.PRIX_DEMANDE ? formatPrice(propertyDetails.PRIX_DEMANDE) : ''}
                                                        </p>
                                                    </div>
                                                }
                                                {/* Bedrooms */}
                                                {propertyDetails.NB_CHAMBRES &&
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Bedrooms')}</p>
                                                        <p className="text-gray-500">{propertyDetails.NB_CHAMBRES || '0'}</p>
                                                    </div>
                                                }
                                                {/* Parking */}
                                                {propertyDetails.NB_SALLES_BAINS &&
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Parking')}</p>
                                                        <p className="text-gray-500">{propertyDetails.NB_SALLES_BAINS || '-'}</p>
                                                    </div>
                                                }
                                                {/* Build Year */}
                                                {propertyDetails.ANNEE_CONTRUCTION &&
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Build-year')}</p>
                                                        <p className="text-gray-500">{propertyDetails.ANNEE_CONTRUCTION || '-'}</p>
                                                    </div>
                                                }
                                                {/* Property Type */}
                                                {propertyDetails.property_english === 'Apartment' ? (
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Sqft')}</p>
                                                        <p className="text-gray-500">
                                                            {propertyDetails.SUPERFICIE_HABITABLE || `-`} {t('individualListing.Sqft')}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                        <p className="font-medium">{t('individualListing.Lot-size')}</p>
                                                        <p className="text-gray-500">
                                                            {propertyDetails.property_english === 'Apartment' || propertyDetails.SUPERFICIE_HABITABLE ? (
                                                                propertyDetails.SUPERFICIE_HABITABLE ? (
                                                                    <p>{`${propertyDetails.SUPERFICIE_HABITABLE} ${t('individualListing.Sqft')}`}</p>
                                                                ) : null
                                                            ) : (
                                                                propertyDetails.FACADE_TERRAIN || propertyDetails.PROFONDEUR_TERRAIN || propertyDetails.SUPERFICIE_TERRAIN ? (
                                                                    <p>
                                                                        {`${propertyDetails.FACADE_TERRAIN ? `${propertyDetails.FACADE_TERRAIN}m x ` : ''}${propertyDetails.PROFONDEUR_TERRAIN ? `${propertyDetails.PROFONDEUR_TERRAIN}m | ` : ''}${propertyDetails.SUPERFICIE_TERRAIN} m²`}
                                                                    </p>
                                                                ) : null
                                                            )}
                                                        </p>
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                        {/* Interior Details */}
                                        {(propertyDetails.NB_CHAMBRES || propertyDetails.NB_SALLES_BAINS || propertyDetails.NB_PIECES || propertyDetails.SUPERFICIE_BATIMENT) &&
                                            <div className="mt-10 text-start">
                                                <h1 className="text-xl font-semibold">{t('individualListing.Interior Details')}</h1>
                                                <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                                                    {propertyDetails.NB_CHAMBRES &&
                                                        <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                            <p className="font-medium">{t('individualListing.Bedrooms')}</p>
                                                            <p className="text-gray-500 font-normal">{propertyDetails.NB_CHAMBRES || '0'}</p>
                                                        </div>
                                                    }
                                                    {propertyDetails.NB_SALLES_BAINS &&
                                                        <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                            <p className="font-medium">{t('individualListing.Bathrooms')}</p>
                                                            <p className="text-gray-500 font-normal">{propertyDetails.NB_SALLES_BAINS || '0'}</p>
                                                        </div>
                                                    }
                                                    {propertyDetails.NB_PIECES &&
                                                        <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                            <p className="font-medium">{t('individualListing.Rooms')}</p>
                                                            <p className="text-gray-500 font-normal">{propertyDetails.NB_PIECES || '0'}</p>
                                                        </div>
                                                    }
                                                    {propertyDetails.SUPERFICIE_BATIMENT &&
                                                        <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                                                            <p className="font-medium">{t('individualListing.Building Area')}</p>
                                                            <p className="text-gray-500 font-normal">
                                                                {propertyDetails.SUPERFICIE_BATIMENT ? `${propertyDetails.SUPERFICIE_BATIMENT} sqft` : '-'}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }


                                        {/* Financial data */}

                                        <div className="mt-10 text-start">
                                            <div className="">
                                                {
                                                    (propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 ||
                                                        propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0 || propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FCOP") || propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FRAISCOMM" && expense.MONTANT_DEPENSE > 0) ||
                                                        propertyDetails.taxDetails.some(
                                                            (tax: any) => tax.MONTANT_DEPENSE > 0 && ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE)
                                                        )) &&
                                                    <div className={!responsiveF ? "flex justify-between w-full flex-wrap lg:flex-nowrap  lg:w-[50%]" : "flex justify-between  w-full "}>
                                                        <h1 className="text-xl font-semibold">{t('financial.finance')}</h1>

                                                        {/* <div className="px-3 py-1 bg-blue-800 border border-blue-800 text-white hover:cursor-pointer text-sm rounded-full">Yearly</div> */}

                                                    </div>


                                                }
                                                <div
                                                    className={!responsiveF ? "flex items-start w-full flex-wrap lg:flex-nowrap gap-5 lg:w-[50%]" : "flex w-full items-start flex-wrap gap-5 lg:flex-nowrap"}>
                                                    {/* MUNICIPAL EVALUATION */}
                                                    {(
                                                        propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 ||
                                                        propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0
                                                    ) && (
                                                            <div className="flex flex-col justify-between p-3 pr-0 w-full ">
                                                                <p className="font-medium mb-5">{t('financial.muni')}</p>

                                                                {/* Lot Value */}
                                                                {propertyDetails.EVALUATION_MUNICIPALE_TERRAIN > 0 && (
                                                                    <div className="flex items-center justify-between p-3  border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0  ">
                                                                        <p className="font-medium ">{t('financial.lot')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {formatPrice(propertyDetails.EVALUATION_MUNICIPALE_TERRAIN)}
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {/* Building Value */}
                                                                {propertyDetails.EVALUATION_MUNICIPALE_BATIMENT > 0 && (
                                                                    <div className="flex items-center justify-between  p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.building')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {formatPrice(propertyDetails.EVALUATION_MUNICIPALE_BATIMENT)}
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {/* Total Value */}
                                                                <div className="flex items-center justify-between  p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0 bg-orange-200">
                                                                    <p className="font-medium">{t('financial.total')}</p>
                                                                    <p className="text-gray-500 font-normal">
                                                                        {formatPrice(
                                                                            (Number(propertyDetails.EVALUATION_MUNICIPALE_TERRAIN) || 0) +
                                                                            (Number(propertyDetails.EVALUATION_MUNICIPALE_BATIMENT) || 0)
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    {/* MUNICIPAL & SCHOOL TAXES */}
                                                    {propertyDetails.taxDetails &&
                                                        propertyDetails.taxDetails.some(
                                                            (tax: any) => tax.MONTANT_DEPENSE > 0 && ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE)
                                                        ) && (
                                                            <div className="flex flex-col justify-between p-3 pr-0  w-full">
                                                                <p className="font-medium mb-5">TAXES</p>

                                                                {/* Municipal Taxes */}
                                                                {propertyDetails.taxDetails
                                                                    .filter((tax: any) => tax.TDEP_CODE === 'TAXMUN' && tax.MONTANT_DEPENSE > 0)
                                                                    .map((tax: any, id: any) => (
                                                                        <div key={id} className="flex items-center justify-between  p-3 gap-5 border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                            <p className="font-medium">{t('financial.municipal')} ({tax.ANNEE})</p>
                                                                            <p className="text-gray-500 font-normal">{formatPrice(tax.MONTANT_DEPENSE)}</p>
                                                                        </div>
                                                                    ))}

                                                                {/* School Taxes */}
                                                                {propertyDetails.taxDetails
                                                                    .filter((tax: any) => tax.TDEP_CODE === 'TAXSCO' && tax.MONTANT_DEPENSE > 0)
                                                                    .map((tax: any, id: any) => (
                                                                        <div key={id} className="flex items-center justify-between  p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                            <p className="font-medium">{t('financial.school')} ({tax.ANNEE})</p>
                                                                            <p className="text-gray-500 font-normal">{formatPrice(tax.MONTANT_DEPENSE)}</p>
                                                                        </div>
                                                                    ))}

                                                                {/* Total Taxes */}
                                                                <div className="flex items-center justify-between p-3  border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0 bg-orange-200">
                                                                    <p className="font-medium">{t('financial.total')}</p>
                                                                    <p className="text-gray-500 font-normal">
                                                                        
                                                                        {formatPrice(propertyDetails.taxDetails
                                                                            .filter((tax: any) => ['TAXMUN', 'TAXSCO'].includes(tax.TDEP_CODE))
                                                                            .reduce((sum: number, tax: any) => sum + Number(tax.MONTANT_DEPENSE || 0), 0))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    {/* EXPENSES */}
                                                    {
                                                        (propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FCOP" && expense.MONTANT_DEPENSE > 0) || propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FRAISCOMM" && expense.MONTANT_DEPENSE > 0) || propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "ENER" && expense.MONTANT_DEPENSE > 0)) && (
                                                            <div className="flex flex-col justify-between p-3 pr-0 w-full  ">
                                                                <p className="font-medium mb-5">{t('financial.exp')}</p>

                                                                {/* Co-ownership Fees */}
                                                                {propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FCOP" && expense.MONTANT_DEPENSE > 0) && (
                                                                    <div className="flex items-center justify-between p-3   border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.coownership')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                formatPrice(propertyDetails.taxDetails.find((expense: any) => expense.TDEP_CODE === "FCOP").MONTANT_DEPENSE)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                {/*Common Expenses*/}
                                                                {propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "FRAISCOMM" && expense.MONTANT_DEPENSE > 0) && (
                                                                    <div className="flex items-center justify-between p-3   border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.common')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                formatPrice(propertyDetails.taxDetails.find((expense: any) => expense.TDEP_CODE === "FRAISCOMM").MONTANT_DEPENSE)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}


                                                                {/* Energy Cost */}
                                                                {propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "ENER" && expense.MONTANT_DEPENSE > 0) && (
                                                                    <div className="flex items-center justify-between p-3   border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.energy')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                formatPrice(propertyDetails.taxDetails.find((expense: any) => expense.TDEP_CODE === "ENER").MONTANT_DEPENSE)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {/* Snow Tax */}
                                                                {propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "GAZO" && expense.MONTANT_DEPENSE > 0) && (
                                                                    <div className="flex items-center justify-between p-3  border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.snow')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                formatPrice(propertyDetails.taxDetails.find((expense: any) => expense.TDEP_CODE === "GAZO").MONTANT_DEPENSE)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {/* Water Tax */}
                                                                {propertyDetails.taxDetails?.some((expense: any) => expense.TDEP_CODE === "TAXEAU" && expense.MONTANT_DEPENSE > 0) && (
                                                                    <div className="flex items-center justify-between p-3   border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0">
                                                                        <p className="font-medium">{t('financial.water')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                formatPrice(propertyDetails.taxDetails.find((expense: any) => expense.TDEP_CODE === "TAXEAU").MONTANT_DEPENSE)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {/* Total Expenses */}
                                                                {propertyDetails.taxDetails && (
                                                                    <div className="flex items-center justify-between p-3  border border-b-gray-300 border-r-0 border-l-0 border-t-0 ps-0 bg-orange-200">
                                                                        <p className="font-medium">{t('financial.total')}</p>
                                                                        <p className="text-gray-500 font-normal">
                                                                            {
                                                                                // Calculate the sum of all available expenses, excluding TAXMUN and TAXSCO, and filter out zero values
                                                                                formatPrice(propertyDetails.taxDetails
                                                                                    .filter((expense: any) =>
                                                                                        expense.MONTANT_DEPENSE > 0 &&
                                                                                        expense.TDEP_CODE !== "TAXMUN" &&
                                                                                        expense.TDEP_CODE !== "TAXSCO"
                                                                                    ) // Exclude TAXMUN, TAXSCO and zero values
                                                                                    .reduce((sum: any, expense: any) => sum + parseFloat(expense.MONTANT_DEPENSE || '0'), 0)) // Sum the MONTANT_DEPENSE values
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    }




                                                </div>


                                            </div>


                                        </div>



                                        <div className="mt-10 text-start">
                                            <h1 className="text-xl font-semibold">{t('individualListing.Map View')}</h1>

                                            <div className="w-full rounded-lg mt-10 h-96 p-7 ">

                                                <SinglePropertiesMap
                                                    coordinates={{
                                                        lat: parseFloat(coordinates.lat),
                                                        lng: parseFloat(coordinates.lng),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col p-2 gap-4 w-full lg:w-[30%] h-full font-open-sans">


                                        <div className="flex flex-col gap-4 px-2 pb-2  rounded-lg shadow-xl">
                                            <h1 className="text-2xl text-start font-semibold text-gray-800">
                                                {propertyDetails?.brokers.length > 1 ? `${t('individualListing.Brokers')}` : 'individualListing.Broker'}</h1>
                                            <BrokerDetails brokers={filterBrokerDetails(propertyDetails?.brokers) || []} />
                                        </div>
                                        <div className="bg-white rounded-lg shadow-xl p-4 mt-4">
                                            {/* form to sent message to brokers*/}
                                            <h1 className="text-start text-2xl font-semibold text-gray-800 py-3" >{t('enquiry.01')}</h1>
                                            <BrokerForm propertyDetails={filterBrokerDetails(propertyDetails?.brokers)} property={propertyName} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        {/* <h1 className="my-20"></h1> */}
                        <NoDataFound/>
                        </>
                        
                    )
                )}
</div>

        </>

    );
};

export default IndividualProperty;
