import React, { useState, useEffect, useContext } from "react";
import ListView from "../components/listView";
import Header from "../components/header";
import FilterComponent from "./filter";
import { FilterContext } from "../context/filterContext";
import { useTranslation } from "react-i18next";

const Rentals: React.FC = () => {
    const { setPurchaseType, setMaxPrice, setMinPrice, setPrice,resetFilters } = useContext(FilterContext);
    const {t} = useTranslation();

    useEffect(()=>{
        document.title = t('title.locations')
      },[t])
    
    useEffect(() => {
        setPurchaseType('Lease');
        setMaxPrice([50000]);
        setMinPrice([0]);
        setPrice([0, 50000]);
    }, [setPurchaseType, setMaxPrice, setMinPrice, setPrice]);
    useEffect(() => {
        return () => {
            resetFilters();
        };
    }, []);

    return (
        <>
            <Header />
            <div className="md:pt-36 pt-24">

            <FilterComponent />
            </div>
            <ListView />
        </>
    );
}

export default Rentals;
