import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { FaPhoneFlip } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { BiSolidNavigation } from "react-icons/bi";
import { IoTimeOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { sendMail } from "../services/mailService";
import { FaCheckCircle } from "react-icons/fa";

interface ErrorState {
    fullName: string;
    email: string;
    phone: string;
    interest?: string;
    message?: string;
}

const ContactPage: React.FC = () => {
    const [success, setSuccess] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('title.contact')
    }, [t])

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        interest: '',
        message: '',
    });

    const [error, setError] = useState<ErrorState>({
        fullName: '',
        email: '',
        phone: '',
        interest: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate the field as the user types
        if (name === "fullName" && !value) {
            setError((prev) => ({ ...prev, fullName: t("contact.fullNameRequired") }));
        } else if (name === "email" && (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))) {
            setError((prev) => ({ ...prev, email: t("contact.emailInvalid") }));
        } else if (name === "phone" && (!value || !/^\d{10}$/.test(value))) {
            setError((prev) => ({ ...prev, phone: t("contact.phoneInvalid") }));
        } else {
            setError((prev) => ({ ...prev, [name]: "" }));
        }
    };


    const validateForm = (): boolean => {
        const { fullName, email, phone, message } = formData;
        const newError: ErrorState = {
            fullName: '',
            email: '',
            phone: '',
        };

        if (!fullName) newError.fullName = t("contact.fullNameRequired");
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newError.email = t("contact.emailInvalid");
        }
        if (!phone || !/^\d{10}$/.test(phone)) {
            newError.phone = t("contact.phoneInvalid");
        }
        if (!message) {
            newError.message = t("contact.messageRequired");
        }

        setError(newError);

        return Object.keys(newError).filter((key) => newError[key as keyof ErrorState] !== '').length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;

        // console.log('Form submitted:', formData);
        // Clear form after submission
        try {
            setFormSubmitted(true);
            await sendMail(formData);
            setFormSubmitted(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
            setFormData({
                fullName: '',
                email: '',
                phone: '',
                interest: '',
                message: '',
            });
        } catch (error) {
            console.error('Failed to send email:', error);
        }

        
    };

  

    return (
        <>
            <Header />



            <div className="bg-gray-100 flex justify-center items-center md:pt-36 pt-24">
                <div className="bg-white shadow-lg rounded-lg p-8 md:flex md:space-x-10">
                    {/* Contact Information */}
                    <div className="md:w-1/2 w-full mb-8 md:mb-0 md:mr-10 p-5 px-2 lg:px-10">
                        <h1 className="text-2xl p-0 pb-5 text-start text-yellow-500 font-medium">{t("contact.contactUs")}</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2 text-start gap-5 items-middle">
                            <div className="mb-4">
                                <p className="text-yellow-500 font-md text-lg flex items-center gap-3">
                                    <FaPhoneFlip />{t("contact.phone")}
                                </p>
                                <p className="mt-2 font-light text-base md:text-lg">1 (819) 425-9324</p>
                            </div>
                            <div className="mb-4">
                                <p className="text-yellow-500 font-md text-lg flex items-center gap-3">
                                    <CiMail />{t("contact.email")}
                                </p>
                                <a href="mailto:info@mtre.ca" className="mt-2 font-light text-base md:text-lg cursor-pointer">Info@Mtre.Ca</a>
                            </div>

                            <div className="h-0.5 w-14 bg-gray-400 mb-2 hidden md:block"></div>
                            <div className="h-0.5 w-14 bg-gray-400 mb-2 hidden md:block"></div>

                            <div className="mb-4">
                                <p className="text-yellow-500 font-md  text-lg flex items-center gap-3">
                                    <BiSolidNavigation />{t("contact.addressLabel")}
                                </p>
                                <p className="mt-2 font-light text-base md:text-lg">2195, Chemin Du Village, Mont-Tremblant, Québec, J8E 3M3</p>
                            </div>
                            <div className="mb-4">
                                <p className="text-yellow-500 font-md text-base md:text-lg flex items-center gap-3">
                                    <IoTimeOutline /> {t("contact.hoursLabel")}
                                </p>
                                <p className="mt-2 font-light text-base md:text-lg">{t('contact.mon')} - {t('contact.sun')}: 9am - 5pm</p>
                                {/* <p className="mt-1 font-light text-base md:text-lg">{t('contact.sat')} & {t('contact.sun')}: 10am - 4pm</p> */}
                            </div>
                        </div>
                    </div>

                        {success && (
                            <div className='bg-white p-3 m-2 rounded fixed shadow-lg z-[500] w-fit top-2 right-2 ' >
                            {/* <IoClose size={24} className="absolute right-0 top-0 m-3 cursor-pointer z-40 text-black" onClick={onClose} /> */}
                            <div className="flex items-center gap-2 justify-center pb-2 ">
                                <FaCheckCircle size={25} color='green' className='' />
                                <h1 className="text-sm text-center font-semibold text-[green]  ">Mail Sent Successfully</h1>
                            </div>
                            <div className='bar  rounded absolute left-0 bottom-0' >
                            </div>
                        </div>
                           
                        )}
                    

                    




                    {/* Contact Form */}
                    <div className="h-full shadow-lg md:w-1/2 p-5 pt-10">
                        <form onSubmit={handleSubmit} className="space-y-4 text-start">
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        placeholder={`${t("contact.fullName")}*`}
                                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${error.fullName ? 'border-Red-600 focus:ring-Red-600' : 'focus:ring-yellow-500'}`}
                                    />
                                    {error.fullName && <p className="text-Red-600 text-xs">{error.fullName}</p>}
                                </div>
                                <div className="w-full">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder={`${t("contact.email")}*`}
                                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${error.email ? 'border-Red-600 focus:ring-Red-600' : 'focus:ring-yellow-500'}`}
                                    />
                                    {error.email && <p className="text-Red-600 text-xs">{error.email}</p>}
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="w-full">
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder={`${t("contact.phone")}*`}
                                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${error.phone ? 'border-Red-600 focus:ring-Red-600' : 'focus:ring-yellow-500'}`}
                                    />
                                    {error.phone && <p className="text-Red-600 text-xs">{error.phone}</p>}
                                </div>
                                <div className="w-full">
                                    <select
                                        name="interest"
                                        value={formData.interest || ""} // Ensures placeholder shows correctly
                                        onChange={handleChange}
                                        className={`text-md w-full px-4 py-2 border font-md rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 ${!formData.interest ? "text-gray-400" : "text-black"
                                            }`}
                                    >
                                        <option className="font-light text-gray-400" value="" disabled>
                                            {t("contact.interest")} {/* Placeholder text */}
                                        </option>
                                        <option className="font-light text-black" value="Buying">{t("contact.buying")}</option>
                                        <option className="font-light text-black" value="Selling">{t("contact.selling")}</option>
                                        <option className="font-light text-black" value="Area Information">{t("contact.areaInfo")}</option>
                                        <option className="font-light text-black" value="Home Valuation">{t("contact.homeValuation")}</option>
                                        <option className="font-light text-black" value="Other">{t("contact.other")}</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder={`${t("contact.message")}*`}
                                    className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${error.message ? 'border-Red-600 focus:ring-Red-600' : 'focus:ring-yellow-500'}`}
                                ></textarea>
                                {error.message && <p className="text-Red-600 text-xs">{error.message}</p>}
                            </div>

                            <button
                                type="submit"
                                className="text-start p-2 px-4 bg-pantone288-700 hover:bg-yellow-500 hover:text-white font-semibold py-2 rounded-md text-white transition duration-200"
                            >
                                {formSubmitted ? (
                                <div className="flex justify-center items-center">
                                    <div className="spinner"></div>
                                    <span className="text-md ml-2">{t("send")}</span>
                                </div>
                            ) : (
                                t("contact.send")
                            )}
                               
                            </button>
                            
                        </form>
                    </div>
                </div>
            </div>

            <div className="responsive-iframe w-full md:w-full mx-auto ">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2322.2868210367724!2d-74.61931818661763!3d46.19692600712733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccf9d13891202fb%3A0x1e1b7b49ef2c1ee3!2sAuberge%20Manitonga%20Room-Suite-Condo!5e0!3m2!1sen!2sin!4v1731671196239!5m2!1sen!2sin"
                    className="w-full h-72 rounded-lg"
                    allowFullScreen={true}
                    loading="lazy"
                    title={"mont"}
                ></iframe>
            </div>
        </>
    );
};

export default ContactPage;
