import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from "../context/filterContext";
import { useTranslation } from 'react-i18next';

interface CardProps {
    title: string;
    description: string;
    imageUrl: string;
    keyVal: any;
}

const CollectionCard: React.FC<CardProps> = ({ title, description, imageUrl, keyVal }) => {
    const navigate = useNavigate();
    const { collections, setCollections } = useContext(FilterContext);
        const { i18n } = useTranslation();

        const language = i18n.language;
    

    return (
        <>
            <div 
                className="rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform transform hover:scale-105 hover:shadow-xl"
                onClick={() => {
                    setCollections(keyVal);
                    if (keyVal === "international-properties") {
                        window.open(`/${language}/global-luxury-search`,'_blank');
                    } else {
                        navigate(`/${language}/listings`);
                    }
                }}
            >
                <div>
                    <img src={imageUrl} alt={title} className="w-full rounded-lg h-52 object-cover rounded-t-lg" />
                </div>
                <div className="p-4 text-center">
                    <h3 className="font-semibold text-lg text-yellow-500">{title}</h3>
                    <p className="text-gray-300 mt-2">{description}</p>
                </div>
            </div>
        </>
    );
};

export default CollectionCard;
