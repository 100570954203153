import Header from "../components/header";
import houseImg from '../assets/images/home-with-night.webp';
import { useTranslation } from 'react-i18next';
import montTremb from "../assets/images/mont-tremblant.webp";
import { useEffect } from "react";


export default function About() {

   

    const {t} = useTranslation();
    useEffect(()=>{
      document.title = t('title.about')
    },[t])
    const boldifyText = (text:string, boldWords:string[]) => {
      const regex = new RegExp(`(${boldWords.join("|")})`, "gi"); // Match any word in the array
      const parts = text.split(regex);
  
      return parts.map((part, index) =>
        boldWords.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
          <span className="font-bold" key={index}>{part}</span>
        ) : (
          <span key={index}>{part}</span>
        )
      );
    };
  return (
    <>
    <Header></Header>

<div >
  

<section className="px-2 lg:px-24 lg:pb-8 bg-white text-gray-800 text-right md:pt-36 pt-24">
  <div className="p-2 flex flex-col lg:flex-row gap-6 mt-8 items-center lg:items-start lg:pe-4">
    <div className="max-w-4xl text-start lg:w-1/2">
      {/* Subheading */}
      <h4 className="text-lg font-medium text-yellow-500 uppercase tracking-wider mb-2">
        {t('about.00')}
      </h4>

      {/* Main Heading */}
      <h2 className="text-2xl lg:text-4xl text-gray-900 mb-6">
        {t('about.01')}
      </h2>

      {/* Description */}
      <p className="text-sm lg:text-base text-gray-600 leading-relaxed mb-4 max-w-3xl">
        {t('about.02')}
      </p>

      <p className="text-sm lg:text-base text-gray-600 leading-relaxed mb-4 max-w-3xl">
        {t('about.03')}
      </p>

      <p className="text-sm lg:text-base text-gray-600 leading-relaxed max-w-3xl">
        {boldifyText(t('about.04'), ["LUXURYREALESTATE.COM", "JAMESEDITION.COM"])}
      </p>
    </div>

    <div className='w-full lg:w-1/2 text-start  h-auto lg:h-[480px]'>
      <img
        src={montTremb}
        alt='mont-tremblant'
        className='rounded-lg h-full w-full object-cover'
      />
    </div>
  </div>
</section>

</div>
<div className=" bg-pantone288-500 text-white">
  <div className="container mx-auto px-4 py-16 flex">
    <div className="grid md:grid-cols-2 gap-8  ">
      {/* Image Section */}
      <div className="w-full ">
        <img
          src={houseImg}
          alt="House at night"
          className="w-full h-full rounded-lg shadow-lg"
        />
      </div>
      {/* Text Section */}
      {/* <div className=" grid-cols-2 grid text-start">
    
        <div className='p-2'>
            <div className='flex items-center gap-3'>
          <h2 className="text-3xl font-bold text-yellow-500">01</h2>
          <div className="h-0.5 w-10 bg-gray-800 mb-2"></div>
          </div>
          <h3 className="text-2xl mt-2">
            {t("brockers.07")}
          </h3>
          <p className="mt-2 text-gray-500">
          {t("brockers.08")}
          </p>
        </div>
      
        <div className='p-2'>
            <div className='flex items-center gap-3'>
          <h2 className="text-3xl font-bold text-yellow-500">02</h2>
          <div className="h-0.5 w-10 bg-gray-800 mb-2"></div>
          </div>
          <h3 className="text-2xl mt-2">
          {t("brockers.09")}
          </h3>
          <p className="mt-2 text-gray-500">
          {t("brockers.10")}
          </p>
        </div>
      
        <div className='p-2'>
        <div className='flex items-center gap-3'>
          <h2 className="text-3xl font-bold text-yellow-500">03</h2>
          <div className="h-0.5 w-10 bg-gray-800 mb-2"></div>
          </div>
          <h3 className="text-2xl mt-2">{t("brockers.11")}</h3>
          <p className="mt-2 text-gray-500">
          {t("brockers.12")}
          </p>
        </div >
    
        <div className='p-2'>
        <div className='flex items-center gap-3'>
          <h2 className="text-3xl font-bold text-yellow-500">04</h2>
          <div className="h-0.5 w-10 bg-gray-800 mb-2"></div>
          </div>
          <h3 className="text-2xl mt-2">{t("brockers.13")}</h3>
          <p className="mt-2 text-gray-500">
          {t("brockers.14")}
          </p>
        </div>
      </div> */}

      <div className='flex flex-col justify-around'>
        <div>
          <h1 className="text-5xl text-yellow-500 text-start">{t("brockers.23")}</h1>
          <p className='text-start mt-2'>{t("brockers.24")}</p>
        </div>

        <div className='mt-3'>
          <h1 className="text-5xl text-yellow-500 text-start">{t("brockers.25")}</h1>
          <p className='text-start mt-2'>{t("brockers.26")}</p>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <div className="bg-white text-gray-800 lg:py-32 py-8 lg:px-16 px-5">
  <div className="container mx-auto px-4">
    <div className="grid md:grid-cols-3 gap-8">
      {/* Column 1 */}
      {/* <div className="text-start">
        <div className="border-t-2 border-gray-300 ml-2 w-24 mb-4"></div>
        <h3 className="text-2xl">{t("brockers.15")}</h3>
        <p className="mt-4 text-gray-400">
        {t("brockers.16")}
        </p>
      </div> */} 
      {/* Column 2 */}
      {/* <div className="text-start">
        <div className="border-t-2 border-gray-300 ml-2 w-24 mb-4"></div>
        <h3 className="text-2xl">{t("brockers.17")}</h3>
        <p className="mt-4 text-gray-400">
        {t("brockers.18")}
        </p>
      </div> */}
      {/* Column 3 */}
      {/* <div className="text-start">
        <div className="border-t-2 border-gray-300 ml-2 w-24 mb-4"></div>
        <h3 className="text-2xl ">{t("brockers.19")}</h3>
        <p className="mt-4 text-gray-400">
        {t("brockers.20")}
        </p>
      </div>
    </div>
  </div>
</div> */}
    </>

  )
}
