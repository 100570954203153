 import monttremblantImg from '../../assets/images/mont-tremblant.webp';
import monttremblantImg2 from '../../assets/images/mont-tremblant-40-scaled.webp';
import montimg3 from '../../assets/images/mont-trembalntpark.webp';
import montimg4 from '../../assets/images/image.png'
import montim5 from '../../assets/images/mont-tremblanteve.webp';
import montim6 from '../../assets/images/tremblant.webp';
import Header from '../../components/header';
import { useTranslation } from 'react-i18next';
import { PiCurrencyCircleDollarThin } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { PiPersonSimpleWalkLight } from "react-icons/pi";
import { useEffect } from 'react';

const MontTremblant = () => {
    const {t} = useTranslation();
    useEffect(()=>{
        document.title = t('title.tremblant')
    },[t])

    const { i18n } = useTranslation();
    const language = i18n.language


    const replaceString = (str: string) => {
        if (language === "fr") {
            return str.replace(/41\.2/g, "47")
                      .replace(/\$90567/g, "74 600 $")
                      .replace(/7,019/g, "10 992")
                      .replace(/41,2/g, "47")
                      .replace(/90 567 \$/g, "74 600 $")
                      .replace(/7 019/g, "10 992")
                      .replace(/High/g, "Low")
                      .replace(/\$74,600/g, "72 600 $")
                      .replace(/Haut/g, "Bas")
                      .replace(/Aspen/g, "Mont-Tremblant");
        } else {
            return str.replace(/41\.2/g, "47")
                      .replace(/\$90567/g, "$74,600")
                      .replace(/7,019/g, "10,992")
                      .replace(/41,2/g, "47")
                      .replace(/90 567 \$/g, "74 600 $")
                      .replace(/7 019/g, "10 992")
                      .replace(/High/g, "Low")
                      .replace(/\$90,567/g, "$72,600")
                      .replace(/Haut/g, "Bas")
                      .replace(/Aspen/g, "Mont-Tremblant");
        }
    }



    return (
        <><Header /><div className="px-5 md:px-20 md:pt-36 pt-24">
            {/* Heading */}
            <div className="font-light text-start">
                <h1 className="text-2xl text-yellow-500 mt-3">{t("communities.tremblant.explore")}</h1>
                <h1 className="text-4xl md:text-6xl ">MONT TREMBLANT</h1>
                <p className="text-gray-600 mt-2">{t("communities.tremblant.explore1")}</p>
            </div>

            {/* content-1 */}
<div className="p-2 flex flex-col lg:flex-row gap-2 mt-5 items-stretch">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-between'>
        <p className='text-gray-500'>{t("communities.tremblant.explore2")}</p>
        <p className='text-gray-500 mt-2'>{t("communities.tremblant.explore3")}</p>
        <h1 className='text-yellow-500 text-2xl  font-light mb-2 mt-2'>{t("communities.tremblant.love.14")}</h1>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.00")}</span>{t("communities.tremblant.love.01")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.02")}</span>{t("communities.tremblant.love.03")}
        </p>
    </div>
    <div className='w-full lg:w-1/2 lg:p-5 h-full'>
        <img src={monttremblantImg} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
    </div>
</div>

{/* content-2 */}
<div className="p-2 flex flex-col-reverse lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className='w-full lg:w-1/2 text-start lg:p-5'>
        <img src={monttremblantImg2} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-between'>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.04")} </span>{t("communities.tremblant.love.05")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.06")}</span>{t("communities.tremblant.love.07")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.08")}</span>{t("communities.tremblant.love.09")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.10")}</span>{t("communities.tremblant.love.11")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.love.12")}</span>{t("communities.tremblant.love.13")}
        </p>
    </div>
</div>

{/* content-4 */}
<div className="p-2 flex flex-col lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className='w-full lg:w-1/2 lg:p-5 h-full text-start'>
        <h1 className='text-yellow-500 text-2xl font-light mt-2 pb-2'>{t("communities.tremblant.village.00")}</h1>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.village.01")}</span>{t("communities.tremblant.village.02")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.village.03")}</span>{t("communities.tremblant.village.04")}
        </p>
        <p className='text-gray-500 mt-2'>
            <span className="font-semibold">{t("communities.tremblant.village.05")}</span>{t("communities.tremblant.village.06")}
        </p>
    </div>
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-between'>
        <img src={montimg4} alt='mont-tremblant' className='rounded-lg h-full w-full object-cover' />
    </div>
</div>

{/* content-5 */}
<div className="p-2 flex flex-col-reverse lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className="w-full lg:w-1/2 lg:p-5">
        <img src={montim5} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
    </div>
    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-start gap-5">
        <h1 className="text-yellow-500 text-2xl font-light">{t("communities.tremblant.living.00")}</h1>
        <div>
            <p className="text-gray-500 font-semibold">{t("communities.tremblant.living.01")}</p>
            <p className="text-gray-500">{t("communities.tremblant.living.02")}</p>
        </div>
        <div>
            <p className="text-gray-500 font-semibold">{t("communities.tremblant.living.03")}</p>
            <p className="text-gray-500">{t("communities.tremblant.living.04")}</p>
        </div>
        <div>
            <p className="text-gray-500 font-semibold">Saint-Jovite</p>
            <p className="text-gray-500">{t("communities.tremblant.living.06")}</p>
        </div>
    </div>
</div>


            {/* contenet - 6  */}
            <div className="p-2 flex flex-col lg:flex-row gap-2 lg:mt-5 items-stretch">
    <div className="w-full lg:w-1/2 text-start lg:p-5 flex flex-col justify-between">
        <h1 className="text-yellow-500 text-2xl font-light">{t("communities.tremblant.population.00")}</h1>
        <p className="text-gray-500">{t("communities.tremblant.population.01")}</p>
        <h1 className="text-2xl text-yellow-500 font-light">{t("communities.tremblant.population.02")}</h1>
        <p className="text-gray-500">{replaceString(t("communities.tremblant.population.03"))}</p>

        <div className="flex pt-2 pb-2 w-full gap-5 flex-wrap">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pt-7 pb-5 w-full">
                {/* First Icon Card */}
                <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-3xl bg-gray-400 flex items-center justify-center">
                        <CiLocationOn color="white" size={24} />
                    </div>
                    <div>
                        <p className="text-sm text-gray-700">10,992</p>
                        <p className="text-sm text-gray-400">{t("communities.tremblant.population.04")}</p>
                    </div>
                </div>

                {/* Second Icon Card */}
                <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                        <IoPersonOutline size={24} color="white" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.05"))}</p>
                        <p className="text-sm text-gray-400">{t("communities.tremblant.population.06")}</p>
                    </div>
                </div>

                {/* Third Icon Card */}
                <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                        <PiPersonSimpleWalkLight size={24} color="white" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-700">{replaceString(t("communities.tremblant.population.07"))}</p>
                        <p className="text-sm text-gray-400">{(t("communities.tremblant.population.08"))}</p>
                    </div>
                </div>

                {/* Fourth Icon Card */}
                <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center">
                        <PiCurrencyCircleDollarThin size={30} color="white" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-700">{replaceString("$74,600")}</p>
                        <p className="text-sm text-gray-400">{t("communities.tremblant.population.09")}</p>
                    </div>
                </div>
            </div>
        </div>

        <p className="text-gray-500 mt-2">{t("communities.tremblant.population.10")}</p>
    </div>

    <div className="w-full lg:w-1/2 lg:p-5">
        <img src={montim6} alt="mont-tremblant" className="rounded-lg h-full w-full object-cover" />
    </div>
</div>

<div className="ps-2 flex flex-col lg:flex-row gap-2 items-stretch">
    <div className='w-full lg:w-1/2 text-start lg:p-5 flex flex-col'>
        <p className='text-xl font-semibold my-2 text-gray-800'>{t("communities.lac.school")}</p>
        <p className="text-gray-500 ms-2">-Pavillon La Ribambelle</p>
        <p className="text-gray-500 ms-2">-Pavillon Fleur-Soleil</p>
        <p className="text-gray-500 ms-2">-Pavillon Trois-Saisons</p>
        <p className="text-gray-500 ms-2">-Pavillon Tournesol</p>
        <p className="text-gray-500 ms-2">-École l’Odyssée</p>
    </div>
    <div className='w-full lg:w-1/2 lg:p-5 text-start'>
        <p className='text-xl font-semibold my-2 text-gray-800'>{t("communities.sainte-agathe.school-sec")}</p>
        <p className="text-gray-500 ms-2">-Polyvalente Curé-Mercure</p>
        <p className="text-gray-500 pb-10 ms-2">-CEGEP</p>
    </div>
</div>

            <div className='pb-7 ps-2 lg:ps-7 text-start text-gray-400'>
                <h1 className='text-lg  text-gray-800 font-semibold'>{t("communities.English-Schools")}</h1>
                <p className='text-gray-500 mt-2'>{t("communities.tremblant.school-eng.00")}</p>

                <p className='text-gray-600 mt-2'>{t("communities.tremblant.school-eng.01")}</p>
                <p className='text-gray-500 mt-2'> <span className="font-semibold"> {t("communities.tremblant.school-eng.02")}</span>{t("communities.tremblant.school-eng.03")}</p>
            </div>

        </div></>
    )
}

export default MontTremblant