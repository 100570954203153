import React, { useEffect } from 'react';
import Img from '../assets/images/first page picture.webp';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

const StatsSection: React.FC = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [inView, controls]);

  return (
    <div 
      ref={ref} 
      className="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 md:p-8 bg-pantone288-500 text-pantone1245"
    >
      {/* Statistics Block 1 */}
      <div className="flex flex-col items-center justify-center border-b md:border-b-0 md:border-r border-pantone1245 pb-4 md:pb-0">
        <motion.h2 
          className="text-4xl md:text-6xl" 
          initial={{ opacity: 0, y: 50 }} 
          animate={controls}
        >
          +{inView && <CountUp start={0} end={250} duration={3.5} />}
        </motion.h2>
        <p className="mt-2 text-base md:text-lg text-yellow-500">{t("stats_section.listings")}</p>
      </div>

      {/* Statistics Block 2 */}
      <div className="flex flex-col items-center justify-center border-b md:border-b-0 md:border-r border-pantone1245 pb-4 md:pb-0">
        <motion.h2 
          className="text-4xl md:text-6xl" 
          initial={{ opacity: 0, y: 50 }} 
          animate={controls}
        >
          {inView && <CountUp start={0} end={21} duration={3.5} />}
        </motion.h2>
        <p className="mt-2 text-base md:text-lg text-yellow-500">{t("stats_section.Brokers")}</p>
      </div>

      {/* Image Block */}
      <div className="relative hidden md:block">
        <img
          src={Img}
          alt="Luxury Interior"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Statistics Block 3 */}
      <div className="flex flex-col items-center justify-center bg-black bg-opacity-60">
        <motion.h2 
          className="text-4xl md:text-6xl" 
          initial={{ opacity: 0, y: 50 }} 
          animate={controls}
        >
          +{inView && <CountUp start={0} end={500} duration={3.5} />}
        </motion.h2>
        <p className="mt-2 text-base md:text-lg text-yellow-500">{t("stats_section.Happy_Clients_in_2024")}</p>
      </div>
    </div>
  );
};

export default StatsSection;
