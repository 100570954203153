import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';
import { MdClose } from "react-icons/md";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { LiaBathSolid } from "react-icons/lia";
import { IoBedOutline } from "react-icons/io5";
import { FilterContext } from "../context/filterContext";
import Modal from "../dialog-components/moreFilterDialog";
import { getAllLocations } from "../services/listingService";





const FilterComponent: React.FC = () => {

    const { t } = useTranslation();
    const propertiesOption = [
        { id: 1, name: t('propertyFilter.all'), key: 'all' },
        { id: 2, name: t('propertyFilter.home'), key: ['CH','ME','MEM','MM','MPM','PP'] },
        { id: 2, name: t('propertyFilter.condos'), key: ['CC','CI','AP','LS'] },
        { id: 2, name: t('propertyFilter.land'), key: ['TB','TV'] },




        // { id: 2, name: t('propertyFilter.commercial'), key: 'BTC' },
        // { id: 3, name: t('propertyFilter.condo'), key: 'CC' },
        // { id: 4, name: t('propertyFilter.sale'), key: 'VEN' },
        // { id: 5, name: t('propertyFilter.industrial'), key: 'BAI' },
        // { id: 6, name: t('propertyFilter.duplex'), key: '2X' },
        // { id: 7, name: t('propertyFilter.incondo'), key: 'CI' },
        // { id: 8, name: t('propertyFilter.quad'), key: '4X' },
        // { id: 9, name: t('propertyFilter.apartment'), key: 'AP' },
        // { id: 10, name: t('propertyFilter.hobby'), key: 'F' },
        // { id: 11, name: t('propertyFilter.two'), key: 'ME' },
        // { id: 12, name: t('propertyFilter.mobile'), key: 'MM' },
        // { id: 13, name: t('propertyFilter.bungalow'), key: 'PP' },
    ];
    const collectionTypes = [
        {
            key: "all",
            value: t("collectionTypes.all")
        },
        {
            key: "exclusives",
            value: t("collectionTypes.exclusive")
        },
        {
            key: "maisons-signature",
            value: t("collectionTypes.signature")
        },
        {
            key: "maisons-de-campagne",
            value: t("collectionTypes.country")
        },
        {
            key: "proprietes-riveraines",
            value: t("collectionTypes.water")
        },
        {
            key: "condos-regionaux",
            value: t("collectionTypes.regional")
        },
        {
            key: "pres-golf",
            value: t("collectionTypes.mountain")
        },
        {
            key: "centre-de-villegiature",
            value: t("collectionTypes.pedestrian")
        },
        {
            key: "ski-in-ski-out",
            value: t("collectionTypes.mountainD")
        },
        {
            key: "terrains-et-projets",
            value: t("collectionTypes.project")
        },
        {
            key: "commercial",
            value: t("collectionTypes.commercial")
        },
        {
            key: "locations-saisonnieres",
            value: t("collectionTypes.seasonal")
        },
        {
            key: "international-properties",
            value: t("collectionTypes.international")
        }
    ]
    const PurchaseTypeOption = [
        t('filter.Buy'),
        t('filter.Lease')
    ]
    const locationOption = [
        { "municipality": "Mont-Joli", "mun_code": 9077 },
        { "municipality": "Lac-Duparquet", "mun_code": 87902 },
        { "municipality": "Grosse-Île", "mun_code": 1042 },
        { "municipality": "Percé", "mun_code": 2005 },
        { "municipality": "Grande-Rivière", "mun_code": 2015 },
        { "municipality": "Port-Daniel/Gascons", "mun_code": 2047 },
        { "municipality": "Gaspé", "mun_code": 3005 },
        { "municipality": "Petite-Vallée", "mun_code": 3015 },
        { "municipality": "Murdochville", "mun_code": 3025 },
        { "municipality": "Saint-Maxime-du-Mont-Louis", "mun_code": 4010 },
        { "municipality": "Rivière-à-Claude", "mun_code": 4020 },
        { "municipality": "La Martre", "mun_code": 4030 },
        { "municipality": "Cap-Chat", "mun_code": 4047 },
        { "municipality": "Saint-Godefroi", "mun_code": 5015 },
        { "municipality": "Hope", "mun_code": 5025 },
        { "municipality": "New Carlisle", "mun_code": 5040 },
        { "municipality": "Saint-Elzéar", "mun_code": 5050 },
        { "municipality": "Caplan", "mun_code": 5060 },
        { "municipality": "New Richmond", "mun_code": 5070 },
        { "municipality": "Rivière-Bonaventure", "mun_code": 5902 },
        { "municipality": "Carleton-sur-Mer", "mun_code": 6013 },
        { "municipality": "Escuminac", "mun_code": 6025 },
        { "municipality": "Ristigouche-Partie-Sud-Est", "mun_code": 6035 },
        { "municipality": "Matapédia", "mun_code": 6045 },
        { "municipality": "Saint-François-d'Assise", "mun_code": 6055 },
        { "municipality": "Sainte-Marguerite-Marie", "mun_code": 7005 },
        { "municipality": "Causapscal", "mun_code": 7018 },
        { "municipality": "Saint-Léon-le-Grand", "mun_code": 7030 },
        { "municipality": "Sainte-Irène", "mun_code": 7040 },
        { "municipality": "Lac-au-Saumon", "mun_code": 7057 },
        { "municipality": "Saint-Tharcisius", "mun_code": 7070 },
        { "municipality": "Val-Brillant", "mun_code": 7080 },
        { "municipality": "Saint-Cléophas", "mun_code": 7090 },
        { "municipality": "Saint-Noël", "mun_code": 7100 },
        { "municipality": "Lac-Casault", "mun_code": 7908 },
        { "municipality": "Les Méchins", "mun_code": 8005 },
        { "municipality": "Grosses-Roches", "mun_code": 8015 },
        { "municipality": "Saint-Adelme", "mun_code": 8030 },
        { "municipality": "Sainte-Paule", "mun_code": 8040 },
        { "municipality": "Saint-Léandre", "mun_code": 8065 },
        { "municipality": "Baie-des-Sables", "mun_code": 8080 },
        { "municipality": "Saint-Charles-Garnier", "mun_code": 9010 },
        { "municipality": "Sainte-Jeanne-d'Arc", "mun_code": 9020 },
        { "municipality": "Saint-Donat", "mun_code": 9030 },
        { "municipality": "Padoue", "mun_code": 9040 },
        { "municipality": "Saint-Octave-de-Métis", "mun_code": 9055 },
        { "municipality": "Price", "mun_code": 9065 }
    ]
    const {
        propertyType,
        setPropertyType,
        location,
        setLocation,
        price,
        setPrice,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        mlsNumber,
        setMlsNumber,
        purchaseType,
        setPurchaseType,
        status,
        setStatus,
        currentView,
        setCurrentView,
        sortType, setSortType,
        collections, setCollections, resetFilters
    } = useContext(FilterContext);
    const [openDropdown, setOpenDropdown] = useState<any>('New Carlisle')
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [locationArray, setLocationArray] = useState<any[]>([]);

    useEffect(() => {
        getLocations()
    }, [])


    


    const getLocations = async () => {
        try {
            const response = await getAllLocations();
            setLocationArray(response.data);
            const arr = response.data.map((item: any) => ({ municipality: item.municipality, mun_code: item.mun_code }));

        } catch (err: any) {
            console.log(err)
        }
    }


    const filteredLocations = locationOption.filter(location =>
        location.municipality.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const closeToggle = () => {
        setOpenDropdown('')
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleFilters = (data: any) => {
        // setFilterData(data)
    }
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event: any) => {
        if (!event.target.closest('.relative')) {
            setOpenDropdown('')
        }
    };
    const handleRangeChange = (value: any) => {
        setPrice(value);
    };
    const handleAfterChange = (value: any) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    };
    return (
        <div className="flex items-center justify-between space-x-4 p-4 bg-white border-b border-gray-200 rounded-lg">
            <div className='flex gap-2'>

                <div className='relative'>
                    <div className="px-4 py-2 border justify-between border-gray-300 rounded-md flex items-center cursor-pointer w-48"
                        onClick={() =>
                            setOpenDropdown((prev: any) => (prev === 'collectionType' ? null : 'collectionType'))
                        }>
                        {/* Display the value of the selected collection based on the key */}
                        <p className="truncate w-44 text-start">
                            {collectionTypes.find(item => item.key === collections)?.value || t('filter.select')}
                        </p>
                        <span className="ml-2"><FaAngleDown /></span>
                    </div>
                    {openDropdown === 'collectionType' && (
                        <div className='absolute top-12 left-0 bg-white shadow-md rounded-md w-72 max-h-72 overflow-auto z-[450]'>
                            {collectionTypes.map(item => (
                                <div key={item.key}
                                    className={`px-4 hover:bg-pantone288-600 truncate hover:text-white py-2 flex items-center cursor-pointer ${collections === item.key ? 'bg-pantone288-500 text-white' : ''}`}
                                    onClick={() => {
                                        // Store only the key, not the full object
                                        setCollections(item.key);
                                        closeToggle();
                                    }}>
                                    {item.value}
                                </div>
                            ))}
                        </div>
                    )}
                </div>


                <div className='relative  hidden lg:block'>
                    <div
                        className="px-4 py-2 border justify-between border-gray-300 rounded-md flex items-center cursor-pointer w-48 truncate"
                        onClick={() => setOpenDropdown((prev: any) => (prev === 'propertyType' ? null : 'propertyType'))}
                    >
                        <p className="truncate w-44 text-start">
                        {propertyType?.name || t('propertyFilter.all')}
                        </p>
                        <span className="ml-2"><FaAngleDown /></span>
                    </div>

                    {openDropdown === 'propertyType' && (
                        <div className='absolute top-12 left-0 bg-white shadow-md rounded-md w-72 max-h-72 overflow-auto z-[450]'>
                            {propertiesOption.map(item => (
                                <div
                                    key={item.id}
                                    className={`px-4 hover:bg-pantone288-600 truncate hover:text-white py-2 flex items-center cursor-pointer ${propertyType?.name === item.name ? 'bg-pantone288-500 text-white' : ''}`}
                                    onClick={() => {
                                        setPropertyType(item);
                                        closeToggle();
                                    }}
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className='relative hidden lg:block'>
                    <div className="px-4 py-2 border border-gray-300 rounded-md flex items-center cursor-pointer w-48"
                        onClick={() =>
                            setOpenDropdown((prev: any) => (prev === 'price' ? null : 'price'))
                        }>
                        <p className='w-48 truncate'>{t('filter.min')} ${price[0].toLocaleString()} - {t('filter.max')} ${price[1].toLocaleString()}</p>
                        <span className="ml-2"><FaAngleDown /></span>
                    </div>
                    {openDropdown === 'price' && (
                        <>
                            <div className='absolute top-12 p-3 left-0 bg-white shadow-md rounded-md w-72 z-[450]'>
                                <Slider
                                    range
                                    min={0}
                                    max={purchaseType === 'Lease' ? 50000 : 25000000}
                                    value={price}
                                    onChange={handleRangeChange}
                                    onChangeComplete={handleAfterChange}
                                    className="w-full h-2 mb-6 rounded-lg appearance-none cursor-pointer"
                                />
                                <div className="flex justify-between mt-2">
                                    <span>${price[0].toLocaleString()}</span>
                                    <span>${price[1].toLocaleString()}</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className='relative hidden lg:block'>
                    <div className="px-4 py-2 justify-between border border-gray-300 rounded-md flex items-center cursor-pointer w-26 gap-2"
                        onClick={() =>
                            setOpenDropdown((prev: any) => (prev === 'bath' ? null : 'bath'))
                        }
                    >
                        <div className='flex items-center gap-1' >
                            <LiaBathSolid />
                            <p className="text-sm">{bathrooms ? `${bathrooms}` : t('collectionTypes.all')}</p>
                        </div>
                        <FaAngleDown  />
                    </div>
                    {openDropdown === 'bath' && (
                        <div className="absolute top-12 left-0 py-3 bg-white shadow-md rounded-md w-36 z-[450]">
                            {[t('collectionTypes.all'), "1", "2", "3", "4", '5+'].map((bath: any, index) => (
                                <div
                                    key={index}
                                    className="flex items-center border-gray-200  px-10 py-1 rounded  gap-3 cursor-pointer hover:bg-pantone288-600 hover:text-white"
                                    onClick={() => {
                                        (bath === 'All' ? setBathrooms('') : setBathrooms(bath))
                                        closeToggle()
                                    }}

                                >
                                    <LiaBathSolid   />
                                    <span>{bath}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>


                <div className="relative hidden lg:block">
                    <div
                        className="px-4 py-2 justify-between border border-gray-300 rounded-md flex items-center cursor-pointer gap-3"
                        onClick={() =>
                            setOpenDropdown((prev: any) => (prev === 'bed' ? null : 'bed'))
                        }
                    >
                        <div className='flex gap-1 items-center'>
                            <IoBedOutline />
                            <p className="text-sm">{bedrooms ? `${bedrooms} ` : t('collectionTypes.all')} </p>
                        </div>

                        <FaAngleDown />
                    </div>
                    {openDropdown === 'bed' && (
                        <div className="absolute top-12 left-0 py-3 bg-white shadow-md rounded-md w-36 z-[450]">
                            {[t('collectionTypes.all'), '1', '2', '3', '4', '5+'].map((bed: any, index) => (
                                <div
                                    key={index}
                                    className="flex items-center  py-1 gap-3 cursor-pointer  px-10 rounded   hover:bg-pantone288-600 hover:text-white"
                                    onClick={() => {
                                        (bed === 'All' ? setBedrooms('') : setBedrooms(bed))

                                        closeToggle()
                                    }}
                                >
                                    <IoBedOutline />
                                    <span>{bed}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="md:px-4 px-[5px] py-2 border border-gray-300 rounded-md flex items-center cursor-pointer" onClick={() => {
                setIsModalOpen(!isModalOpen)
                closeToggle()
            }}>
                &#x2699; {t('filter.more')}
            </div>
            <Modal isOpen={isModalOpen} value={''} onClose={closeModal} onSubmit={handleFilters} index={''} title={'filter'} />
        </div>
    )
}

export default FilterComponent
