import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Header from '../components/header';

export default function Cookies() {
    const { t } = useTranslation();
      useEffect(()=>{
        document.title = t('title.cookies')
    },[t])

    const highlightAndRedirect = () => {
        const elements = document.querySelectorAll('p');
        elements.forEach((element) => {
            if (element.textContent?.includes('www.tremblantrealestate.ca')) {
                const highlightedText = element.textContent.replace(
                    'www.tremblantrealestate.ca',
                    `<span style="color: #042c89; cursor: pointer;">www.tremblantrealestate.ca</span>`
                );
                element.innerHTML = highlightedText;
                element.querySelector('span')?.addEventListener('click', () => {
                    window.location.href = 'https://www.tremblantrealestate.ca';
                });
            }
        });
    };

    useEffect(() => {
        highlightAndRedirect();
    }, [t]);

  return (
    <>
    <Header></Header>
    <div className="px-4 md:px-24 md:pt-36 pt-20 text-start">
        <section className='my-8'>
        <h1 className='text-4xl font-normal text-yellow-500 ' >{t('cookies.00')}</h1>
        <p className='my-8 text-xl' >{t('cookies.01')}</p>
        <p className=''>{t('cookies.02')}</p>

        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.03')}</h2>
            <p className='mb-8' >
            {t('cookies.04')}
            </p>
            <p className='mb-8' >
            {t('cookies.05')}
            </p>
            <p className='mb-8'>
            {t('cookies.06')}
            </p>
            <p className='mb-8'>
            {t('cookies.07')}
            </p>

        </section>

        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.08')}</h2>
            <p className='mb-8' >
            {t('cookies.09')}
            </p>
            <p className='mb-8' >
            {t('cookies.10')}
            </p>
            <p className='mb-8'>
            {t('cookies.11')}
            </p>
            <p className='mb-8'>
            {t('cookies.12')}
            </p>

        </section>

        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.13')}</h2>
            <p className='mb-8' >
            {t('cookies.14')}
            </p>
        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.15')}</h2>
            <p className='mb-8' >
            {t('cookies.16')}
            </p>
        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.17')}</h2>
            <p className='mb-8' >
            {t('cookies.18')}
            </p>
        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.19')}</h2>
            <p className='mb-8' >
            {t('cookies.20')}
            </p>
        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.21')}</h2>
            <p className='mb-8' >
            {t('cookies.22')}
            </p>
            <p className='mb-8' >
            {t('cookies.23')}
            </p>
            <p className='mb-8'>
            {t('cookies.24')}
            </p>
            <p className='mb-8'>
            {t('cookies.25')}
            </p>

        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.26')}</h2>
            <p className='mb-8' >
            {t('cookies.27')}
            </p>
        </section>
        <section className='my-8' >
            <h2 className='mb-8 text-xl font-medium text-yellow-500' >{t('cookies.28')}</h2>
            <p className='mb-8' >
            {t('cookies.29')}
            </p>

            <div className='mb-8'>
                <p>{t('cookies.30')}</p>
                <p>{t('cookies.32')}</p>
            </div>

            <div>
                <p>{t('cookies.31')}</p>
                <p>{t('cookies.32')}</p>
            </div>
        </section>
       
        
    </div>
    
    </>


  )
}
