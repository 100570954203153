import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type LogoutConfirmationPopupProps = {
  onConfirm: () => void; // Function type for the "Logout" button
  onCancel: () => void;  // Function type for the "Cancel" button
};

export default function LogoutConfirmationPopup({
  onConfirm,
  onCancel,
}: LogoutConfirmationPopupProps) {

    const { t } = useTranslation();

  useEffect(() => {
    // Disable scrolling by setting body styles
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Cleanup function to re-enable scrolling
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = `0px`;

    };
  }, []);

  return (
    <div className="fixed px-2 lg:px-0 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
      <div className="bg-white text-black p-5 rounded">
        <h1 className="mb-2 text-lg font-semibold text-red-600">{t('logoutconfirmation.01')}</h1>
        <p className="mb-8">{t('logoutconfirmation.02')}</p>
        <div className="flex gap-8 justify-center">
          <button
            className="bg-pantone288-600 text-[16px] text-white px-2 py-1 rounded-lg hover:bg-yellow-500 hover:text-white"
            onClick={onConfirm}
          >
            {t('logoutconfirmation.03')}
          </button>
          <button
            className="border-2 text-[16px] text-yellow-500 px-2 py-1 rounded-lg hover:bg-yellow-500 hover:text-white"
            onClick={onCancel}
          >
            {t('logoutconfirmation.04')}
          </button>
        </div>
      </div>
    </div>
  );
}
