import Header from '../../components/header';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBlogById } from '../../services/blogService';
import { useTranslation } from "react-i18next";
import Loading from '../../components/loading';

// Define the type for a single description item
interface DescItem {
    id: number;
    Desc_EN: string;
    Desc_FR: string;
    img1?: {
        url: string;
    };
}

// Define the type for the blog object
interface Blog {
    Title_EN: string;
    Title_FR: string;
    Desc: DescItem[];
}

export default function BlogListing() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [blog, setBlog] = useState<Blog | null>(null); // Define the type for blog
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(i18n.language);

    console.log(id);
    

    // Update language when i18n.language changes
    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    // Fetch blog details when the component mounts or the ID changes
    useEffect(() => {
        if (!id) {
            console.error("Blog ID is undefined");
            setLoading(false);
            return;
        }

        const fetchBlogDetails = async () => {
            setLoading(true);
            try {
                const response = await getBlogById(id);
                console.log(response[0]);
                
                setBlog(response[0] || null); // Set the first blog item or null if no data
            } catch (err) {
                console.error("Error fetching blog:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetails();
    }, [id]);

    // Render loading state
    if (loading) {
        return (
            <div className="text-center">
                <Loading />
            </div>
        );
    }

    // Render if no blog is found
    if (!blog) {
        return (
            <div className="text-center text-red-500">
                Blog not found
            </div>
        );
    }

    return (
        <div>
            <Header />
            <section className='lg:px-[300px] px-5 py-10 md:pt-36 pt-24'>
                <div className="flex flex-col justify-center items-center">
                    {/* Popular Tag */}
                    <div className="text-pantone288-600 bg-yellow-500 rounded-lg px-4 py-1 font-semibold">
                        {t("popular")}
                    </div>

                    {/* Blog Title */}
                    <h1 className="md:text-5xl text-3xl font-[400] my-5 text-yellow-500">
                        {language === "fr" ? blog.Title_FR || "Titre non disponible" : blog.Title_EN || "Title not available"}
                    </h1>

                    {/* Blog Descriptions and Images */}
                    {blog.Desc && blog.Desc.length > 0 ? (
                        blog.Desc.map((descItem: DescItem, index: number) => (
                            <div key={index} className="w-full mb-5">
                                {/* Description */}
                                <p className="text-start">
                                    {language === "fr" ? descItem.Desc_FR || "Description non disponible" : descItem.Desc_EN || "Description not available"}
                                </p>

                                {/* Image */}
                                {descItem.img1?.url ? (
                                    <img
                                        className="w-full lg:h-[500px] my-5 rounded-lg"
                                        src={descItem.img1.url}
                                        alt={`Blog Image ${index + 1}`}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            </section>
        </div>
    );
}