import React, { useEffect } from "react";
import ImageSlider from "../components/imageSlider";
import { IoClose } from "react-icons/io5";

interface PhotoDialog {
    isOpen: any;
    onClose: any;
    value: any;
    index: any;
}

const PhotoModal: React.FC<PhotoDialog> = ({ isOpen, onClose, value, index }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;
    
    return (
        <div className="fixed inset-0 overflow-hidden z-[600] flex items-center justify-center bg-black bg-opacity-80">
            <div className="bg-Stone-600 overflow-auto rounded-lg w-full max-w-4xl h-full sm:max-w-lg md:max-w-xl lg:max-w-4xl px-5 relative flex flex-col">
                <div className="flex-grow">
                    <ImageSlider images={value} initialIndex={index} />
                </div>
                <button
                    onClick={onClose}
                    className="absolute top-3 right-4 bg-Indigo-700/40 p-3 font-bold text-white rounded-full sm:p-2"
                >
                    <IoClose size={24} />
                </button>
            </div>
        </div>
    );
};

export default PhotoModal;
