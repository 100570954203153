import React, { useContext, useEffect } from 'react';
import i18n from 'i18next';
// import encaImg from '../assets/images/en-ca.svg';
// import frImg from '../assets/images/fr-qc.svg';
import { LanguageContext } from '../context/languageContext';
import { useNavigate } from 'react-router-dom';

const LanguageSelector: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  const navigate = useNavigate()

  if (!languageContext) {
    throw new Error('LanguageContext must be used within a LanguageProvider');
  }
  
  const getLanguageFromUrl = () => {
    const path = window.location.pathname;
    const langMatch = path.match(/^\/(fr|en)/);
    return langMatch ? langMatch[1] : 'fr'; 
  };

  const updateUrl = (lang: string) => {
    const path = window.location.pathname.replace(/^\/(fr|en)/, '') || '/';
    const newUrl = `/${lang}${path}${window.location.search}${window.location.hash}`;
    // window.history.pushState({}, '', newUrl);
    navigate(newUrl);
  };
  

  const { language, setLanguage } = languageContext;

  return (
    <div className="flex space-x-2 lg:justify-start justify-center">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          i18n.changeLanguage('en');
          setLanguage('en'); // Use setLanguage to change context state
          updateUrl('en');

        }}
      >
        {/* <img src={encaImg} alt="English" className="w-8 h-8 cursor-pointer" /> */}
        <span className={`${language === 'en' ? 'text-yellow-500' : 'lg:text-white text-black'}`}>EN</span>
      </div>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          i18n.changeLanguage('fr');
          updateUrl('fr');
          setLanguage('fr'); // Use setLanguage to change context state
        }}
      >
        {/* <img src={frImg} alt="French" className="w-8 h-8 cursor-pointer" /> */}
        <span className={`${language === 'fr' ? 'text-yellow-500' : 'lg:text-white text-black'}`}>FR</span>
      </div>
    </div>
  );
};

export default LanguageSelector;
