import Header from "../components/header";
import houseImg from '../assets/images/Sainte-Agathe-des-Monts,_Quebec_-_église_-_5.webp';
import { useTranslation } from 'react-i18next';
import montTremb from "../assets/images/05-LES-LAURENTIDES.webp";
import img1 from "../assets/images/01Lac-Superieur-scaled.webp"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blog1 from "../assets/images/blog/Village Pietonnier 1.webp";
import blog2 from "../assets/images/blog/Domaine de la Foret 1.webp";
import blogImg1 from "../assets/images/blog/Village pietonnier 2.webp"
import blogImg2 from "../assets/images/blog/Domaine de la Montagne 2.webp"
import blogImg3 from "../assets/images/blog/village pietonnier 3.webp"
import i18next from "i18next";
import { getBlog } from "../services/blogService";
import Loading from "../components/loading";


export default function Blog() {

    const [language, setLanguage] = useState('')
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchBlog();
    }, [])


    useEffect(() => {
        if (i18n.language === 'fr') {
            setLanguage('fr')
        } else {
            setLanguage('en')
        }
    }, [i18n.language]);




    const fetchBlog = async () => {
        setLoading(true);
        try {
            const response = await getBlog();
            setBlogPosts(response);
            console.log(response);

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }




    useEffect(() => {
        document.title = t('title.blog2')
    }, [t])

    const url = 'https://mt-cms-214691512350.us-east4.run.app'


    return (
        <>
            <Header></Header>

            <section className="lg:px-24 px-5 py-10 md:pt-36 pt-24" >
                <h1 className="text-3xl font-medium text-start pb-8 text-yellow-500">{t('blog.blog')}</h1>
                <div className=" flex lg:flex-row flex-col  gap-5">
                    <div className="relative lg:w-8/12">
                        <img src={blogImg1} alt="" className="lg:h-[500px] w-full" />
                        <h1 className="text-3xl left-5 font-semibold text-start text-white bottom-10 absolute">{t('blog.01')}</h1>
                    </div>
                    <div className="flex flex-col gap-5 w-full lg:w-4/12" >
                        <div className="relative">
                            <img src={blogImg2} alt="" className="w-full h-[240px]" />
                            <h1 className="text-xl left-5  text-start text-white bottom-5 absolute">{t('blog.02')}</h1>
                        </div>
                        <div className="relative">
                            <img src={blogImg3} alt="" className="w-full h-[240px] " />
                            <h1 className="text-xl left-5  text-start text-white bottom-5 absolute">{t('blog.02')}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lg:px-24 px-5 py-10 bg-gray-200">
                <h1 className="text-2xl font-medium text-yellow-500 text-start pb-8">{t('blog.03')}</h1>
                <div className="flex lg:flex-row gap-10 flex-col flex-wrap  justify-start lg:ps-20">


                    {
                        loading ?
                            <div className="text-center w-full">
                                {/* <p className="w-full text-black">{t('loading')}</p> */}
                                <Loading></Loading>
                            </div>

                            :
                            blogPosts &&
                            blogPosts.map((blog: any, index) =>
                                <>
                                    <div key={index} className="flex flex-col rounded w-full lg:w-auto ">
                                        <div>
                                                
                                                     <img  src={blog.Desc[0].img1.url} alt="" className="lg:w-[320px] rounded-t  lg:h-[200px]" /> 

                                        </div>
                                        <div className=" lg:w-[320px] lg:h-[230px] w-full  text-start bg-white rounded-b p-8 py-4 flex flex-col gap-5 shadow-lg">
                                            <p className="text-sm text-blue-400">{t('blog.04')}</p>
                                            <h1 className="text-md font-semibold break-words overflow-hidden text-ellipsis whitespace-nowrap">
                                                {language === 'fr' ? blog.Title_FR : blog.Title_EN}
                                            </h1>
                                            <p className="text-gray-500 text-sm">{blog.Date}</p>
                                            <button onClick={() => navigate(`/${language}/bloglisting/${blog.id}`)} className="bg-pantone288-600 mt- hover:bg-yellow-500 text-yellow-500 hover:text-white transition font-bold py-2 px-4 rounded-md">{t('blog.06')}</button>
                                        </div>
                                    </div>
                                </>

                            )


                    }




                </div>


            </section>



        </>

    )
}
